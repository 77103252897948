import React from 'react'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import { Navigate } from 'react-router-dom'
import { useAtom } from 'jotai'
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded'
import { atomWithStorage } from "jotai/utils";

import packageJson from './../../../package.json'
import EstateSelector from '../components/estate-selector'
import { IconButton } from '@mui/material'
import { simplifiedAppVersion } from '../state/application'
import { useRedirectToWizard } from './wizard/hooks'
import HomeSimpleVersionView from './home-simple-version'
import HomeFullVersionView from './home-full-version'

function HomeView() {
    const [simplifiedAppVersionValue, setSimplifiedAppVersion] = useAtom(simplifiedAppVersion)

    const {progress} = useRedirectToWizard()

    // first time user doesn't have estate selected, no need to show progress/error
    if(progress) {
        return progress
    }

    return (
        <>
            {
                simplifiedAppVersionValue
                ?
                    <HomeSimpleVersionView />
                :
                    <HomeFullVersionView />
            }
        </>
    )
}

export default HomeView
