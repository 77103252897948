import React, { useState } from 'react'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import CircularProgress from '@mui/material/CircularProgress'
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded'
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded'

import CreateButton from './create-button'
import { useAppliancePlanOverrides } from '../hooks/use-plan-override'
import PlanOverrideCard from './plan-override-card'
import CreatePlanOverrideForm from './forms/plan-override/create'

function PlanOverrideSelector({
    appliancePlanId,
}: {
    appliancePlanId: number | undefined,
}) {

    const [newPlanOverride, setNewPlanOverride] = useState<boolean>(false)
    
    const { status, data, error } = useAppliancePlanOverrides(appliancePlanId)

    if (status === 'pending') {
        return (
            <CircularProgress />
        )
    }

    if (error) {
        return (
            <Box>
                <ErrorOutlineRoundedIcon />
                <Box>{error.message}</Box>
            </Box>
        )
    }

    return (
        <Box
            // onClick={toggleDrawer(false)}
            // onKeyDown={toggleDrawer(false)}
        >
            {!newPlanOverride &&
                <>
                    <Box sx={{
                        fontSize: '1.25rem',
                        flexGrow: 1,
                        color: 'primary.main',
                        paddingX: 2,
                        marginBottom: 1.5,
                    }}>
                        Nastavení režimu párty/dovolená
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1.5,
                    }}>
                        {data.map(planOverride => (
                            <PlanOverrideCard
                                key={planOverride.id}
                                planOverride={planOverride}
                                appliancePlanId={appliancePlanId}
                            />
                        ))}
                        <Box display="flex" my={2}>
                            <CreateButton text='Nový časový úsek' onClick={() => setNewPlanOverride(true)}/>
                        </Box>
                    </Box>
                </>
            }
            {newPlanOverride &&
                <>
                    <Box display="flex" alignContent="center" mb={2}>
                        <Box marginLeft={-1}>
                            <IconButton
                                color="primary"
                                aria-label="back"
                                onClick={() => setNewPlanOverride(false)}
                            >
                                <ArrowBackIosNewRoundedIcon />
                            </IconButton>
                        </Box>
                        <Box sx={{
                            fontSize: '1.25rem',
                            flexGrow: 1,
                            color: 'primary.main',
                            display: 'flex',
                            alignItems: 'center',
                            paddingLeft: 1,
                        }}>
                            Nový časový úsek
                        </Box>
                    </Box>
                    <CreatePlanOverrideForm
                        appliancePlanId={appliancePlanId}
                        handleClose={() => setNewPlanOverride(false)}
                    />
                </>
            }
        </Box>
    )
}

export default PlanOverrideSelector
