import { Dose } from "./types"

function padTo2Digits(num) {
    return String(num).padStart(2, '0')
}

export function printDoseTime(begins_at, ends_at) {
    if (!begins_at || !ends_at) return '-'
  
    const beginsAtDate = new Date(begins_at)
    const endsAtDate = new Date(ends_at)

    return `${padTo2Digits(beginsAtDate.getHours())
    }:${padTo2Digits(beginsAtDate.getMinutes())
    } - ${padTo2Digits(endsAtDate.getHours())
    }:${padTo2Digits(endsAtDate.getMinutes())
    }`
}

export function getHoursDate(hours: string) {  
    const date = new Date()
    const splitHours = hours.split(':')
    date.setHours(parseInt(splitHours[0]), parseInt(splitHours[1]))

    return date.toISOString()
}

export function getHoursAndMinutesString(dateIsoString: string) {  
    const date = new Date(dateIsoString)
    
    return `${padTo2Digits(date.getHours())
    }:${padTo2Digits(date.getMinutes())}`
}

export function getDoseTypeCounts(dose: Dose) {  
    const showerCount = dose.dose_time_frame_dose_type_junctions.find(
        doseType => doseType.dose_type_id == 1
    )?.doses_count ?? 0

    const bathCount = dose.dose_time_frame_dose_type_junctions.find(
        doseType => doseType.dose_type_id == 2
    )?.doses_count ?? 0
    
    return [showerCount, bathCount]
}

export function getHours(dateIsoString: string) {  
    const date = new Date(dateIsoString)
    
    return date.getHours()
}

export function getLengthInHours(beginningIsoString: string, endIsoString: string) {
    const beginningDate = new Date(beginningIsoString)
    const endDate = new Date(endIsoString)

    return (endDate.getHours()+(endDate.getMinutes()/60))-(beginningDate.getHours()+(beginningDate.getMinutes()/60))
}

export function printDate(isoDate: string) {
    const date = new Date(isoDate)
    // Get day, month, year, hours, and minutes from the date object
    const day = String(date.getDate())
    const month = String(date.getMonth() + 1)
    const year = date.getFullYear()
    const hours = String(date.getHours()).padStart(2, '0')
    const minutes = String(date.getMinutes()).padStart(2, '0')
  
    // Return formatted date string
    return `${day}.${month}.${year} ${hours}:${minutes}`
}

export function convertFromCents(price: number):number {  
    if (!price) {
        return 0
    } else {
        return parseFloat((price/100).toFixed(5))
    }
}

export function convertFromKiloWattHoursPrice(price_kiloWatt: number):number {
    if (!price_kiloWatt) {
        return 0
    } else {
        const MEGAWATT = 1000
        return parseFloat((price_kiloWatt*MEGAWATT/100).toFixed(5))
    }
}

export function convertToCents(price: number | string | undefined):number {  
    if (!price) {
        return 0
    } else if (typeof price === 'string') {
        // Legacy rounding, now implemented on BE
        // return (parseFloat(price)*100).toFixed(5)
        return parseFloat(price)*100
    } else {
        return price*100
    }
}

export function convertToKiloWattHoursPrice(price_megaWatt: number | string | undefined):number {
    const MEGAWATT = 1000

    if (!price_megaWatt) {
        return 0
    } else if (typeof price_megaWatt === 'string') {
        return parseFloat(price_megaWatt)*100/MEGAWATT
    } else {
        return price_megaWatt*100/MEGAWATT
    }
}
