import React, { useState } from 'react'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import ElectricalServicesOutlinedIcon from '@mui/icons-material/ElectricalServicesOutlined'
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded'
import { useNavigate } from 'react-router'

import videoFile from '../../../../assets/videos/Shelly_integrator_allow_1080p_converted.mp4'
import { useEstate } from '../../../hooks/use-estate'
import { BottomButtons, Hint, StepIcon } from './wizard-common'
import { PreviousStepButton } from '../components'
import CustomButton from '../../../components/custom-button'
import WebsocketButton from '../../../components/websocket-button'
import { useAppliance, useAppliances } from '../../../hooks/use-appliance'
import { useApplianceControls, useApplianceControlTest } from '../../../hooks/use-appliance-control'
import { useAppliancePlans, useCreateAppliancePlan } from '../../../hooks/use-appliance-plan'
import { Text } from './wizard-common'
import { ErrorMessage } from './components/ErrorMessage'
import { useDebug } from '../../debug'

export function WizardStep9({
    goBack,
    estateId,
    applianceId,
}: {
    applianceId: number
    estateId: number
    goBack: () => void
}) {
    const { status, data, error } = useEstate(estateId)
    const [isTestSuccessful, setIsTestSuccesful] = React.useState(false)
    const [isTestButtonClicked, setIsTestButtonClicked] = React.useState(false)
    const appliance = useAppliances(applianceId)

    // This mutation is here so we can show error below buttons in a simple way (without using useState & useEffect and calling parent from child)
    const navigate = useNavigate()
    const mutation = useCreateAppliancePlan(applianceId, appliancePlanId => {
        navigate(`/estate/${estateId}/appliance/${applianceId}/plan/${appliancePlanId}`)
    })

    if (status === 'pending') {
        return <CircularProgress />
    }

    if (error) {
        return <ErrorMessage>{error.message}</ErrorMessage>
    }

    function onTestSuccess() {
        setIsTestSuccesful(true)
    }

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1rem',
                }}
            >
                <StepIcon icon={<ElectricalServicesOutlinedIcon />} color={'#ffecdb'} />

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '3rem',
                    }}
                >
                    <Text>
                        <Box>
                            Propojte Vaši zásuvku s aplikací Chytrý Bojler. Nejprve připojte zásuvku
                            do Shelly Smart Control aplikace, videonávod je{' '}
                            <a href="https://chytrybojler.cz/tutorials/#sec-videos" target="_blank">
                                zde
                            </a>
                            . Kliknutím na tlačítko budete přesměrováni na webovou stránku Shelly
                            cloud, kde vyberete zásuvku a potvrdíte její propojení s Chytrým
                            Bojlerem.
                        </Box>
                    </Text>

                    <Box sx={{ aspectRatio: 552 / 828 }}>
                        <video width="100%" controls loop autoPlay>
                            <source src={videoFile} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </Box>

                    <EditAppliance applianceId={applianceId} />

                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}>
                        <Test
                            applianceId={applianceId}
                            onSuccess={onTestSuccess}
                            onClick={() => {
                                setIsTestButtonClicked(true)
                            }}
                        />
                        {isTestSuccessful && (
                            <Text
                                color="#52cb44"
                                sx={{ display: 'flex', justifyContent: 'center' }}
                                //isHeader={true}
                            >
                                <CheckCircleOutlineRoundedIcon
                                    sx={{ margin: '0 .5rem 0 0', color: '#52cb44' }}
                                />
                                Test zásuvky proběhl úspěšně
                            </Text>
                        )}
                    </Box>

                    {isTestButtonClicked && (
                        <>
                            <Text
                            //isHeader={true}
                            >
                                Nyní vyplňte časy, kdy se obvykle sprchujete či koupete
                            </Text>
                            <Hint>
                                Aplikace ohřívá vodu co nejlevněji, což je typicky až těsně před
                                sprchováním. Je tedy lepší vyplnit časy sprchování o trochu dřív,
                                než kdy se skutečně sprchujete, aby byla teplá voda už připravená.
                                Přesnější plánování ale vede k lepší optimalizaci.
                            </Hint>
                        </>
                    )}

                    <Box>
                        <BottomButtons>
                            <Box sx={{ flex: 1 }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <PreviousStepButton
                                        onClick={() => {
                                            goBack()
                                        }}
                                    >
                                        Zpět
                                    </PreviousStepButton>

                                    <NextButton
                                        estateId={estateId}
                                        applianceId={applianceId}
                                        isTestSuccessful={
                                            isTestButtonClicked // show after user made interaction
                                            // isTestSuccessful // show after test successful
                                        }
                                        createPlan={() => {
                                            mutation.mutate({ name: 'Můj plán', active: true })
                                        }}
                                    />
                                </Box>
                                <Box sx={{ margin: '1.5rem 0 0 0' }}>
                                    {mutation.isError ? (
                                        <div>Došlo k chybě: {mutation.error.message}</div>
                                    ) : null}
                                </Box>
                            </Box>
                        </BottomButtons>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

function NextButton({
    isTestSuccessful,
    applianceId,
    estateId,
    createPlan,
}: {
    isTestSuccessful: boolean
    applianceId: number
    estateId: number
    createPlan: () => void
}) {
    const navigate = useNavigate()

    const { data, error, status } = useAppliancePlans(applianceId)

    if (status === 'pending') {
        return <CircularProgress />
    }

    if (error) {
        return <ErrorMessage>{error.message}</ErrorMessage>
    }

    const appliancePlanId = data && data.length > 0 ? data[0].id : 0

    return (
        <CustomButton
            text={
                'Nastavit sprchování'
                // appliancePlanId ? 'Nastavit sprchování' : 'Vytvořit plán sprchování'
            }
            type="submit"
            disabled={!isTestSuccessful}
            onClick={() => {
                if (appliancePlanId) {
                    navigate(`/estate/${estateId}/appliance/${applianceId}/plan/${appliancePlanId}`)
                } else {
                    createPlan()
                }
            }}
        />
    )
}

function EditAppliance({ applianceId }: { applianceId: number }) {
    const { status, data, error } = useAppliance(applianceId, {
        refetchOnMount: false, // @hotfix
    })

    if (status === 'pending') {
        return <CircularProgress />
    }

    if (error) {
        return <ErrorMessage>{error.message}</ErrorMessage>
    }

    return (
        <Box>
            <WebsocketButton
                link={data.integrator_url}
                text={'Nastavit zásuvku - budete přesměrováni'}
                target={'_blank'}
            />
        </Box>
    )
}

function Test({ applianceId, onSuccess, onClick }) {
    //
    // @debug ... to quickly test how button looksbutton ...
    //
    // let data = [{ id: 1 }]

    const debug = useDebug({ name: '!!! Test zasuvky !!!' })

    const { status, data, error } = useApplianceControls(applianceId)

    if (status === 'pending') {
        return <CircularProgress />
    }

    if (error) {
        debug.log('render kdyz requst /api/appliance_control/list je 404...')

        return (
            <ErrorMessage title={'Neúspěšný test!'}>
                Odezva zásuvky trvala moc dlouho, nebo byl test úplně bez odezvy. Počkejte chvíli a
                opakujte test, případně opakujte nastavení zásuvky
            </ErrorMessage>
        )
    }

    debug.logObject('data co vratil backend z /api/appliance_control/list ... ', data)
    debug.logObject('my vemem todle idcko pro zasuvku', data && data[0] ? data[0].id : '???')

    return (
        <>
            {data && data.length > 0 && (
                <>
                    <TestButton
                        applianceControlId={data[0].id}
                        onSuccess={onSuccess}
                        onClick={onClick}
                    />
                </>
            )}
        </>
    )
}

// @paste from ApplianceControlTestButton
function TestButton({
    applianceControlId,
    onSuccess,
    onClick,
}: {
    applianceControlId: string | number | undefined
    onSuccess: () => void
    onClick: () => void
}) {
    const [response, setResponse] = useState('')
    const [error, setError] = useState(false)

    const mutation = useApplianceControlTest(
        applianceControlId,
        setResponse,
        () => {
            setError(true)
        },
        () => {
            setError(false)
            onSuccess()
        }
    )

    return (
        <Box display="flex" flexDirection="column" mt={2}>
            <Box display="flex" justifyContent="center">
                <CustomButton
                    variant="outlined"
                    color="primary"
                    text="Vyzkoušet spojení se zásuvkou"
                    onClick={() => {
                        onClick()
                        mutation.mutate()
                    }}
                    endIcon={
                        mutation.isPending ? (
                            <Box sx={{ margin: '0 0 0 .75rem' }}>
                                <CircularProgress size={17} />
                            </Box>
                        ) : undefined
                    }
                />
            </Box>
            {error && (
                <Box
                    sx={{
                        fontSize: '0.875rem',
                        color: 'primary',
                        textAlign: 'center',
                        marginTop: '1rem',
                    }}
                >
                    {
                        <ErrorMessage title="Neúspěšný test!">
                            Odezva zásuvky trvala moc dlouho, nebo byl test úplně bez odezvy.
                            Počkejte chvíli a opakujte test, případně opakujte nastavení zásuvky
                        </ErrorMessage>
                        // 'Spojení se zásuvkou nebylo úspěšně otestováno, opakujte nastavení zásuvky.'
                        // error // this error is not human readable
                    }
                </Box>
            )}
        </Box>
    )
}
