import { useState } from 'react'

export default function useDrawer():
    [boolean, (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => void, Function] {

    const [drawerOpen, setDrawerOpen] = useState<boolean>(false)

    const toggleDrawer = (open: boolean) =>
        (event: React.KeyboardEvent | React.MouseEvent) => {
            if (
                event &&
                event.type === 'keydown' &&
                ((event as React.KeyboardEvent).key === 'Tab' ||
                    (event as React.KeyboardEvent).key === 'Shift')
            ) {
                return
            }

            setDrawerOpen(open)
        }

    const resetDrawer = () => {
        setDrawerOpen(false)
    }

    return [drawerOpen, toggleDrawer, resetDrawer]
}
