export const numberOrUndefinedFromString = (x: string | undefined): number | undefined => {
    if(x === undefined) {
        return undefined
    }

    const asNumber: number = parseFloat(x)
    if (isNaN(asNumber)) {
        return undefined
    } else {
        return asNumber
    }
}
