import React, { useState } from 'react'
import Box from '@mui/material/Box'
import ButtonBase from '@mui/material/ButtonBase'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded'

import { Hdo } from '../types'
import { printDoseTime } from '../helpers'
import UpdateHdoForm from './forms/hdo/update'
import DeleteHdoForm from './forms/hdo/delete'

function HdoCard({
    hdo,
}: {
    hdo: Hdo,
}) {

    const [edit, setEdit] = useState<boolean>(false)
    
    return (
        <Box display="flex" flexDirection="column">
            <ButtonBase
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: 'customBeige.main',
                    padding: 2,
                    borderRadius: 5,
                    textAlign: 'left',
                    boxShadow: '0 2px 6px rgba(0, 0, 0, .03)',
                    "&.Mui-focusVisible": {
                        backgroundColor: 'customBeige.dark',
                    },
                }}
                onClick={() => setEdit(!edit)}
            >
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    gap: 1,
                }}>
                    <Box sx={{ fontSize: '0.875rem' }}>{printDoseTime(hdo.begins_at, hdo.ends_at)}</Box>
                </Box>
                <Box sx={{
                    paddingLeft: 1,
                    fontSize: '1.5rem',
                    lineHeight: 0,
                }}>
                    {edit ?
                        <ExpandLessRoundedIcon fontSize='inherit'/>
                    :
                        <ExpandMoreRoundedIcon fontSize='inherit'/>
                    }
                </Box>
                
            </ButtonBase>
            {edit &&
                <Box sx={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: 'customBeige.light',
                    padding: 2,
                    borderRadius: 5,
                    paddingTop: 8,
                    marginTop: -4,
                }}>
                    <UpdateHdoForm
                        hdo={hdo}
                        distributionElectricityId={hdo.distribution_electricity_id}
                        handleClose={() => setEdit(false)}
                    />
                    <DeleteHdoForm hdoId={hdo.id} distributionElectricityId={hdo.distribution_electricity_id} />
                </Box>
            }
        </Box>
    )
}

export default HdoCard
