import { atom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'

// TODO: Need to delete when logging out
export const selectedEstateIdAtom = atomWithStorage<number | null>(
    'selectedEstateId',
    null,
    undefined,
    {
        getOnInit: true // Jotai doesn't load data from local storage on initial render https://github.com/pmndrs/jotai/issues/2420
    }
)

export const simplifiedAppVersion = atomWithStorage<boolean>(
    'simplifiedAppVersion',
    true,
    undefined,
    {
        getOnInit: true // Jotai doesn't load data from local storage on initial render https://github.com/pmndrs/jotai/issues/2420
    }
)

export const simplifiedAppVersionConfig = atomWithStorage<{
    selectedEstateId: number,
    selectedApplianceId: number,
    selectedAppliancePlanId: number,
}>(
    'simplifiedAppVersionConfig', 
    {
        selectedEstateId: 0,
        selectedApplianceId: 0,
        selectedAppliancePlanId: 0,
    },
    undefined,
    {
        getOnInit: true // Jotai doesn't load data from local storage on initial render https://github.com/pmndrs/jotai/issues/2420
    }
)

export const selectedAppliancePlansAtom = atom<AppliancePlans>({})

interface AppliancePlans {
    [key: string]: number
}
