import React from 'react'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded'

import { useAppliances } from '../../../hooks/use-appliance'
// import { useDebug } from '../../debug'

export function WizardStep2Prepare({
    nextStep,
    estateId,
    applianceId,
    setApplianceId,
}: {
    nextStep: () => void
    estateId: number
    applianceId: number
    setApplianceId: (applianceId: number) => void
}) {
    // const debug = useDebug({name:'WizardStep2 Prepare!!!'})

    const appliancesLoader = useAppliances(estateId)

    // biome-ignore lint/correctness/useExhaustiveDependencies: only care about change of
    React.useEffect(() => {
        //
        // appliance already preselected (from url) ?
        //

        // debug.log("appliance preselection ")

        if (applianceId) {
            // debug.log(`appliance preselection -> we have id ${applianceId}`)

            nextStep()

            return
        }

        //
        // select first appliance id
        //

        if (appliancesLoader.status === 'success') {
            const firstId: number = appliancesLoader.data?.length ? appliancesLoader.data[0].id : 0

            // debug.log(`appliance preselection -> we have loaded -> set id ${firstId}`)

            setApplianceId(firstId)
            nextStep()
        }
    }, [appliancesLoader.status])

    if (appliancesLoader.status === 'pending') {
        return <CircularProgress />
    }

    const error = appliancesLoader.error
    if (error) {
        return (
            <Box>
                <ErrorOutlineRoundedIcon />
                <Box>{error.message}</Box>
            </Box>
        )
    }

    // this step is not visual
    return <></>
}
