import React from 'react'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import Switch, { SwitchProps } from '@mui/material/Switch'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'

import { useToggleOptimisation } from '../hooks/use-estate'
import { CustomDataWithError, Estate } from '../types'
import { AxiosError } from 'axios'

const IOSSwitch = styled((props: SwitchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#7FC387' : '#7FC387',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
            backgroundColor: theme.palette.grey[500],
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#CB5E44' : '#CB5E44',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

function EstateOptimisationToggle({
    estate,
}: {
    estate: Estate,
}) {

    const mutation = useToggleOptimisation(estate.id)

    return (
        <Box display= "flex" flexDirection="column">
            <Box display="flex">
                {!mutation.isPending &&
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Typography sx={{
                            color: estate.optimalization_disabled ? 'grey.500' : 
                                !estate.optimalization_active ? '#CB5E44' :'inherit',
                        }}>Trvalé sepnutí</Typography>
                        <IOSSwitch
                            checked={estate.optimalization_active}
                            inputProps={{ 'aria-label': 'optimisation switch' }}
                            onClick={() => mutation.mutate(!estate.optimalization_active)}
                            disabled={estate.optimalization_disabled}
                        />
                        <Typography sx={{
                            color: estate.optimalization_disabled ? 'grey.500' : 
                                estate.optimalization_active ? 'success.main' :'inherit',
                        }}>Optimalizuji&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Typography>
                    </Stack>
                }
                {mutation.isPending &&
                    <CircularProgress size={26}/>
                }
            </Box>
            {((mutation.error as AxiosError)?.response?.data as CustomDataWithError)?.error &&
                <Box sx={{
                    color: 'error.main',
                    marginTop: 1,
                }}>{((mutation.error as AxiosError)?.response?.data as CustomDataWithError)?.error}</Box>
            }
        </Box>
    )
}

export default EstateOptimisationToggle
