import React from 'react'
import Box from '@mui/material/Box'
import ButtonBase from '@mui/material/ButtonBase'
import Typography from '@mui/material/Typography'
import LocalFireDepartmentRoundedIcon from '@mui/icons-material/LocalFireDepartmentRounded'
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded'

function StatusButton({
    name,
    status,
    icon,
}: {
    name: string,
    status: string,
    icon: 'plan' | 'party',
}) {

    return (
        <ButtonBase sx={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: 'customGreen.main',
            padding: 2,
            borderRadius: 5,
            flexBasis: '50%',
            "&.Mui-focusVisible": {
                backgroundColor: 'customGreen.dark',
            },
         }} component="div">
            <Box sx={{
                paddingRight: 1.5,
                fontSize: '1.5rem',
                lineHeight: 0,
            }}>
                {icon === 'plan' &&
                    <CalendarMonthRoundedIcon fontSize='inherit'/>
                }
                {icon === 'party' &&
                    <LocalFireDepartmentRoundedIcon fontSize='inherit'/>
                }
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                gap: 0.2,
            }}>
                <Typography component="div" sx={{ fontSize: '1rem' }}>{name}</Typography>
                <Typography component="div" sx={{ fontSize: '0.875rem' }}>{status}</Typography>
            </Box>
        </ButtonBase>
    )
}

export default StatusButton
