import React, { Fragment, useState } from 'react'
import Box from '@mui/material/Box'
import ButtonBase from '@mui/material/ButtonBase'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded'
import ShowerRoundedIcon from '@mui/icons-material/ShowerRounded'
import BathtubRoundedIcon from '@mui/icons-material/BathtubRounded'
import Typography from '@mui/material/Typography'

import { Dose } from '../types'
import { printDoseTime } from '../helpers'
import UpdateDoseForm from './forms/dose/update'
import DeleteDoseForm from './forms/dose/delete'

function DoseCard({
    dose,
    estateId,
}: {
    dose: Dose,
    estateId: number,
}) {

    const [edit, setEdit] = useState<boolean>(false)
    
    return (
        <Box display="flex" flexDirection="column">
            <ButtonBase
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: 'customBlue.main',
                    padding: 2,
                    borderRadius: 5,
                    textAlign: 'left',
                    boxShadow: '0 2px 6px rgba(0, 0, 0, .03)',
                    "&.Mui-focusVisible": {
                        backgroundColor: 'customBlue.dark',
                    },
                }}
                onClick={() => setEdit(!edit)}
            >
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    gap: 1,
                }}>
                    <Box sx={{ fontSize: '0.875rem' }}>{printDoseTime(dose.begins_at, dose.ends_at)}</Box>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                        {dose.dose_time_frame_dose_type_junctions.map((doseType, index) => (doseType.doses_count > 0 ?
                            <Fragment key={index}>
                                <Typography component="span" sx={{ fontSize: '1.1rem' }}>{doseType.doses_count}</Typography>
                                {doseType.dose_type_id == 1 &&
                                    <ShowerRoundedIcon sx={{ marginRight: 1 }}/>
                                }
                                {doseType.dose_type_id == 2 &&
                                    <BathtubRoundedIcon sx={{ marginRight: 1 }}/>
                                }
                            </Fragment>
                            : null
                        ))}
                    </Box>
                </Box>
                <Box sx={{
                    paddingLeft: 1,
                    fontSize: '1.5rem',
                    lineHeight: 0,
                }}>
                    {edit ?
                        <ExpandLessRoundedIcon fontSize='inherit'/>
                    :
                        <ExpandMoreRoundedIcon fontSize='inherit'/>
                    }
                </Box>
                
            </ButtonBase>
            {edit &&
                <Box sx={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: '#D6F3FF',
                    padding: 2,
                    borderRadius: 5,
                    paddingTop: 8,
                    marginTop: -4,
                }}>
                    <UpdateDoseForm
                        dose={dose}
                        appliancePlanId={dose.appliance_plan_id}
                        handleClose={() => setEdit(false)}
                    />
                    <DeleteDoseForm estateId={estateId} doseId={dose.id} appliancePlanId={dose.appliance_plan_id} />
                </Box>
            }
        </Box>
    )
}

export default DoseCard
