import React, { useState } from 'react'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import DeviceTypeButton from './device-type-button'
import DeviceThermostatRoundedIcon from '@mui/icons-material/DeviceThermostatRounded'
// import BatteryChargingFullRoundedIcon from '@mui/icons-material/BatteryChargingFullRounded'
import BoltRoundedIcon from '@mui/icons-material/BoltRounded'
// import HeatPumpRoundedIcon from '@mui/icons-material/HeatPumpRounded'
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded'

import { DeviceType } from '../types'
import CreateApplianceForm from './forms/appliance/create'
import CreateRenewableSourceForm from './forms/renewable-source/create'

function DeviceTypeSelector({
    estateId,
    //toggleCreate,
}: {
    estateId: string | undefined,
    //toggleCreate: Function,
}) {

    const [deviceType, setDeviceType] = useState<DeviceType | null>(null)
    
    const deviceTypes = [
        {
            name: 'Bojler',
            icon: <DeviceThermostatRoundedIcon fontSize='inherit'/>,
            onClick: () => setDeviceType(DeviceType.boiler),
        },
        // {
        //     name: 'Tepelné čerpadlo',
        //     icon: <HeatPumpRoundedIcon fontSize='inherit'/>,
        // },
        {
            name: 'Fotovoltaické panely',
            icon: <BoltRoundedIcon fontSize='inherit'/>,
            onClick: () => setDeviceType(DeviceType.pv),
        },
        // {
        //     name: 'Bateriové úložiště',
        //     icon: <BatteryChargingFullRoundedIcon fontSize='inherit'/>,
        // },
    ]

    return (
        <Box
            // onClick={toggleDrawer(false)}
            // onKeyDown={toggleDrawer(false)}
        >
            {!deviceType &&
                <>
                    <Box sx={{
                        fontSize: '1.25rem',
                        flexGrow: 1,
                        color: 'primary.main',
                        paddingX: 2,
                        marginBottom: 1.5,
                    }}>
                        Vyberte typ zařízení
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1.5,
                    }}>
                        {deviceTypes.map((deviceType, index) => (
                            <DeviceTypeButton
                                key={index}
                                name={deviceType.name}
                                icon={deviceType.icon}
                                onClick={deviceType.onClick}
                            />
                        ))}
                    </Box>
                </>
            }
            {deviceType === DeviceType.boiler &&
                <>
                    <Box display="flex" alignContent="center">
                        <Box marginLeft={-1}>
                            <IconButton
                                color="primary"
                                aria-label="back"
                                onClick={() => setDeviceType(null)}
                            >
                                <ArrowBackIosNewRoundedIcon />
                            </IconButton>
                        </Box>
                        <Box sx={{
                            fontSize: '1.25rem',
                            flexGrow: 1,
                            color: 'primary.main',
                            display: 'flex',
                            alignItems: 'center',
                            paddingLeft: 1,
                        }}>
                            Nový bojler
                        </Box>
                    </Box>
                    <CreateApplianceForm estateId={estateId} />
                </>
            }
            {deviceType === DeviceType.pv &&
                <>
                    <Box display="flex" alignContent="center">
                        <Box marginLeft={-1}>
                            <IconButton
                                color="primary"
                                aria-label="back"
                                onClick={() => setDeviceType(null)}
                            >
                                <ArrowBackIosNewRoundedIcon />
                            </IconButton>
                        </Box>
                        <Box sx={{
                            fontSize: '1.25rem',
                            flexGrow: 1,
                            color: 'primary.main',
                            display: 'flex',
                            alignItems: 'center',
                            paddingLeft: 1,
                        }}>
                            Nové FV panely
                        </Box>
                    </Box>
                    <CreateRenewableSourceForm estateId={estateId} />
                </>
            }
        </Box>
    )
}

export default DeviceTypeSelector
