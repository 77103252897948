import React, { useEffect, useState } from "react"
import TextField from "@mui/material/TextField"
import Box from "@mui/material/Box"
import { Controller } from "react-hook-form"
import Slider from "@mui/material/Slider"

import { Declination } from './declination'
import { FormControlWithLabelWrapper } from "../../../../views/wizard/steps/wizard-common"

const clamp = (x, min, max) => x < min ? min : x > max ? max : x

export function DeclinationInput({ control, watch, setValue }) {

    // single source of truth is RHF text input
    const declinationAsString: string = watch('declination')
    const declination: number = clamp(+declinationAsString,0,90)

    // Slider value is based on this value
    const [sliderValue, setSliderValue] = useState(declination)
    useEffect(() => {
        setSliderValue(declination)
    }, [declination])

    return (
        <FormControlWithLabelWrapper
            label={'Sklon panelu (°)'}
            labelId="renewable-source-declination"
        >
            <Declination declination={declination} />

            <Controller
                name="declination"
                control={control}
                render={({ field }) =>
                    <TextField
                        id="renewable-source-declination-text"
                        label="Sklon (°)"
                        variant="standard"
                        type="number"
                        required
                        inputProps={{ min: 0, max: 90 }}
                        {...field}
                    />
                }
            />

            <Slider
                id="renewable-source-declination"
                min={0}
                max={90}
                step={1}
                value={sliderValue}
                onChange={e => {
                    const angle = +((e.target as HTMLInputElement).value)
                    setSliderValue(angle)
                    setValue('declination', angle)
                }}
            />
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
            }}>
                <Box>0°</Box>
                <Box>90°</Box>
            </Box>
        </FormControlWithLabelWrapper>
    )
}
