// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
console.log('Vite ⚡️ Rails')

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

console.log('Visit the guide for more information: ', 'https://vite-ruby.netlify.app/guide/rails')

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

import React from 'react'
import { createRoot } from 'react-dom/client'
import { ThemeProvider } from '@mui/material'
import { RouterProvider } from 'react-router-dom'
import { Provider as JotaiProvider } from 'jotai'
import CssBaseline from '@mui/material/CssBaseline'
import {
    QueryClient,
    QueryClientProvider,
  } from '@tanstack/react-query'
import theme from '../theme'
import router from '../router'

const queryClient = new QueryClient()

function UserReservations() {
    return (
        <React.StrictMode>
            <JotaiProvider>
                <ThemeProvider theme={theme}>
                    <QueryClientProvider client={queryClient}>
                        <CssBaseline />
                        <RouterProvider router={router} />
                    </QueryClientProvider>
                </ThemeProvider>
            </JotaiProvider>
        </React.StrictMode>
    )
}
  
const domNode = document.getElementById('react-root')

const root = createRoot(domNode)

root.render(<UserReservations />)
