import React from "react"
import TextField from "@mui/material/TextField"
import Box from "@mui/material/Box"
import InputAdornment from "@mui/material/InputAdornment"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import Switch from "@mui/material/Switch"
import FormControlLabel from "@mui/material/FormControlLabel"
import { useForm, Controller, SubmitHandler } from "react-hook-form"
import {
    SellElectricityType,
    type SellElectricityFormInput,
    SellElectricity,
} from "../../../types"
import CustomButton from "../../custom-button"
import { useUpdateSellElectricity } from "../../../hooks/use-sell-electricity"
import { SELL_ELECTRICITY_TYPES } from "../../../constants"
import { convertFromKiloWattHoursPrice } from "../../../helpers"

export default function UpdateSellElectricityForm({
    estateId,
    sellElectricity,
    handleClose,
}: {
    estateId: string | number | undefined,
    sellElectricity: SellElectricity,
    handleClose: Function,
}) {
    const sell_fees_amount_cents = sellElectricity.sell_fees.length > 0 ?
        sellElectricity.sell_fees[sellElectricity.sell_fees.length - 1].amount_cents : null
    
    const sell_electricity_amount_cents = sellElectricity.sell_electricity.length > 0 ?
        sellElectricity.sell_electricity[sellElectricity.sell_electricity.length - 1].amount_cents : null
    
    const { control, watch, handleSubmit } = useForm({
        defaultValues: {
            type: sellElectricity.type ?? SellElectricityType.spot,
            sell_fees_amount_cents: sell_fees_amount_cents ? convertFromKiloWattHoursPrice(sell_fees_amount_cents) : '',
            sell_amount_cents: sell_electricity_amount_cents ?  convertFromKiloWattHoursPrice(sell_electricity_amount_cents) : '',
            cant_be_negative: sellElectricity.cant_be_negative ?? false,
        },
    })

    const mutation = useUpdateSellElectricity(sellElectricity.id, estateId, handleClose)

    const onSubmit: SubmitHandler<SellElectricityFormInput> = data => {
        mutation.mutate(data)
    }

    const typeWatch = watch('type')

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1.5,
            }}>
                <FormControl variant="standard" sx={{ minWidth: 130 }}>
                    <InputLabel id="sell-electricity-type-label">Typ</InputLabel>
                    <Controller
                        name="type"
                        control={control}
                        render={({ field }) =>
                            <Select
                                labelId="sell-electricity-type-label"
                                id="sell-electricity-type"
                                {...field}
                            >
                                {SELL_ELECTRICITY_TYPES.map(sellElType => (
                                    <MenuItem key={sellElType.id} value={sellElType.id}>{sellElType.name}</MenuItem>
                                ))}
                            </Select>
                        }
                    />
                </FormControl>
                {typeWatch !== SellElectricityType.null &&
                    <Controller
                        name="sell_fees_amount_cents"
                        control={control}
                        render={({ field }) =>
                            <TextField
                                id="sell-electricity-fees"
                                label="Poplatky"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">Kč bez DPH/MWh</InputAdornment>,
                                }}
                                variant="standard"
                                type="number"
                                required
                                {...field}
                            />}
                    />
                }
                {typeWatch === SellElectricityType.flat &&
                    <Controller
                        name="sell_amount_cents"
                        control={control}
                        render={({ field }) =>
                            <TextField
                                id="sell-electricity-amount"
                                label="Elektřina"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">Kč bez DPH/MWh</InputAdornment>,
                                }}
                                variant="standard"
                                type="number"
                                required
                                {...field}
                            />}
                    />
                }
                {typeWatch === SellElectricityType.spot &&
                    <Box mt={1}>
                        <FormControlLabel control={
                            <Controller
                            name="cant_be_negative"
                            control={control}
                            render={({ field }) =>
                                <Switch
                                    onChange={(e) => field.onChange(e.target.checked)}
                                    checked={field.value}
                                />
                            }
                        />
                        } label="Nemůže být záporný" />
                    </Box>
                }
                <Box display="flex" justifyContent="center" mt={2} mb={2}>
                    <CustomButton
                        text="Uložit"
                        type="submit"
                        disabled={mutation.isPending}
                    />
                </Box>
                {mutation.isError ? (
                    <div>Došlo k chybě: {mutation.error.message}</div>
                ) : null}
            </Box>
        </form>
    )
}
