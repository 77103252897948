import axios from 'axios'
import {
    useQuery,
    useMutation,
    useQueryClient,
} from '@tanstack/react-query'
import { PowerElectricityType, type PowerElectricity, type PowerElectricityFormInput } from '../types'
import { convertToKiloWattHoursPrice } from '../helpers'

export function usePowerElectricities(estateId) {
    return useQuery({
        queryKey: ['powerElectricities-' + estateId],
        queryFn: async () => {
            const { data } : { data : Array<PowerElectricity> } = await axios.get(
                '/api/power_electricity/list?estate_id=' + estateId,
            )
            return data ? data.sort((a,b)=> a.id - b.id): data // backend doesn't sort the data
        },
    })
}

export function usePowerElectricity(powerElectricityId) {
    return useQuery({
        queryKey: ['powerElectricity-' + powerElectricityId],
        queryFn: async () => {
            const { data } : { data : PowerElectricity } = await axios.get(
                '/api/power_electricity/single?id=' + powerElectricityId,
            )
            return data
        },
    })
}

export const useCreatePowerElectricity = (estateId, returnToList: (
    (() => void) |
    ((powerElectricityId: number) => void))) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (data: PowerElectricityFormInput) => {            
            return axios.get(`/api/power_electricity/create?estate_id=${estateId
            }&type=${data.type
            }&power_fees_amount_cents=${convertToKiloWattHoursPrice(data.power_fees_amount_cents)
            }${data.type == PowerElectricityType.flat ?
                '&power_electricity_amount_cents='+convertToKiloWattHoursPrice(data.power_electricity_amount_cents) : ''}`
            )
        },
        onSuccess: (response) => {
            queryClient.invalidateQueries({ queryKey: ['powerElectricities-' + estateId] })
            returnToList(response.data.id)
        },
    })
}

export const useUpdatePowerElectricity = (powerElectricityId, estateId, handleClose) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (data: PowerElectricityFormInput) => {
            return axios.get(`/api/power_electricity/update?id=${powerElectricityId
            }&type=${data.type
            }&power_fees_amount_cents=${convertToKiloWattHoursPrice(data.power_fees_amount_cents)
            }${data.type == PowerElectricityType.flat ?
                '&power_electricity_amount_cents='+convertToKiloWattHoursPrice(data.power_electricity_amount_cents) : ''}`
            )
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['powerElectricities-' + estateId] })
            queryClient.invalidateQueries({ queryKey: ['powerElectricity-' + powerElectricityId] })
            handleClose()
        },
    })
}
