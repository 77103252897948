import React from "react"
import dayjs from 'dayjs'
import 'dayjs/locale/cs'
import Box from "@mui/material/Box"
import { useForm, Controller, SubmitHandler } from "react-hook-form"
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

import type { Hdo, HdoFormInput } from "../../../types"
import CustomButton from "../../custom-button"
import { useUpdateHdo } from "../../../hooks/use-hdo"
import FormRow from "../helpers/form-row"

export default function UpdateHdoForm({
    distributionElectricityId,
    hdo,
    handleClose,
}: {
    distributionElectricityId: number,
    hdo: Hdo,
    handleClose: Function,
}) {
    
    const { control, handleSubmit } = useForm({
        defaultValues: {
            begins_at: dayjs(hdo.begins_at),
            ends_at: dayjs(hdo.ends_at),
        },
    })

    const mutation = useUpdateHdo(hdo.id, distributionElectricityId, handleClose)

    const onSubmit: SubmitHandler<HdoFormInput> = data => {
        mutation.mutate(data)
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1.5,
            }}>
                <FormRow>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="cs">
                        <Controller
                            name="begins_at"
                            control={control}
                            render={({ field }) => 
                                <TimePicker label="Začátek" ampm={false} {...field}/>
                            }
                        />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="cs">
                        <Controller
                            name="ends_at"
                            control={control}
                            render={({ field }) => 
                                <TimePicker label="Konec" ampm={false} {...field}/>
                            }
                        />
                    </LocalizationProvider>
                </FormRow>
                <Box display="flex" justifyContent="center" mt={2}>
                    <CustomButton
                        text="Uložit"
                        type="submit"
                        disabled={mutation.isPending}
                    />
                </Box>
                {mutation.isError ? (
                    <div>Došlo k chybě: {mutation.error.message}</div>
                ) : null}
            </Box>
        </form>
    )
}
