import React from 'react'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded'
import ElectricalServicesOutlinedIcon from '@mui/icons-material/ElectricalServicesOutlined'

import { DistributorForm } from './components/DistributorForm'
import { useEstate } from '../../../hooks/use-estate'
import { StepIcon } from './wizard-common'

export function WizardStep8({
    nextStep,
    goBack,
    estateId,
    applianceId,
}: {
    nextStep: () => void
    goBack: () => void
    applianceId: number
    estateId: number
}) {
    const { status, data, error } = useEstate(estateId)

    if (status === 'pending') {
        return <CircularProgress />
    }

    if (error) {
        return (
            <Box>
                <ErrorOutlineRoundedIcon />
                <Box>{error.message}</Box>
            </Box>
        )
    }

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1rem',
                }}
            >
                <StepIcon icon={<ElectricalServicesOutlinedIcon />} color={'#ffecdb'} />

                <DistributorForm
                    applianceId={applianceId}
                    estateId={estateId}
                    distributionElectricityId={data.distribution_electricity_id}
                    nextStep={nextStep}
                    goBack={goBack}
                />
            </Box>
        </>
    )
}
