import React, { useState } from 'react'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded'
import PaidRoundedIcon from '@mui/icons-material/PaidRounded'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { useSavedAmount } from '../hooks/use-estate'
import { convertFromCents } from '../helpers'
import SavedAmountGraph from './saved-amount-graph'

function SavedAmountCard({
    estateId,
}: {
    estateId: string | number | undefined
}) {
    const { status, data, error } = useSavedAmount(estateId)

    const [expanded, setExpanded] = useState(false)

    if (status === 'pending') {
        return <CircularProgress />
    }

    if (error) {
        return (
            <Box>
                <ErrorOutlineRoundedIcon />
                <Box>{error.message}</Box>
            </Box>
        )
    }

    return (
        <Box display="flex" flexDirection="column">
            <Accordion
                sx={{
                    border: '',
                    boxShadow: 'none',
                    backgroundColor: 'customYellow.light',
                    borderRadius: '20px !important', // equivalent to `borderRadius: 5` which doesn't work
                }}
                onChange={(event, opened) => {
                    setExpanded(opened)
                }}
            >
                <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{}}>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            // backgroundColor: 'customYellow.light',
                            padding: '0', //1rem 0',
                            borderRadius: 5,
                            textAlign: 'left',
                            zIndex: 1,
                            '&.Mui-focusVisible': {
                                backgroundColor: 'customYellow.dark',
                            },
                        }}
                    >
                        <Box
                            sx={{
                                paddingRight: 1,
                                fontSize: '1.8rem',
                                lineHeight: 0,
                            }}
                        >
                            <PaidRoundedIcon
                                fontSize="inherit"
                                sx={{ color: 'customYellow.dark' }}
                            />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                flexGrow: 1,
                                gap: 1,
                            }}
                        >
                            <Box sx={{ fontSize: '0.875rem' }}>
                                Tento měsíc jste ušetřili:{' '}
                                {convertFromCents(data.saved_amount_cents)} CZK
                            </Box>
                        </Box>
                    </Box>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: 0 }}>
                    <SavedAmountGraph estateId={estateId} />
                </AccordionDetails>
            </Accordion>
        </Box>
    )
}

export default SavedAmountCard
