import React from 'react'
import {
    createRoutesFromElements,
    createBrowserRouter,
    Route,
} from 'react-router-dom'

import ErrorView from './views/error'
import HomeView from './views/home'
import ApplianceView from './views/appliance'
import ProfileView from './views/profile'
import RenewableSourceView from './views/renewable-source'
import BatteryView from './views/battery'
import PlanView from './views/plan'
import EstateView from './views/estate'
import EstateSettingsView from './views/estate-settings'
import { WizardView } from "./views/wizard/wizard";


const router = createBrowserRouter(
    createRoutesFromElements(
        <Route errorElement={<ErrorView />}>
            <Route index element={<HomeView />} />
            <Route
                path="estate/:estateId"
            >
                <Route index element={<EstateView />} />
                <Route
                    path="appliance/:applianceId"
                >
                    <Route index element={<ApplianceView />} />
                    <Route
                        path="plan/:planId"
                        element={<PlanView />}
                    />
                </Route>
                <Route
                    path="renewable-source/:renewableSourceId"
                    element={<RenewableSourceView />}
                />
                <Route
                    path="battery/:batteryId"
                    element={<BatteryView />}
                />
                <Route
                    path="settings"
                    element={<EstateSettingsView />}
                />
            </Route>
            <Route
                path="wizard/:estateId?/:applianceId?"
                element={<WizardView />}
            />
            <Route
                path="sign_out"
                element={<ProfileView />}
            />
        </Route>
    )
)

export default router
