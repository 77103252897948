import React, { useState } from 'react'
import Box from '@mui/material/Box'
import ButtonBase from '@mui/material/ButtonBase'
import LocalFireDepartmentRoundedIcon from '@mui/icons-material/LocalFireDepartmentRounded'
import BeachAccessRoundedIcon from '@mui/icons-material/BeachAccessRounded'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded'

import { PlanOverride, PlanOverrideType } from '../types'
import DeletePlanOverrideForm from './forms/plan-override/delete'
import { printDate } from '../helpers'
import UpdatePlanOverrideForm from './forms/plan-override/update'

function PlanOverrideCard({
    planOverride,
    appliancePlanId,
}: {
    planOverride: PlanOverride,
    appliancePlanId: number | undefined,
}) {

    const [edit, setEdit] = useState<boolean>(false)

    const currentDate = new Date()

    const isActive = () => {
        const beginsAtDate = new Date(planOverride.begins_at)
        const endsAtDate = new Date(planOverride.ends_at)
        return beginsAtDate < currentDate && endsAtDate > currentDate
    }

    return (
        <Box display="flex" flexDirection="column">
            <ButtonBase
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: isActive() ? 'customGreen.main' : 'customGrey.main',
                    padding: 2,
                    borderRadius: 5,
                    "&.Mui-focusVisible": {
                        backgroundColor: isActive() ? 'customGreen.dark' : 'customGrey.dark',
                    },
                }} 
                onClick={() => setEdit(!edit)}
                component="div"
            >
                <Box sx={{
                    paddingRight: 2,
                    fontSize: '1.8rem',
                    lineHeight: 0,
                }}>
                    {planOverride.type == PlanOverrideType.party &&
                        <LocalFireDepartmentRoundedIcon fontSize='inherit'/>
                    }
                    {planOverride.type == PlanOverrideType.away &&
                        <BeachAccessRoundedIcon fontSize='inherit'/>
                    }
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    gap: 0.2,
                }}>
                    <Box>
                        {planOverride.type == PlanOverrideType.party && 'Párty režim'}
                        {planOverride.type == PlanOverrideType.away && 'Režim dovolené'}
                    </Box>
                    <Box sx={{ fontSize: '0.875rem' }}>
                        {printDate(planOverride.begins_at)} až {printDate(planOverride.ends_at)}
                    </Box>
                </Box>
                <Box sx={{
                    paddingLeft: 1,
                    fontSize: '1.5rem',
                    lineHeight: 0,
                }}>
                    {edit ?
                        <ExpandLessRoundedIcon fontSize='inherit'/>
                    :
                        <ExpandMoreRoundedIcon fontSize='inherit'/>
                    }
                </Box>
            </ButtonBase>
            {edit && appliancePlanId &&
                <Box sx={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: isActive() ? 'customGreen.light' : '#E2E2E2',
                    padding: 2,
                    borderRadius: 5,
                    paddingTop: 8,
                    marginTop: -4,
                }}>
                    <UpdatePlanOverrideForm
                        planOverride={planOverride}
                        appliancePlanId={appliancePlanId}
                        handleClose={() => setEdit(false)}
                    />
                    <DeletePlanOverrideForm planOverrideId={planOverride.id} appliancePlanId={appliancePlanId} />
                </Box>
            }
        </Box>
    )
}

export default PlanOverrideCard
