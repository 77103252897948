import React from 'react'
import Box from '@mui/material/Box'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined'
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined'
import PhotoCameraOutlinedIcon from '@mui/icons-material/PhotoCameraOutlined'

import { StepIcon, Text } from './wizard-common'
import CustomButton from '../../../components/custom-button'

export function WizardStepWelcome({
    nextStep,
}: {
    nextStep: () => void
}) {
    const ICON_STYLE = {
        position: 'relative',
        left: '-14px',
    }

    return (
        <Box>
            <StepIcon icon={<SettingsOutlinedIcon />} color="#DFF2E1" />

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '4rem',
                }}
            >
                <Box>
                    <Text isHeader>Vítejte v průvodci rychlého nastavení!</Text>
                    <Text>
                        Tento proces vám zabere přibližně 10 minut. Než začneme, připravte si prosím
                        následující věci:
                    </Text>

                    <List>
                        <ListItem key="1">
                            <ReceiptLongOutlinedIcon sx={ICON_STYLE} />
                            <Text>Poslední vyúčtování elektřiny</Text>
                        </ListItem>
                        <ListItem key="2">
                            <PhotoCameraOutlinedIcon sx={ICON_STYLE} />
                            <Text>Ofocený štítek vašeho bojleru</Text>
                        </ListItem>
                        <ListItem key="3">
                            <PeopleAltOutlinedIcon sx={ICON_STYLE} />
                            <Box sx={{}}>
                                <Text sx={{ display: 'inline-block' }}>
                                    Shelly účet a připojenou Shelly zásuvku
                                </Text>{' '}
                                (pokud účet a zásuvku ještě nemáte, postupujte podle{' '}
                                <a
                                    href="https://chytrybojler.cz/tutorials/#sec-videos"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    tohoto návodu
                                </a>
                                )
                            </Box>
                        </ListItem>
                    </List>
                </Box>

                <Box display="flex" justifyContent="right" mt={2}>
                    <CustomButton
                        text="Pokračovat"
                        type="submit"
                        onClick={() => {
                            nextStep()
                        }}
                    />
                </Box>
            </Box>
        </Box>
    )
}
