import React, { useState } from 'react'
import Box from '@mui/material/Box'
import ButtonBase from '@mui/material/ButtonBase'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded'

import { DistributionElectricity, supplierHasEAN, supplierHasHDO } from '../types'
import UpdateDistributionElectricityForm from './forms/distribution-electricity/update'
import { convertFromKiloWattHoursPrice } from '../helpers'

function Code({
    distributionElectricity
}:{
    distributionElectricity: DistributionElectricity
}) {
    if(supplierHasHDO(distributionElectricity.supplier)) {
        return <>{distributionElectricity.signal_code &&
            <Box sx={{ fontSize: '0.875rem', flexGrow: 1, }}>
                Kód HDO: {distributionElectricity.signal_code}
            </Box>
        }
        </>
    }

    if(supplierHasEAN(distributionElectricity.supplier)) {
        return <>{distributionElectricity.ean &&
            <Box sx={{ fontSize: '0.875rem', flexGrow: 1, }}>
                EAN: {distributionElectricity.ean}
            </Box>
        }
        </>
    }

    return null;
}

function DistributionElectricityCard({
    distributionElectricity,
}: {
    distributionElectricity: DistributionElectricity,
}) {

    const [edit, setEdit] = useState<boolean>(false)
    
    return (
        <Box display="flex" flexDirection="column">
            <ButtonBase
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: 'customGrey.main',
                    padding: 2,
                    borderRadius: 5,
                    textAlign: 'left',
                    boxShadow: '0 2px 6px rgba(0, 0, 0, .03)',
                    "&.Mui-focusVisible": {
                        backgroundColor: 'customGrey.dark',
                    },
                }}
                onClick={() => setEdit(!edit)}
            >
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    gap: 1,
                }}>
                    <Box mb={0.5} fontSize={'1rem'}>{distributionElectricity.supplier} {distributionElectricity.distribution_rate}</Box>
                    <Box display="flex" flexDirection="column" gap={0.5}>
                        <Box sx={{ fontSize: '0.875rem', flexGrow: 1, }}>
                            Nízký tarif: {convertFromKiloWattHoursPrice(distributionElectricity.low_price_cents)} Kč bez DPH/MWh
                        </Box>
                        <Box sx={{ fontSize: '0.875rem', flexGrow: 1, }}>
                            Vysoký tarif: {convertFromKiloWattHoursPrice(distributionElectricity.high_price_cents)} Kč bez DPH/MWh
                        </Box>
                        <Code distributionElectricity={distributionElectricity} />
                    </Box>
                </Box>
                <Box sx={{
                    paddingLeft: 1,
                    fontSize: '1.5rem',
                    lineHeight: 0,
                }}>
                    {edit ?
                        <ExpandLessRoundedIcon fontSize='inherit'/>
                    :
                        <ExpandMoreRoundedIcon fontSize='inherit'/>
                    }
                </Box>
                
            </ButtonBase>
            {edit &&
                <Box sx={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: 'customGrey.light',
                    padding: 2,
                    borderRadius: 5,
                    paddingTop: 8,
                    marginTop: -4,
                }}>
                    <UpdateDistributionElectricityForm
                        distributionElectricity={distributionElectricity}
                        handleClose={() => setEdit(!edit)}
                    />
                </Box>
            }
        </Box>
    )
}

export default DistributionElectricityCard
