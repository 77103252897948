import React from 'react'
import Box from '@mui/material/Box'
import ButtonBase from '@mui/material/ButtonBase'
import Typography from '@mui/material/Typography'
import AddRoundedIcon from '@mui/icons-material/AddRounded'

function WebsocketButton({
    link,
    text = 'Přidat zásuvku',
    target,
}: {
    link: string,
    text?: string,
    target?: '_blank',
}) {

    return (
        <ButtonBase
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                backgroundColor: 'customBeige.main',
                paddingX: 2,
                paddingY: 2,
                paddingRight: 3,
                borderRadius: 5,
                "&.Mui-focusVisible": {
                    backgroundColor: 'customBeige.dark',
                },
            }}
            component="a"
            href={link}
            target={target}
        >
            <Box sx={{
                paddingRight: 1.5,
                fontSize: '1.5rem',
                lineHeight: 0,
            }}>
                <AddRoundedIcon fontSize='inherit'/>
            </Box>
            <Typography component="div" sx={{ fontSize: '1.1rem' }}>{text}</Typography>
        </ButtonBase>
    )
}

export default WebsocketButton
