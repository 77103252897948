import axios from 'axios'
import {
    useQuery,
    useMutation,
    useQueryClient,
} from '@tanstack/react-query'
import type { Hdo, HdoFormInput } from '../types'

export function useHdos(distributionElectricityId) {
    return useQuery({
        queryKey: ['hdos-' + distributionElectricityId],
        queryFn: async () => {
            const { data } : { data : Array<Hdo> } = await axios.get(
                '/api/hdo_time_frame/list?distribution_electricity_id=' + distributionElectricityId,
            )
            return data
        },
    })
}

export function useHdo(hdoId) {
    return useQuery({
        queryKey: ['hdo-' + hdoId],
        queryFn: async () => {
            const { data } : { data : Hdo } = await axios.get(
                '/api/hdo_time_frame/single?id=' + hdoId,
            )
            return data
        },
    })
}

export const useCreateHdo = (distributionElectricityId, selectedDay, returnToList) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (data: HdoFormInput) => {            
            const beginsAt = data.begins_at.toISOString()
            const endsAt = data.ends_at.toISOString()
            return axios.get(`/api/hdo_time_frame/create?distribution_electricity_id=${distributionElectricityId
                }&begins_at=${beginsAt
                }&ends_at=${endsAt
                }&day_of_week=${selectedDay
                }&on=true`
            )
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['hdos-' + distributionElectricityId] })
            returnToList()
        },
    })
}

export const useUpdateHdo = (hdoId, distributionElectricityId, resetDrawer) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (data: HdoFormInput) => {
            const beginsAt = data.begins_at.toISOString()
            const endsAt = data.ends_at.toISOString()
            return axios.get(`/api/hdo_time_frame/update?id=${hdoId
                }&begins_at=${beginsAt
                }&ends_at=${endsAt
                }&on=true`
            )
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['hdos-' + distributionElectricityId] })
            queryClient.invalidateQueries({ queryKey: ['hdo-' + hdoId] })
            resetDrawer()
        },
    })
}

export const useDeleteHdo = (hdoId, distributionElectricityId) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: () => {
            return axios.get(`/api/hdo_time_frame/delete?id=${hdoId}`)
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['hdos-' + distributionElectricityId] })
        },
    })
}
