import React, { useState } from 'react'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import CircularProgress from '@mui/material/CircularProgress'
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded'
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded'

import EstateButton from './estate-button'
import CreateButton from './create-button'
import { useEstates } from '../hooks/use-estate'
import CreateEstateForm from './forms/estate/create'

function EstateSelector() {

    const [newEstate, setNewEstate] = useState<boolean>(false)
    
    const { status, data, error } = useEstates()

    if (status === 'pending') {
        return (
            <CircularProgress />
        )
    }

    if (error) {
        return (
            <Box>
                <ErrorOutlineRoundedIcon />
                <Box>{error.message}</Box>
            </Box>
        )
    }

    return (
        <Box
            // onClick={toggleDrawer(false)}
            // onKeyDown={toggleDrawer(false)}
        >
            {!newEstate &&
                <>
                    <Box sx={{
                        fontSize: '1.25rem',
                        flexGrow: 1,
                        color: 'primary.main',
                        paddingX: 2,
                        marginBottom: 1.5,
                    }}>
                        Výběr nemovitosti
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1.5,
                    }}>
                        {data.map((estate, index) => (
                            <EstateButton
                                key={index}
                                estateName={estate.name}
                                estateId={estate.id}
                            />
                        ))}
                        <Box display="flex" my={2}>
                            <CreateButton text='Nová nemovitost' onClick={() => setNewEstate(true)}/>
                        </Box>
                    </Box>
                </>
            }
            {newEstate &&
                <>
                    <Box display="flex" alignContent="center">
                        <Box marginLeft={-1}>
                            <IconButton
                                color="primary"
                                aria-label="back"
                                onClick={() => setNewEstate(false)}
                            >
                                <ArrowBackIosNewRoundedIcon />
                            </IconButton>
                        </Box>
                        <Box sx={{
                            fontSize: '1.25rem',
                            flexGrow: 1,
                            color: 'primary.main',
                            display: 'flex',
                            alignItems: 'center',
                            paddingLeft: 1,
                        }}>
                            Nová nemovitost
                        </Box>
                    </Box>
                    <CreateEstateForm />
                </>
            }
        </Box>
    )
}

export default EstateSelector
