import React from 'react'
import Box from '@mui/material/Box'
import ButtonBase from '@mui/material/ButtonBase'
import Typography from '@mui/material/Typography'
import GiteRoundedIcon from '@mui/icons-material/GiteRounded'
import { Link } from 'react-router-dom'

function EstateButton({
    estateId,
    estateName,
}: {
    estateId: number,
    estateName: string,
}) {

    return (
        <ButtonBase
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                backgroundColor: 'customGrey.main',
                paddingX: 2,
                paddingY: 2.5,
                borderRadius: 5,
                flexGrow: 1,
                "&.Mui-focusVisible": {
                    backgroundColor: 'customGrey.dark',
                },
            }}
            component={Link}
            to={'/estate/'+estateId}
        >
            <Box sx={{
                paddingRight: 1.5,
                fontSize: '1.5rem',
                lineHeight: 0,
            }}>
                <GiteRoundedIcon fontSize='inherit'/>
            </Box>
            <Typography component="div" sx={{ fontSize: '1.25rem' }}>{estateName}</Typography>
        </ButtonBase>
    )
}

export default EstateButton
