import React, {
    // useState,
    // SyntheticEvent,
} from 'react'
import Box from '@mui/material/Box'
// import Tabs from '@mui/material/Tabs'
// import Tab from '@mui/material/Tab'
import CircularProgress from "@mui/material/CircularProgress";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import { Appliance } from '../types'
import UpdateApplianceForm from '../components/forms/appliance/update'
import DeleteApplianceForm from '../components/forms/appliance/delete'
import WebsocketButton from './websocket-button'
// import ApplianceControlList from '../components/lists/appliance-control'
import ApplianceControlWebsocketList from './lists/appliance-control-websocket'
import { useBasicConsumption } from "../hooks/use-basic-consumption";

// function a11yProps(index: number) {
//     return {
//         id: `simple-tab-${index}`,
//         'aria-controls': `simple-tabpanel-${index}`,
//     }
// }

// interface TabPanelProps {
//     children?: React.ReactNode
//     dir?: string
//     index: number
//     value: number
// }

// function TabPanel(props: TabPanelProps) {
//     const { children, value, index, ...other } = props;
  
//     return (
//       <div
//         role="tabpanel"
//         hidden={value !== index}
//         id={`full-width-tabpanel-${index}`}
//         aria-labelledby={`full-width-tab-${index}`}
//         {...other}
//       >
//         {value === index && (
//           <Box sx={{ pt: 3 }}>
//             {children}
//           </Box>
//         )}
//       </div>
//     )
// }

function ApplianceSettingsTabs({
    appliance,
    resetDrawer,
}: {
    appliance: Appliance,
    resetDrawer: Function,
}) {
    const { status, data, error } = useBasicConsumption(appliance.id)

    if (status === 'pending') {
        return (
            <CircularProgress />
        )
    }

    if (error) {
        return (
            <Box>
                <ErrorOutlineRoundedIcon />
                <Box>{error.message}</Box>
            </Box>
        )
    }

    // const [value, setValue] = useState(0)

    // const handleChange = (event: SyntheticEvent, newValue: number) => {
    //     setValue(newValue)
    // }

    return (
        <Box sx={{ width: '100%', minHeight: '680px' }}>
            {/* <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="appliance settings tabs"  variant='fullWidth'>
                    <Tab label="Bojler" {...a11yProps(0)} sx={{ textTransform: 'none', fontSize: '1rem' }} />
                    <Tab label="Zásuvka" {...a11yProps(1)} sx={{ textTransform: 'none', fontSize: '1rem' }} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <UpdateApplianceForm appliance={appliance} resetDrawer={resetDrawer}/>
                <DeleteApplianceForm applianceId={appliance.id} estateId={appliance.estate_id}/>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <ApplianceControlList applianceId={appliance.id} />
            </TabPanel> */}
            <Box sx={{
                fontSize: '1.25rem',
                flexGrow: 1,
                color: 'primary.main',
                display: 'flex',
                alignItems: 'center',
                paddingBottom: 2,
            }}>
                {'Nastavení Bojler '+appliance.id}
            </Box>
            <UpdateApplianceForm appliance={appliance} basicConsumption={data} resetDrawer={resetDrawer}/>
            <DeleteApplianceForm applianceId={appliance.id} estateId={appliance.estate_id}/>
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                mt: 4,
            }}>
                <WebsocketButton link={appliance.integrator_url} target={"_blank"} />
            </Box>
            <ApplianceControlWebsocketList applianceId={appliance.id} />
        </Box>
    )
}

export default ApplianceSettingsTabs
