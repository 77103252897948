import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'

import { usePrepareOptimisation } from '../hooks/use-estate'

function EstateReadyCard({
    estateId,
}: {
    estateId: string | number |undefined,
}) {

    const [expand, setExpand] = useState<boolean>(false)
    const [response, setResponse] = useState<string | null>(null)

    const mutation = usePrepareOptimisation(estateId, setResponse)
    
    return (
        <Box display="flex" flexDirection="column">
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: 'customGreen.main',
                    padding: 2,
                    borderRadius: 5,
                    textAlign: 'left',
                    zIndex: 1,
                    "&.Mui-focusVisible": {
                        backgroundColor: 'customBeige.dark',
                    },
                }}
            >
                <Box sx={{
                    paddingRight: 1,
                    fontSize: '1.8rem',
                    lineHeight: 0,
                }}>
                    <CheckRoundedIcon fontSize='inherit' color="success"/>
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    gap: 1,
                    color: 'success.main',
                }}>
                    <Box sx={{ fontSize: '0.875rem' }}>Nemovitost je připravena k optimalizaci</Box>
                </Box>
            </Box>
            {expand &&
                <Box sx={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: 'customGreen.main',
                    padding: 2,
                    borderRadius: 5,
                    paddingTop: 4,
                    marginTop: -4,
                }}>
                    <Box display="flex" justifyContent="center">
                        <Button variant="outlined" color="success" sx={{ fontSize: '0.875rem' }} onClick={() => mutation.mutate()}>
                            Optimalizovat
                        </Button>
                    </Box>
                    {response &&
                        <Box sx={{ fontSize: '0.875rem', color: 'success.main' }}>{response}</Box>
                    }
                </Box>
            }
        </Box>
    )
}

export default EstateReadyCard
