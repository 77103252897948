import React from "react"
import dayjs from 'dayjs'
import 'dayjs/locale/cs'
import Box from "@mui/material/Box"
import { useForm, Controller, SubmitHandler } from "react-hook-form"
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

import type { HdoFormInput } from "../../../types"
import CustomButton from "../../custom-button"
import { useCreateHdo } from "../../../hooks/use-hdo"
import FormRow from "../helpers/form-row"

export default function CreateHdoForm({
    distributionElectricityId,
    selectedDay,
    handleClose,
}: {
    distributionElectricityId: string | number | undefined,
    selectedDay: 1|2|3|4|5|6|0,
    handleClose: Function,
}) {
    const { control, handleSubmit } = useForm({
        defaultValues: {
            begins_at: dayjs('2024-03-21T00:00'),
            ends_at: dayjs('2024-03-21T01:00'),
        },
    })

    const mutation = useCreateHdo(distributionElectricityId, selectedDay, handleClose)

    const onSubmit: SubmitHandler<HdoFormInput> = data => {
        mutation.mutate(data)
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1.5,
            }}>
                <FormRow>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="cs">
                        <Controller
                            name="begins_at"
                            control={control}
                            render={({ field }) =>
                                <TimePicker label="Začátek" ampm={false} {...field}/>
                            }
                        />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="cs">
                        <Controller
                            name="ends_at"
                            control={control}
                            render={({ field }) =>
                                <TimePicker label="Konec" ampm={false} {...field}/>
                            }
                        />
                    </LocalizationProvider>
                </FormRow>
                <Box display="flex" justifyContent="center" mt={2}>
                    <CustomButton
                        text="Vytvořit"
                        type="submit"
                        disabled={mutation.isPending}
                    />
                </Box>
                {mutation.isError ? (
                    <div>Došlo k chybě: {mutation.error.message}</div>
                ) : null}
            </Box>
        </form>
    )
}
