import React, { ReactNode, MouseEventHandler } from 'react'
import Box from '@mui/material/Box'
import ButtonBase from '@mui/material/ButtonBase'
import Typography from '@mui/material/Typography'

function DeviceTypeButton({
    name,
    icon,
    onClick,
}: {
    name: string,
    icon: ReactNode,
    onClick: MouseEventHandler,
}) {

    return (
        <ButtonBase
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                backgroundColor: 'customGreen.main',
                paddingX: 2,
                paddingY: 2.5,
                borderRadius: 5,
                flexGrow: 1,
                "&.Mui-focusVisible": {
                    backgroundColor: 'customGreen.dark',
                },
            }}
            onClick={onClick}
        >
            <Box sx={{
                paddingRight: 1.5,
                fontSize: '1.5rem',
                lineHeight: 0,
            }}>
                {icon}
            </Box>
            <Typography component="div" sx={{ fontSize: '1.25rem' }}>{name}</Typography>
        </ButtonBase>
    )
}

export default DeviceTypeButton
