import axios, { AxiosError } from 'axios'
import {
    useQuery,
    useMutation,
    useQueryClient,
} from '@tanstack/react-query'
import {
    type DistributionElectricity,
    type DistributionElectricityFormInput,
    type DistributionElectricityEan,
} from '../types'
import { convertToKiloWattHoursPrice } from '../helpers'

export function useDistributionElectricity(distributionElectricityId: string | number) {
    return useQuery({
        queryKey: ['distributionElectricity-' + distributionElectricityId],
        queryFn: async () => {
            const { data } : { data : DistributionElectricity } = await axios.get(
                '/api/distribution_electricity/single?id=' + distributionElectricityId,
            )
            return data
        },
    })
}

export function useDistributionElectricityEanSignals(ean: string) {
    return useQuery<DistributionElectricityEan[] | null, AxiosError>({
        queryKey: ['distributionElectricity-EAN-' + ean],
        staleTime: 5 * 60 * 1000, // 5 minutes
        queryFn: async () => {
            const { data } : { data : DistributionElectricityEan[] | null } = await axios.get(
                '/api/distribution_electricity/get_ean_signals?ean=' + encodeURIComponent( ean ),
            )

            // sort from smallest values times first (user friendly format)
            return data? data.sort((a, b) => a.average_hours - b.average_hours): data // backend doesn't sort data
        },
    })
}

export const useCreateDistributionElectricity = (
    estateId: number | string,
    returnToList: (data:DistributionElectricity)=>void
) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (data: DistributionElectricityFormInput) => {            
            return axios.get(`/api/distribution_electricity/create?estate_id=${estateId
            }&supplier=${data.supplier
            }&distribution_rate=${data.distribution_rate
            //}&has_manual_hdo=${data.has_manual_hdo ? 'true' : 'false'
            //}&has_manual_hdo=${'true' // backend fallbacks to 'true'
            }&low_price_cents=${0 // convertToKiloWattHoursPrice(data.low_price_cents)
            }&high_price_cents=${0 // convertToKiloWattHoursPrice(data.high_price_cents)
            }&signal_code=${data.signal_code
            }&ean=${data.ean}`
            )
        },
        onSuccess: (response) => {
            //queryClient.invalidateQueries({ queryKey: ['distributionElectricities-' + estateId] })
            queryClient.invalidateQueries({ queryKey: ['estate-' + estateId] })
            returnToList(response.data)
        },
    })
}

export const useUpdateDistributionElectricity = (
    distributionElectricityId:number,
    successCallback: ((data:DistributionElectricity) => void) | (() => void),
) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (data: DistributionElectricityFormInput) => {
            return axios.get(`/api/distribution_electricity/update?id=${distributionElectricityId
            }&supplier=${encodeURIComponent(data.supplier)
            }&distribution_rate=${encodeURIComponent(data.distribution_rate)
            //}&has_manual_hdo=${data.has_manual_hdo ? 'true' : 'false'
            }&has_manual_hdo=${'true'
            // }&low_price_cents=${convertToKiloWattHoursPrice(data.low_price_cents)
            // }&high_price_cents=${convertToKiloWattHoursPrice(data.high_price_cents)
            }&signal_code=${encodeURIComponent(data.signal_code)
            }&ean=${encodeURIComponent(data.ean)}`
            )
        },
        onSuccess: (response) => {
            //queryClient.invalidateQueries({ queryKey: ['distributionElectricities-' + estateId] })
            queryClient.invalidateQueries({ queryKey: ['distributionElectricity-' + distributionElectricityId] })
            successCallback(response.data)
        },
    })
}
