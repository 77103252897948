import React from 'react'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded'

import { useIsReady } from '../hooks/use-estate'
import EstateNotReadyCard from './estate-not-ready-card'
import EstateReadyCard from './estate-ready-card'
import EstateOptimisationToggle from './estate-optimisation-toggle'
import { Estate } from '../types'

function EstateIsReady({
    estate,
} : {
    estate: Estate,
}) {

    const { status, data, error } = useIsReady(estate.id)

    if (status === 'pending') {
        return (
            <CircularProgress />
        )
    }

    if (error) {
        return (
            <Box>
                <ErrorOutlineRoundedIcon />
                <Box>{error.message}</Box>
            </Box>
        )
    }

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1.5,
        }}>
            {data.is_ready &&
                <>
                    <EstateReadyCard estateId={estate.id}/>
                    <Box display="flex" justifyContent="center">
                        <EstateOptimisationToggle estate={estate}/>
                    </Box>
                </>
                
            }
            {!data.is_ready &&
                <EstateNotReadyCard estateIsReady={data} />
            }
        </Box>
    )
}

export default EstateIsReady
