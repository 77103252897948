import React from 'react'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Button, { buttonClasses } from '@mui/material/Button'
import { Link } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress'
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded'
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded'
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded'
import { useAtom } from 'jotai'

import { useAppliancePlans } from '../hooks/use-appliance-plan'
import useDrawer from '../hooks/use-drawer'
import AppliancePlanSelector from '../components/appliance-plan-selector'
import SwipeableTemporaryDrawer from '../components/swipeable-temporary-drawer'
import UpdateAppliancePlanForm from './forms/appliance-plan/update'
import DeleteAppliancePlanForm from './forms/appliance-plan/delete'
import SelectedDoseOverview from './selected-dose-overview'
import PlanOverrideButton from '../components/plan-override-button'
import PlanToggleButton from './plan-toggle-button'
import { selectedAppliancePlansAtom } from '../state/application'
import PlanSelectButton from './plan-select-button'

function SelectedAppliancePlan({
    applianceId,
}: {
    applianceId: string | undefined,
}) {

    const [selectedAppliancePlans, setSelectedAppliancePlans] = useAtom(selectedAppliancePlansAtom)

    const [drawerOpenPlans, toggleDrawerPlans, resetDrawerPlans] = useDrawer()
    const [drawerOpenSettings, toggleDrawerSettings, resetDrawerSettings] = useDrawer()
    
    const { status, data, error } = useAppliancePlans(applianceId)

    const returnToList = () => {

    }

    if (status === 'pending') {
        return (
            <CircularProgress />
        )
    }

    if (error) {
        return (
            <Box>
                <ErrorOutlineRoundedIcon />
                <Box>{error.message}</Box>
            </Box>
        )
    }

    let selectedPlan
    if (selectedAppliancePlans['applianceId-'+applianceId]) {
        selectedPlan = data.find(plan => plan.id == selectedAppliancePlans['applianceId-'+applianceId])
    } else {
        selectedPlan = data.find(plan => plan.active)
    }

    return (
        <>
            <Box sx={{
                    display: 'flex',
                    gap: 1.5,
                    marginTop: 1,
                    marginBottom: 2,
                }}>
                    {selectedPlan ?
                        <PlanToggleButton
                            appliancePlan={selectedPlan}
                            applianceId={applianceId}
                        />
                        :
                        <PlanSelectButton
                            onClick={toggleDrawerPlans(true)}
                        />
                    }
                    <Box sx={{
                        flexBasis: '50%',
                    }}>
                        <PlanOverrideButton
                            appliancePlanId={selectedPlan?.id}
                            disabled={!selectedPlan ? true : false}
                        />
                    </Box>
            </Box>
            <Box display="flex" justifyContent="space-between">
                <Button
                    startIcon={<CalendarMonthRoundedIcon fontSize='large' />}
                    endIcon={<ArrowDropDownRoundedIcon fontSize='inherit' />}
                    size='large'
                    sx={{
                        fontSize: '1.2rem',
                        fontWeight: 400,
                        [`& .${buttonClasses.startIcon} > *:nth-of-type(1)`]: {
                            fontSize: '1.5rem',
                        }
                    }}
                    onClick={toggleDrawerPlans(true)}
                >
                    {selectedPlan ? selectedPlan.name : 'Vyberte plán'}
                </Button>
                <Box>
                    <IconButton
                        color="primary"
                        aria-label="edit"
                        disabled={!selectedPlan}
                        component={Link}
                        to={'plan/'+selectedPlan?.id}
                    >
                        <EditRoundedIcon />
                    </IconButton>
                    <IconButton
                        color="primary"
                        aria-label="settings"
                        onClick={toggleDrawerSettings(true)}
                        disabled={!selectedPlan}
                    >
                        <SettingsRoundedIcon />
                    </IconButton>
                </Box>
            </Box>
            {selectedPlan &&
                <SelectedDoseOverview appliancePlanId={selectedPlan.id} />
            }
            <SwipeableTemporaryDrawer
                open={drawerOpenPlans}
                toggleDrawer={toggleDrawerPlans}
            >
                <AppliancePlanSelector applianceId={applianceId} resetDrawer={resetDrawerPlans}/>
            </SwipeableTemporaryDrawer>
            {selectedPlan &&
                <SwipeableTemporaryDrawer
                    open={drawerOpenSettings}
                    toggleDrawer={toggleDrawerSettings}
                >
                    <UpdateAppliancePlanForm
                        appliancePlan={selectedPlan}
                        applianceId={applianceId}
                        resetDrawer={resetDrawerSettings}
                    />
                    <DeleteAppliancePlanForm
                        appliancePlanId={selectedPlan.id}
                        applianceId={applianceId}
                        returnToList={returnToList}
                    />
                </SwipeableTemporaryDrawer>
            }
        </>
    )
}

export default SelectedAppliancePlan
