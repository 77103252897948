import React, { useState } from 'react'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import { useAtom } from 'jotai'
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded'
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    CircularProgress,
    IconButton,
} from '@mui/material'
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import NavigationIcon from '@mui/icons-material/Navigation'
import PlaceIcon from '@mui/icons-material/Place'
import dayjs from 'dayjs'

import packageJson from './../../../package.json'
import { simplifiedAppVersionConfig } from '../state/application'
import { useAppliance } from '../hooks/use-appliance'
import BoilerCard from '../components/boiler-card'
import { useDoses, useDuplicateDosesToOtherDays } from '../hooks/use-dose'
import { DAYS_OF_WEEK } from '../constants'
import { getHours, getLengthInHours } from '../helpers'
import DoseCard from '../components/dose-card'
import { WizardButton } from './wizard/wizard-button'
import EstateOptimised from '../components/estate-optimised'
import { useEstate } from '../hooks/use-estate'
import { ErrorMessage } from './wizard/steps/components/ErrorMessage'
import CreateButton from '../components/create-button'
import CreateDoseForm from '../components/forms/dose/create'
import { FormControlWithLabelWrapper } from './wizard/steps/wizard-common'
import { FONT_WEIGHT } from './wizard/components'
import type { Day, Dose, PlanOverride } from '../types'
import { useAutoSelectFirstEstateFirstAppliancePlanAndAppliances } from '../hooks/use-simple-homepage'
import PlanOverrideButton from '../components/plan-override-button'
import { useAppliancePlanOverrides } from '../hooks/use-plan-override'
import { useDebug } from './debug'
import PlanOverrideCard from '../components/plan-override-card'

function HomeSimpleVersionView() {
    //throw new Response("Not Found", { status: 404 })

    const [simplifiedConfig, setSimplifiedConfig] = useAtom(simplifiedAppVersionConfig)

    return (
        <>
            <Container maxWidth="sm">
                <Box display="flex" justifyContent="space-between">
                    <WizardButton
                        estateId={simplifiedConfig.selectedEstateId}
                        applianceId={simplifiedConfig.selectedApplianceId}
                    />
                    <Box />
                    <IconButton href="/sign_out" color="primary">
                        <AccountCircleRoundedIcon />
                    </IconButton>
                </Box>

                <Box
                    sx={{
                        marginTop: 3,
                        fontSize: '0.75rem',
                        color: 'primary.main',
                        textAlign: 'center',
                    }}
                >
                    <Content />

                    <p>ChargeControl v{packageJson.version}</p>
                    <p>© Factorio Solutions</p>
                </Box>
            </Container>
        </>
    )
}

function Content() {
    const [simplifiedConfig, setSimplifiedConfig] = useAtom(simplifiedAppVersionConfig)

    const appliance = useAppliance(simplifiedConfig.selectedApplianceId)
    const estate = useEstate(simplifiedConfig.selectedEstateId)

    useAutoSelectFirstEstateFirstAppliancePlanAndAppliances()

    if (appliance?.error?.response?.status === 404 || estate?.error?.response?.status === 404) {
        return (
            <Box
                sx={{
                    margin: '3rem 0',
                }}
            >
                <ErrorMessage title="Nenalezen bojler">
                    Pokračujte přes "Rychlé nastavení"
                </ErrorMessage>
            </Box>
        )
    }

    if (appliance.status === 'pending') {
        return <CircularProgress />
    }

    if (appliance.error) {
        return (
            <Box>
                <ErrorOutlineRoundedIcon />
                <Box>{appliance.error.message}</Box>
            </Box>
        )
    }

    if (estate.status === 'pending') {
        return <CircularProgress />
    }

    if (estate.error) {
        return (
            <Box>
                <ErrorOutlineRoundedIcon />
                <Box>{estate.error.message}</Box>
            </Box>
        )
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '2rem',
            }}
        >
            {/* <FormControlWithLabelWrapper label='Optimalizace ohřevu' component='h2'> */}
            <EstateOptimised estate={estate.data} />
            {/* </FormControlWithLabelWrapper> */}

            {/* <FormControlWithLabelWrapper label='Bojler' component='h2'> */}
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <BoilerCard appliance={appliance.data} isLink={false} />
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                }}>
                    <PlanOverrideButton appliancePlanId={simplifiedConfig.selectedAppliancePlanId} />
                </Box>
            </Box>
            {/* </FormControlWithLabelWrapper> */}

            <FormControlWithLabelWrapper label="Plán" component="h2">
                <Doses appliancePlanId={simplifiedConfig.selectedAppliancePlanId} estateId={estate.data.id} />
            </FormControlWithLabelWrapper>
        </Box>
    )
}

function Doses({
    appliancePlanId,
    estateId,
}: {
    appliancePlanId: string | number | undefined
    estateId: number
}) {
    const debug = useDebug({ name: 'Doses' })
    const { status, data, error } = useDoses(appliancePlanId)

    const planOverrides = useAppliancePlanOverrides(appliancePlanId)

    if (!appliancePlanId || error?.response?.status === 404) {
        return (
            <Box
                sx={{
                    margin: '3rem 0',
                }}
            >
                <ErrorMessage title="Plán nebyl nastaven">
                    Plán nastavíte v "rychlém nastavení"
                </ErrorMessage>
            </Box>
        )
    }

    if (status === 'pending' || planOverrides.status === 'pending') {
        return <CircularProgress />
    }

    if (error) {
        return (
            <Box>
                <ErrorOutlineRoundedIcon />
                <Box>{error.message}</Box>
            </Box>
        )
    }

    if (planOverrides.error) {
        return (
            <Box>
                <ErrorOutlineRoundedIcon />
                <Box>{planOverrides.error.message}</Box>
            </Box>
        )
    }

    debug.log('planOverrides', planOverrides, planOverrides.data)

    // TODO: Connect actual dose to width and left

    return (
        <Box display="flex" flexDirection="column" gap={2} pt={2}>
            <Box display="flex">
                <Box flexBasis={30}></Box>
                <Box flexGrow={1} display="flex" justifyContent="space-between">
                    <Box fontSize={14}>00:00</Box>
                    <Box fontSize={14}>12:00</Box>
                    <Box fontSize={14}>24:00</Box>
                </Box>
            </Box>
            <Box>
                {DAYS_OF_WEEK.map(day => {
                    const filteredDoses = data.filter(dose => dose.day_of_week === day.id)

                    const dayjsDayID = day.id === 0 ? 7 : day.id // dayjs interprets 0 as previous week sunday, not current one
                    const date = dayjs()
                    const dayStart = date.day(dayjsDayID).startOf('day')
                    const dayEnd = date.day(dayjsDayID).endOf('day')
                    const weekEnd = date.day(7).endOf('day')

                    const filteredOverrides = planOverrides.data
                        .filter(override => {

                            const isInFuture = !date.isAfter(dayjs(override.ends_at))

                            const isThisWeek = !(
                                weekEnd.isBefore(dayjs(override.begins_at))
                            )

                            const isToday = !(
                                dayStart.isAfter(dayjs(override.ends_at)) ||
                                dayEnd.isBefore(dayjs(override.begins_at))
                            )

                            return isInFuture && isThisWeek && isToday
                        })
                        .map(override => ({
                            ...override,
                            begins_at: dayjs(override.begins_at).isBefore(dayStart)
                                ? dayStart.format()
                                : override.begins_at,
                            ends_at: dayjs(override.ends_at).isAfter(dayEnd)
                                ? dayEnd.format()
                                : override.ends_at,
                        }))

                    return (
                        <DoseDay
                            key={day.id}
                            appliancePlanId={+appliancePlanId}
                            day={day}
                            filteredDoses={filteredDoses}
                            filteredOverrides={filteredOverrides}
                            estateId={estateId}
                        />
                    )
                })}
            </Box>
        </Box>
    )
}

function DoseDay({
    day,
    filteredDoses,
    appliancePlanId,
    filteredOverrides,
    estateId,
}: {
    day: Day
    filteredDoses: Dose[]
    filteredOverrides: PlanOverride[]
    appliancePlanId: number
    estateId: number
}) {
    const [expanded, setExpanded] = useState(false)

    return (
        <Box
            display="flex"
            sx={{
                flexDirection: 'column',
                gap: 0,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    // gap: '1rem'
                }}
            >
                <Accordion
                    slotProps={{ heading: { component: 'h4' } }}
                    sx={{
                        border: expanded ? '1px solid #eee' : '',
                        boxShadow: 'none',
                        borderRadius: '20px !important',
                    }}
                    onChange={(event, opened) => {
                        setExpanded(opened)
                    }}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        //aria-controls="panel1-content"
                        //id="panel1-header"
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '1rem',
                                flexGrow: 1,
                            }}
                        >
                            <Box
                                sx={{
                                    fontWeight: FONT_WEIGHT.medium,
                                    fontSize: '16px',
                                    textAlign: 'left',
                                }}
                                color="primary.main"
                            >
                                {day.name}
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '.25rem',
                                    flexGrow: 1,
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexGrow: 1,
                                        backgroundColor: 'customGreen.main',
                                        borderRadius: 10,
                                        position: 'relative',
                                        overflow: 'hidden',
                                        minHeight: 12,
                                    }}
                                >
                                    <CurrentTimeIndicator day={day} />
                                    {filteredDoses.map(dose => {
                                        const startHour = getHours(dose.begins_at)
                                        const lengthHours = getLengthInHours(
                                            dose.begins_at,
                                            dose.ends_at
                                        )

                                        return (
                                            <Box
                                                key={dose.id}
                                                sx={{
                                                    position: 'absolute',
                                                    zIndex: 1,
                                                    left: startHour / 0.24 + '%',
                                                    width: lengthHours / 0.24 + '%',
                                                    height: '100%',
                                                    backgroundColor: 'customBlue.dark',
                                                }}
                                            />
                                        )
                                    })}

                                    {filteredOverrides.map(dose => {
                                        const startHour = getHours(dose.begins_at)
                                        const lengthHours = getLengthInHours(
                                            dose.begins_at,
                                            dose.ends_at
                                        )

                                        return (
                                            <Box
                                                key={dose.id}
                                                sx={{
                                                    position: 'absolute',
                                                    zIndex: 2,
                                                    left: startHour / 0.24 + '%',
                                                    width: lengthHours / 0.24 + '%',
                                                    height: '100%',
                                                    backgroundColor: 'customYellow.main', //dark',
                                                    opacity: 0.5,
                                                }}
                                            />
                                        )
                                    })}
                                </Box>
                            </Box>
                        </Box>
                    </AccordionSummary>

                    <AccordionDetails>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 1.5,
                            }}
                        >
                            {filteredDoses.map(dose => (
                                <DoseCard key={dose.id} dose={dose} estateId={estateId} />
                            ))}

                            {filteredOverrides.map(override => (
                                <PlanOverrideCard
                                    key={override.id}
                                    appliancePlanId={appliancePlanId}
                                    planOverride={override}
                                />
                            ))}
                        </Box>

                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Add planId={appliancePlanId} selectedDay={day.id} estateId={estateId} />

                            <UseForOtherDays planId={appliancePlanId} selectedDay={day.id} />
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </Box>
        </Box>
    )
}

function CurrentTimeIndicator({ day }: { day: Day }) {
    const date = new Date()
    const currentDay = date.getDay()
    const percentage = (date.getHours() * 60 + date.getMinutes()) / (24 * 60)

    return day.id === currentDay ? (
        <Box
            sx={{
                position: 'absolute',
                left: `${Math.round(percentage * 1e4) / 1e2}%`, // 2 decimal digits precision
                // top: '50%',
                // top: '-3px',
                //transform: 'rotate(180deg)',
                zIndex: 3,
            }}
        >
            <PlaceIcon />
        </Box>
    ) : (
        <></>
    )
}

function Add({ planId, estateId, selectedDay }) {
    const [add, setAdd] = useState<boolean>(false)

    return (
        <Box display="flex" mt={2}>
            {!add && <CreateButton text="Přidat" onClick={() => setAdd(true)} />}
            {add && (
                <Box
                    sx={{
                        flexGrow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        backgroundColor: 'customBlue.main',
                        padding: 2,
                        borderRadius: 5,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignContent: 'center',
                            marginBottom: 1,
                        }}
                    >
                        <Box
                            sx={{
                                fontSize: '1.25rem',
                                flexGrow: 1,
                                color: 'primary.main',
                            }}
                        >
                            Nový úsek spotřeby
                        </Box>
                        <Box>
                            <IconButton
                                color="primary"
                                aria-label="back"
                                onClick={() => setAdd(false)}
                            >
                                <CloseRoundedIcon />
                            </IconButton>
                        </Box>
                    </Box>
                    <CreateDoseForm
                        appliancePlanId={planId}
                        selectedDay={selectedDay}
                        handleClose={() => setAdd(false)}
                        estateId={estateId}
                    />
                </Box>
            )}
        </Box>
    )
}

function UseForOtherDays({ planId, selectedDay }) {
    const mutationDuplicate = useDuplicateDosesToOtherDays(planId, selectedDay)

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: 2,
                flexDirection: 'column',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <Button
                    startIcon={<CalendarMonthRoundedIcon fontSize="large" />}
                    size="large"
                    sx={{
                        fontSize: '1rem',
                        fontWeight: 400,
                    }}
                    onClick={() => {
                        mutationDuplicate.mutate()
                    }}
                    disabled={mutationDuplicate.isPending}
                    endIcon={mutationDuplicate.isPending ? <CircularProgress /> : null}
                >
                    Použít i pro další dny
                </Button>
            </Box>
            {mutationDuplicate.isError ? (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <Box sx={{ color: '#f70500' }}>{mutationDuplicate.error.message}</Box>
                </Box>
            ) : null}
        </Box>
    )
}

export default HomeSimpleVersionView
