import React from "react"
import TextField from "@mui/material/TextField"
import Box from "@mui/material/Box"
import InputAdornment from "@mui/material/InputAdornment"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import { useForm, Controller, SubmitHandler } from "react-hook-form"
import {
    PowerElectricityType,
    type PowerElectricityFormInput,
} from "../../../types"
import CustomButton from "../../custom-button"
import { useCreatePowerElectricity } from "../../../hooks/use-power-electricity"
import { POWER_ELECTRICITY_TYPES } from "../../../constants"
import FormRow from "../helpers/form-row"

export default function CreatePowerElectricityForm({
    estateId,
}: {
    estateId: string | number | undefined,
}) {
    const { control, watch, handleSubmit } = useForm({
        defaultValues: {
            type: PowerElectricityType.spot,
            power_fees_amount_cents: '',
            power_electricity_amount_cents: '',
        },
    })

    const mutation = useCreatePowerElectricity(estateId, () => {})

    const onSubmit: SubmitHandler<PowerElectricityFormInput> = data => {
        mutation.mutate(data)
    }

    const typeWatch = watch('type')

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1.5,
            }}>
                <FormControl variant="standard" sx={{ minWidth: 130 }}>
                    <InputLabel id="power-electricity-type-label">Typ</InputLabel>
                    <Controller
                        name="type"
                        control={control}
                        render={({ field }) =>
                            <Select
                                labelId="power-electricity-type-label"
                                id="power-electricity-type"
                                {...field}
                            >
                                {POWER_ELECTRICITY_TYPES.map(powerElType => (
                                    <MenuItem key={powerElType.id} value={powerElType.id}>{powerElType.name}</MenuItem>
                                ))}
                            </Select>
                        }
                    />
                </FormControl>
                {typeWatch === PowerElectricityType.spot &&
                    <Controller
                        name="power_fees_amount_cents"
                        control={control}
                        render={({ field }) =>
                            <TextField
                                id="appliance-control-client-id"
                                label="Cena za službu obchodu"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">Kč bez DPH/MWh</InputAdornment>,
                                }}
                                variant="standard"
                                type="number"
                                required
                                {...field}
                            />}
                    />
                }
                {typeWatch === PowerElectricityType.flat &&
                    <Controller
                        name="power_electricity_amount_cents"
                        control={control}
                        render={({ field }) =>
                            <TextField
                                id="appliance-control-client-id"
                                label="Cena elektřiny"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">Kč bez DPH/MWh</InputAdornment>,
                                }}
                                variant="standard"
                                type="number"
                                required
                                {...field}
                            />}
                    />
                }
                <Box display="flex" justifyContent="center" mt={2} mb={2}>
                    <CustomButton
                        text="Přidat"
                        type="submit"
                        disabled={mutation.isPending}
                    />
                </Box>
                {mutation.isError ? (
                    <div>Došlo k chybě: {mutation.error.message}</div>
                ) : null}
            </Box>
        </form>
    )
}
