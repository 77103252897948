import React, { useState } from 'react'
import Box from '@mui/material/Box'

import { useForceOptimisation } from '../hooks/use-estate'
import CustomButton from './custom-button'

function ForceOptimisationButton({
    estateId,
}: {
    estateId: string | number | undefined,
}) {

    const [response, setResponse] = useState<string | null>(null)

    const mutation = useForceOptimisation(estateId, setResponse)
    
    return (
        <Box display="flex" flexDirection="column" mt={2}>
            <Box display="flex" justifyContent="center">
                <CustomButton
                    variant="outlined"
                    color="primary"    
                    text="Aktualizovat optimalizaci"
                    onClick={() => mutation.mutate()}
                />
            </Box>
            {response &&
                <Box sx={{
                    fontSize: '0.875rem',
                    color: 'primary',
                    textAlign: 'center',
                    marginTop: '1rem',
                }}>{response}</Box>
            }
        </Box>
    )
}

export default ForceOptimisationButton
