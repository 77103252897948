
import React, { useEffect, useState } from "react"
import TextField from "@mui/material/TextField"
import Box from "@mui/material/Box"
import { Controller } from "react-hook-form"
import Slider from "@mui/material/Slider"
import CircularProgress from "@mui/material/CircularProgress"
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded'

import Map from "./map"
import { FormControlWithLabelWrapper } from "../../../../views/wizard/steps/wizard-common"
import { Azimuth } from "./azimuth"
import { useEstate } from '../../../../hooks/use-estate'
import { numberOrUndefinedFromString } from "../_utils"

const clamp = (x, min, max) => x < min ? min : x > max ? max : x

export function AzimuthInput({
    watch,
    control,
    setValue,
    estateId
}: {
    watch,
    control,
    setValue,
    estateId: number | undefined
}) {

    // single source of truth is RHF text input
    const azimuthAsString: string = watch('azimuth')
    const azimuth: number = clamp(+azimuthAsString, -180, 180)

    // angle on slider is reversed, because html slider can't go from 180 to -180 (as azimut value does)

    // Slider value is based on this value
    const [sliderValue, setSliderValue] = useState(-azimuth)
    useEffect(() => {
        setSliderValue(-azimuth)
    }, [azimuth])

    const { status, data, error } = useEstate(estateId || 0)

    if (status === 'pending') {
        return <CircularProgress />
    }

    if (error) {
        return (
            <Box>
                <ErrorOutlineRoundedIcon />
                <Box>{error.message}</Box>
            </Box>
        )
    }

    return (
        <FormControlWithLabelWrapper
            label={'Orientace panelu (°)'}
            labelId="renewable-source-azimuth-slider"
        >
            <Azimuth azimuthAngle={-azimuth}>
                <Map
                    startingLatitude={numberOrUndefinedFromString(data.latitude)}
                    startingLongitude={numberOrUndefinedFromString(data.longitude)}
                />
            </Azimuth>

            <Controller
                name="azimuth"
                control={control}
                render={({ field }) =>
                    <TextField
                        id="renewable-source-azimuth-text"
                        label="Orientace (°)"
                        variant="standard"
                        type="number"
                        inputProps={{ min: -180, max: 180 }}
                        {...field}
                    />
                }
            />

            <Slider
                id="renewable-source-azimuth-slider"
                min={-180}
                max={180}
                step={1}
                value={sliderValue}
                onChange={e => {
                    const angle = +((e.target as HTMLInputElement).value)
                    setSliderValue(angle)
                    setValue('azimuth', -angle)
                }}
            />
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
            }}>
                <Box>180°</Box>
                <Box>0°</Box>
                <Box>-180°</Box>
            </Box>
        </FormControlWithLabelWrapper>
    )
}
