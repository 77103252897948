import React, { useEffect, useRef } from "react"
import dayjs from 'dayjs'
import 'dayjs/locale/cs'
import Box from "@mui/material/Box"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import { useForm, Controller, type SubmitHandler } from "react-hook-form"
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import { PlanOverrideType, type PlanOverrideFormInput } from "../../../types"
import CustomButton from "../../custom-button"
import { useCreatePlanOverride } from "../../../hooks/use-plan-override"
import FormRow from "../helpers/form-row"

export default function CreatePlanOverrideForm({
    appliancePlanId,
    handleClose,
}: {
    appliancePlanId: number | undefined,
    handleClose: Function,
}) {
    const { control, watch, setValue, handleSubmit } = useForm({
        defaultValues: {
            begins_at: dayjs(),
            ends_at: dayjs(),
            type: PlanOverrideType.party,
        },
    })

    const type = watch('type')
    const swap = useRef({
        changedAlready: false,
    })

    // First time he changes from `party` to `away` set the time to being and end of the day
    //
    // biome-ignore lint/correctness/useExhaustiveDependencies: we track only `type`
    useEffect(() => {
        if( type === PlanOverrideType.away ) {

            if(swap.current.changedAlready === false) { // only first time
                swap.current.changedAlready = true

                setValue('begins_at',dayjs().startOf('day'))
                setValue('ends_at',dayjs().endOf('day'))
            }
        }
    },[type])

    const mutation = useCreatePlanOverride(appliancePlanId, handleClose)

    const onSubmit: SubmitHandler<PlanOverrideFormInput> = data => {
        mutation.mutate(data)
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1.5,
            }}>
                <FormControl variant="standard" sx={{ flexGrow: 1, marginBottom: 1 }}>
                    <InputLabel id="plan-override-type-label">Typ</InputLabel>
                    <Controller
                        name="type"
                        control={control}
                        render={({ field }) =>
                            <Select
                                labelId="plan-override-type-label"
                                id="plan-override-type"
                                {...field}
                            >
                                <MenuItem value={PlanOverrideType.party}>Párty</MenuItem>
                                <MenuItem value={PlanOverrideType.away}>Dovolená</MenuItem>
                            </Select>
                        }
                    />
                </FormControl>
                <FormRow>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="cs">
                        <Controller
                            name="begins_at"
                            control={control}
                            render={({ field }) => 
                                <DateTimePicker label="Začátek" ampm={false} {...field}/>
                            }
                        />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="cs">
                        <Controller
                            name="ends_at"
                            control={control}
                            render={({ field }) => 
                                <DateTimePicker label="Konec" ampm={false} {...field}/>
                            }
                        />
                    </LocalizationProvider>
                </FormRow>
                <Box display="flex" justifyContent="center" mt={2}>
                    <CustomButton
                        text="Vytvořit"
                        type="submit"
                        disabled={mutation.isPending}
                    />
                </Box>
                {mutation.isError ? (
                    <div>Došlo k chybě: {mutation.error.message}</div>
                ) : null}
            </Box>
        </form>
    )
}
