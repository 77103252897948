import React, { ReactNode } from 'react'
import Box from '@mui/material/Box'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import Container from '@mui/material/Container'

export default function SwipeableTemporaryDrawer({
    open,
    toggleDrawer,
    children,
} : {
    open: boolean,
    toggleDrawer: Function,
    children: ReactNode,
}) {

    return (
        <SwipeableDrawer
            anchor="bottom"
            open={open}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
            PaperProps={{
                sx: {
                    borderTopLeftRadius: 20,
                    borderTopRightRadius: 20,
                }
                }}
        >
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                padding: 1,
            }}>
                <Box sx={{
                    width: '80px',
                    height: '3px',
                    borderRadius: '3px',
                    backgroundColor: 'primary.light',
                }} />
            </Box>
            <Container maxWidth="sm" sx={{
                minHeight: 550,
                paddingY: 2,
            }}>
                {children}
            </Container>
        </SwipeableDrawer>
    )
}
