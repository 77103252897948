import React from 'react'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded'
import { Controller, type SubmitHandler, useForm } from 'react-hook-form'
import TextField from '@mui/material/TextField'
import { Checkbox } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'
import ShowerRoundedIcon from '@mui/icons-material/ShowerRounded'

import { PreviousStepButton } from '../components'
import type { BasicConsumptionInput } from '../../../types'
import { type BasicConsumption, CONSUMPTION_TEXTS } from '../../../types'
import {
    BottomButtons,
    FormControlWithLabelWrapper,
    Hint,
    StepIcon,
    Textarea,
} from './wizard-common'
import {
    useBasicConsumption,
    useCreateBasicConsumption,
    useUpdateBasicConsumption,
} from '../../../hooks/use-basic-consumption'
import CustomButton from '../../../components/custom-button'
import { useDebug } from '../../debug'

function AlignToTextOfCheckbox({ children }) {
    return (
        <Box
            sx={{
                margin: '0 0 0 2rem',
            }}
        >
            {children}
        </Box>
    )
}

export function WizardStep3({
    nextStep,
    goBack,
    applianceId,
}: {
    nextStep: () => void
    goBack: () => void
    applianceId: number
}) {
    const { status, data, error } = useBasicConsumption(applianceId)

    if (status === 'pending') {
        return <CircularProgress />
    }

    if (error || data === undefined) {
        return (
            <Box>
                <ErrorOutlineRoundedIcon />
                <Box>{error.message}</Box>
            </Box>
        )
    }

    return (
        <>
            <Box>
                <StepIcon icon={<ShowerRoundedIcon />} color={'#E2F7FF'} />

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '4rem',
                    }}
                >
                    {
                        // basicConsumption.data != null && basicConsumption.data.length > 0 ? //  Don't show when no other items
                        // <PickExisting
                        //     estateId={estateId}
                        //     applianceId={applianceId}
                        //     onNextButtonClick={() => {
                        //         nextStep('step3')
                        //     }}
                        //     goBack={goBack}
                        //     disableSubmit={false} // ???
                        // />
                        // :
                        // null
                    }

                    <Box>
                        {data != null /* && data.length > 0 */ ? (
                            //  Don't show when no other items
                            <>
                                <UpdateExisting
                                    id={data.id}
                                    nextStep={nextStep}
                                    applianceId={applianceId}
                                    goBack={goBack}
                                />
                            </>
                        ) : (
                            <>
                                <CreateNew
                                    nextStep={nextStep}
                                    applianceId={applianceId}
                                    goBack={goBack}
                                />
                            </>
                        )}
                    </Box>
                </Box>
            </Box>
        </>
    )
}

function UpdateExisting({ applianceId, id, nextStep, goBack }) {
    const debug = useDebug({ name: 'UpdateExisting' })

    const { status, data, error } = useBasicConsumption(applianceId)

    const mutation = useUpdateBasicConsumption(applianceId, id, basicConsumptionId => {
        // alert(`updated basic consumption #${basicConsumptionId}`)
        nextStep()
    })

    const onSubmit: SubmitHandler<BasicConsumptionInput> = data => {
        debug.log('Update basic consumption', id)

        mutation.mutate(data)
    }

    if (status === 'pending') {
        return <CircularProgress />
    }

    if (error || data === undefined) {
        return (
            <Box>
                <ErrorOutlineRoundedIcon />
                <Box>{error.message}</Box>
            </Box>
        )
    }

    return (
        <Form
            applianceId={applianceId}
            goBack={goBack}
            onSubmit={onSubmit}
            data={data}
            disableSubmit={mutation.isPending}
        />
    )
}

function CreateNew({
    applianceId,
    nextStep,
    goBack,
}: {
    applianceId: number
    nextStep: () => void
    goBack: () => void
}) {
    const debug = useDebug({})

    const { control, handleSubmit } = useForm<BasicConsumptionInput>({
        defaultValues: {
            applianceId: applianceId,
            forCleaning: false,
            forDishwasher: false,
            forHandDishWashing: false,
            forHandWash: false,
            forShowering: false,
            forOtherUsage: '',
            personsCount: 2, // default value
        },
    })

    const { status, data, error } = useBasicConsumption(applianceId)

    const mutation = useCreateBasicConsumption(applianceId, basicConsumptionId => {
        //alert(`created basic consumption #${basicConsumptionId}`)
        nextStep()
    })

    const onSubmit: SubmitHandler<BasicConsumptionInput> = data => {
        debug.log('Create basic consumption')
        // @ts-ignore
        mutation.mutate(data)
    }

    if (status === 'pending') {
        return <CircularProgress />
    }

    if (error || data === undefined) {
        return (
            <Box>
                <ErrorOutlineRoundedIcon />
                <Box>{error.message}</Box>
            </Box>
        )
    }

    return (
        <Form
            applianceId={applianceId}
            goBack={goBack}
            onSubmit={onSubmit}
            disableSubmit={mutation.isPending}
        />
    )
}

function Form({
    applianceId,
    data,
    goBack,
    onSubmit,
    disableSubmit,
}: {
    applianceId: number
    data?: BasicConsumption
    goBack: () => void
    onSubmit: SubmitHandler<BasicConsumptionInput>
    disableSubmit?: boolean
}) {
    const debug = useDebug({})

    debug.log('applianceId', applianceId)
    debug.log('data', data)

    const { control, handleSubmit } = useForm<BasicConsumptionInput>({
        defaultValues: {
            applianceId: data?.appliance_id || applianceId,
            forCleaning: data?.for_cleaning ?? false,
            forDishwasher: data?.for_dishwasher ?? false,
            forHandDishWashing: data?.for_hand_dish_washing ?? false,
            forHandWash: data?.for_hand_wash ?? true,
            forShowering: data?.for_showering ?? true,
            forOtherUsage: data?.for_other_usage || '',
            personsCount: data?.persons_count ?? 2, // default value
        },
    })

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '4rem',
                }}
            >
                <FormControlWithLabelWrapper
                    label={'Kolik osob pravidelně používá vodu z tohoto bojleru?'}
                    component='h2'
                >
                    <Controller
                        name="personsCount"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                id="BasicConsumptionInput-personsCount"
                                label="Počet osob"
                                variant="standard"
                                type="number"
                                required
                                {...field}
                            />
                        )}
                    />
                </FormControlWithLabelWrapper>

                <FormControlWithLabelWrapper
                    label={'K čemu používáte tento bojler?'}
                    component='h2'
                    id="BasicConsumptionInput-usage"
                    // hint={'Teplota musí být minimálně 60°C kvůli legionelle.'}
                >
                    <Box role="group" aria-labelledby='BasicConsumptionInput-usage' >
                        <Controller
                            name="forShowering"
                            control={control}
                            render={({ field }) => (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={field.value}
                                            {...field}
                                            onChange={e => field.onChange(e.target.checked)}
                                        />
                                    }
                                    label={CONSUMPTION_TEXTS.forShowering.label}
                                />
                            )}
                        />

                        <Box>
                            <Controller
                                name="forHandWash"
                                control={control}
                                render={({ field }) => (
                                    <>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={field.value}
                                                    {...field}
                                                    onChange={e => field.onChange(e.target.checked)}
                                                />
                                            }
                                            label={CONSUMPTION_TEXTS.forHandWash.label}
                                        />
                                        <AlignToTextOfCheckbox>
                                            <Hint>{CONSUMPTION_TEXTS.forHandWash.hint}</Hint>
                                        </AlignToTextOfCheckbox>
                                    </>
                                )}
                            />
                        </Box>

                        <Box>
                            <Controller
                                name="forHandDishWashing"
                                control={control}
                                render={({ field }) => (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={field.value}
                                                {...field}
                                                onChange={e => field.onChange(e.target.checked)}
                                            />
                                        }
                                        label={CONSUMPTION_TEXTS.forHandDishWashing.label}
                                    />
                                )}
                            />

                            <AlignToTextOfCheckbox>
                                <Hint>{CONSUMPTION_TEXTS.forHandDishWashing.hint}</Hint>
                            </AlignToTextOfCheckbox>
                        </Box>

                        <Box>
                            <Controller
                                name="forDishwasher"
                                control={control}
                                render={({ field }) => (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={field.value}
                                                {...field}
                                                onChange={e => field.onChange(e.target.checked)}
                                            />
                                        }
                                        label={CONSUMPTION_TEXTS.forDishwasher.label}
                                    />
                                )}
                            />
                            <AlignToTextOfCheckbox>
                                <Hint>{CONSUMPTION_TEXTS.forDishwasher.hint}</Hint>
                            </AlignToTextOfCheckbox>
                        </Box>

                        <Box>
                            <Box
                                sx={{
                                    color: '#071F3F',
                                    fontWeight: '400',
                                    fontSize: '1rem',
                                    lineHeight: '1.5',
                                    margin: '0 0 .5rem 0',
                                }}
                            >
                                {CONSUMPTION_TEXTS.forOtherUsage.label}
                            </Box>
                            <Box
                                sx={{
                                    margin: '0 0 .5rem 0',
                                }}
                            >
                                <Hint>{CONSUMPTION_TEXTS.forOtherUsage.hint}</Hint>
                            </Box>
                            <Controller
                                name="forOtherUsage"
                                control={control}
                                render={({ field }) => (
                                    <Textarea
                                        {...field}
                                        sx={{
                                            resize: 'vertical',
                                        }}
                                    />
                                )}
                            />
                        </Box>
                    </Box>
                </FormControlWithLabelWrapper>

                <BottomButtons>
                    <PreviousStepButton
                        onClick={() => {
                            goBack()
                        }}
                    >
                        Zpět
                    </PreviousStepButton>

                    <CustomButton text="Pokračovat" type="submit" disabled={disableSubmit} />
                </BottomButtons>
            </Box>
        </form>
    )
}
