import React from "react"
import Box from "@mui/material/Box"

export function Declination({ declination }) {
    const declinationCSSAngle = declination - 90

    // Declination angle = CSS angle
    // 90 = 0
    // 0 = -90
    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
        }}>
            {/* {declination} */}
            <Box sx={{ position: 'relative' }}>
                <Box sx={{
                    position: 'absolute',
                    width: '20px',
                    height: '110px',
                    zIndex: '10000',
                    background: '#001dff',
                    bottom: 'calc( 20px )',
                    left: 'calc( 50% - -17px )',
                    pointerEvents: 'none',
                    opacity: '.5',
                    transform: `rotate(${declinationCSSAngle}deg)`,
                    transformOrigin: 'bottom',
                }} />
                <img
                    src="/panel_declination.svg"
                    loading="lazy"
                    alt="Panel declination hint"
                    style={{
                        maxWidth: '100%',
                        width: '200px',

                    }}
                />
            </Box>
        </Box>
    )
}
