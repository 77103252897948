import React from 'react'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded'
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined'

import { BuyingOfElectricity } from './components/BuyingOfElectricity'
import { useEstate } from '../../../hooks/use-estate'
import { StepIcon } from './wizard-common'

export function WizardStep7({
    nextStep,
    goBack,
    estateId,
}: {
    nextStep: () => void
    goBack: () => void
    estateId: number
}) {
    const { status, data, error } = useEstate(estateId)

    if (status === 'pending') {
        return <CircularProgress />
    }

    if (error) {
        return (
            <Box>
                <ErrorOutlineRoundedIcon />
                <Box>{error.message}</Box>
            </Box>
        )
    }

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1rem',
                }}
            >
                <StepIcon icon={<ShoppingCartOutlinedIcon />} color={'#ffecdb'} />

                <BuyingOfElectricity estateId={estateId} goBack={goBack} nextStep={nextStep} />
            </Box>
        </>
    )
}
