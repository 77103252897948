import axios from 'axios'
import {
    useQuery,
    useMutation,
    useQueryClient,
} from '@tanstack/react-query'
import { SellElectricityType, type SellElectricity, type SellElectricityFormInput } from '../types'
import { convertToKiloWattHoursPrice } from '../helpers'

export function useSellElectricities(estateId) {
    return useQuery({
        queryKey: ['sellElectricities-' + estateId],
        queryFn: async () => {
            const { data } : { data : Array<SellElectricity> } = await axios.get(
                '/api/sell_electricity/list?estate_id=' + estateId,
            )
            return data
        },
    })
}

export function useSellElectricity(sellElectricityId) { 
    return useQuery({
        queryKey: ['sellElectricity-' + sellElectricityId],
        queryFn: async () => {
            const { data } : { data : SellElectricity } = await axios.get(
                '/api/sell_electricity/single?id=' + sellElectricityId,
            )
            return data
        },
    })
}

export const useCreateSellElectricity = (estateId, returnToList) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (data: SellElectricityFormInput) => {            
            return axios.get(`/api/sell_electricity/create?estate_id=${estateId
            }&type=${data.type
            }${data.type !== SellElectricityType.null ?
                '&sell_fees_amount_cents='+convertToKiloWattHoursPrice(data.sell_fees_amount_cents) : ''
            }${data.type === SellElectricityType.flat ?
                '&sell_amount_cents='+convertToKiloWattHoursPrice(data.sell_amount_cents) : ''
            }${data.type === SellElectricityType.spot ?
                '&cant_be_negative='+(data.cant_be_negative ? 'true' : 'false') : ''
            }`
            )
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['sellElectricities-' + estateId] })
            returnToList()
        },
    })
}

export const useUpdateSellElectricity = (sellElectricityId, estateId, handleClose) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (data: SellElectricityFormInput) => {
            return axios.get(`/api/sell_electricity/update?id=${sellElectricityId
            }&type=${data.type
            }${data.type !== SellElectricityType.null ?
                '&sell_fees_amount_cents='+convertToKiloWattHoursPrice(data.sell_fees_amount_cents) : ''
            }${data.type === SellElectricityType.flat ?
                '&sell_amount_cents='+convertToKiloWattHoursPrice(data.sell_amount_cents) : ''
            }${data.type === SellElectricityType.spot ?
                '&cant_be_negative='+(data.cant_be_negative ? 'true' : 'false') : ''
            }`
            )
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['sellElectricities-' + estateId] })
            queryClient.invalidateQueries({ queryKey: ['sellElectricity-' + sellElectricityId] })
            handleClose()
        },
    })
}
