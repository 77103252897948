import React from 'react'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import { Link, useParams } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress'
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded'
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded'

import packageJson from './../../../package.json'
// import BatteryCard from '../components/battery-card'
import { IconButton } from '@mui/material'
import { useEstate } from '../hooks/use-estate'
import EstateSettingsTabs from '../components/estate-settings-tabs'
import { Estate } from '../types'
import { useRedirectToSimpleHomepageIfEnabled } from '../hooks/use-simple-homepage'

function EstateSettingsView() {

    useRedirectToSimpleHomepageIfEnabled()
    
    let { estateId } = useParams()

    const { status, data, error }: {status: any, data: Estate | undefined, error: any} = useEstate(estateId)

    if (status === 'pending') {
        return (
            <CircularProgress />
        )
    }

    if (error || data === undefined) {
        return (
            <Box>
                <ErrorOutlineRoundedIcon />
                <Box>{error.message}</Box>
            </Box>
        )
    }

    return (
        <>
            <Container maxWidth="sm">
            <Box display="flex" alignContent="center">
                    <Box marginLeft={-1}>
                        <IconButton
                            color="primary"
                            aria-label="back"
                            component={Link}
                            to={'/estate/'+estateId}
                        >
                            <ArrowBackIosNewRoundedIcon />
                        </IconButton>
                    </Box>
                    <Box sx={{
                        fontSize: '1.25rem',
                        flexGrow: 1,
                        color: 'primary.main',
                        display: 'flex',
                        alignItems: 'center',
                        paddingLeft: 1,
                    }}>
                        {'Nastavení '+data.name}
                    </Box>
                </Box>
                <EstateSettingsTabs estate={data} />
                <Box sx={{
                    marginTop: 3,
                    fontSize: '0.75rem',
                    color: 'primary.main',
                    textAlign: 'center',
                }}>
                    <p>ChargeControl v{packageJson.version}</p>
                    <p>© Factorio Solutions</p>
                </Box>
            </Container>
        </>
    )
}

export default EstateSettingsView
