import React, { useEffect } from 'react'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import { Link } from 'react-router-dom'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import LockRoundedIcon from '@mui/icons-material/LockRounded'
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded'
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import { useAtom } from 'jotai'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import CircularProgress from '@mui/material/CircularProgress'
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";

import {
    simplifiedAppVersion,
    simplifiedAppVersionConfig
} from '../state/application'
import { useEstates } from '../hooks/use-estate'
import { useAppliances } from '../hooks/use-appliance'
import { FormControlWithLabelWrapper } from './wizard/steps/wizard-common'
import packageJson from './../../../package.json'
import { useAppliancePlans } from '../hooks/use-appliance-plan'
import { useAutoSelectFirstEstateFirstAppliancePlanAndAppliances } from '../hooks/use-simple-homepage'


function ProfileView() {

    const domNode = document.getElementById('react-root')
    const userEmail = domNode?.getAttribute('user-email')


    // This page is added on top of rails form which has logout button, so show that button after component is loaded
    useEffect(() => {
        const form: HTMLElement | null = document.querySelector('.js-sign-out-form')
        if( form ) {
            // show form
            form.style.display = 'flex'

            // confirm logout
            form.onsubmit = function (event) {
                if(!confirm("Ukončit aplikaci?")) {
                    event.preventDefault()
                }
            }
        }
    },[])

    return (
        <>
            <Container maxWidth="sm">
                {/* TODO: Abstract - used accross pages */}
                <Box display="flex" alignContent="center">
                    <Box marginLeft={-1}>
                        <IconButton
                            color="primary"
                            aria-label="back"
                            href="/"
                        >
                            <ArrowBackIosNewRoundedIcon />
                        </IconButton>
                    </Box>
                    <Box sx={{
                        fontSize: '1.25rem',
                        flexGrow: 1,
                        color: 'primary.main',
                        display: 'flex',
                        alignItems: 'center',
                        paddingLeft: 1,
                    }}>
                        Účet
                    </Box>
                    <Box>
                        <IconButton
                            color="primary"
                            aria-label="settings"
                            disabled
                            component={Link}
                            to="/"
                        >
                            <SettingsRoundedIcon />
                        </IconButton>
                    </Box>
                </Box>
                {/* <Box sx={{
                    color: 'primary.main',
                    fontWeight: 400,
                    marginTop: '1rem',
                    marginBottom: 0.5,
                    paddingLeft: '0.4rem',
                 }}>
                    Jméno a příjmení
                </Box>
                <Box sx={{
                    color: 'primary.main',
                    fontWeight: 400,
                    fontSize: '1.2rem',
                    marginBottom: '1.5rem',
                    paddingLeft: '0.4rem',
                 }}>
                    Franta Flinta
                </Box> */}

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '3rem',
                    margin: '3rem 0  0 0'
                }}>
                    <SimplifiedConfig />

                    <FormControlWithLabelWrapper
                        label={"Email"}
                    >
                        <Box sx={{
                            color: 'primary.main',
                            fontWeight: 400,
                            fontSize: '1.2rem',
                            marginBottom: '1.5rem',
                            paddingLeft: '0.4rem',
                        }}>
                            {userEmail}
                        </Box>
                    </FormControlWithLabelWrapper>
                </Box>
                
                {/* <Box sx={{
                    color: 'primary.main',
                    fontWeight: 400,
                    marginBottom: 0.5,
                    paddingLeft: '0.4rem',
                 }}>
                    Subscription
                </Box>
                <Box sx={{
                    color: 'primary.main',
                    fontWeight: 400,
                    fontSize: '1.2rem',
                    paddingLeft: '0.4rem',
                 }}>
                    Roční
                </Box>
                <Box sx={{
                    color: 'primary.main',
                    fontWeight: 400,
                    marginBottom: 0.5,
                    paddingLeft: '0.4rem',
                 }}>
                    K obnovení dojde 25.9.2024
                </Box> */}
                
                {/* <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: 6,
                }}>
                    <Button
                        startIcon={<LockRoundedIcon fontSize='large' />}
                        size='large'
                        sx={{
                            fontSize: '1rem',
                            fontWeight: 400,
                        }}
                    >
                        Odhlásit se
                    </Button>
                </Box>
                <Box sx={{
                    marginTop: 3,
                    fontSize: '0.75rem',
                    color: 'primary.main',
                    textAlign: 'center',
                }}>
                    <p>ChargeControl v{packageJson.version}</p>
                    <p>© Factorio Solutions</p>
                </Box> */}
            </Container>
        </>
    )
}

function SimplifiedConfig() {

    const [simplifiedAppVersionValue, setSimplifiedAppVersion] = useAtom(simplifiedAppVersion)
    const [simplifiedConfig, setSimplifiedConfig] = useAtom(simplifiedAppVersionConfig)

    const estateList = useEstates()
    const applianceList = useAppliances(simplifiedConfig.selectedEstateId)
    const appliancePlans = useAppliancePlans(simplifiedConfig.selectedApplianceId)

    useAutoSelectFirstEstateFirstAppliancePlanAndAppliances()

    if (applianceList.status === 'pending') {
        return (
            <CircularProgress />
        )
    }

    if (applianceList.error) {
        return (
            <Box>
                <ErrorOutlineRoundedIcon />
                <Box>{applianceList.error.message}</Box>
            </Box>
        )
    }

    if (appliancePlans.status === 'pending') {
        return (
            <CircularProgress />
        )
    }

    if (appliancePlans.error) {
        return (
            <Box>
                <ErrorOutlineRoundedIcon />
                <Box>{appliancePlans.error.message}</Box>
            </Box>
        )
    }


    if (estateList.status === 'pending') {
        return (
            <CircularProgress />
        )
    }

    if (estateList.error) {
        return (
            <Box>
                <ErrorOutlineRoundedIcon />
                <Box>{estateList.error.message}</Box>
            </Box>
        )
    }

    return (
        <>
            <FormControlLabel 
                control={
                    <Switch
                        onChange={(e) => setSimplifiedAppVersion(e.target.checked)}
                        checked={simplifiedAppVersionValue}
                    />
                }
                label="Zjednodušený režim aplikace" 
            />

            <Box sx={{
                margin: '0 0 0 1rem'
            }}>
            {
                simplifiedAppVersionValue
                ? 
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '3rem',
                    }}>
                        <FormControlWithLabelWrapper
                            label={"Vybraná nemovitost"}
                        >
                            <Select
                                value={simplifiedConfig.selectedEstateId}
                                onChange={(event) => {
                                    const id = + event.target.value
                                    setSimplifiedConfig({
                                        ...simplifiedConfig,
                                        selectedEstateId: id,
                                        selectedApplianceId: 0,
                                        selectedAppliancePlanId: 0,
                                    })
                                }}
                            >
                                {estateList.data && estateList.data.map((item, index) => (
                                    <MenuItem key={item.id} value={item.id}>{item.name} #{item.id}</MenuItem>
                                ))}
                            </Select>
                        </FormControlWithLabelWrapper>

                        <FormControlWithLabelWrapper
                            label={"Vybraný bojler"}
                        >
                            <Select
                                value={simplifiedConfig.selectedApplianceId}
                                onChange={(event) => {
                                    const id = + event.target.value
                                    setSimplifiedConfig({
                                        ...simplifiedConfig,                                        
                                        selectedApplianceId: id,
                                        selectedAppliancePlanId: 0,
                                    })
                                }}
                            >
                                {applianceList.data && applianceList.data.map((item, index) => (
                                    <MenuItem key={item.id} value={item.id}>bojler #{item.id}</MenuItem>
                                ))}
                            </Select>
                        </FormControlWithLabelWrapper>

                        {/*
                        <FormControlWithLabelWrapper
                            label={"Vybraný plán"}
                        >
                            <Select
                                value={simplifiedConfig.selectedAppliancePlanId}
                                onChange={(event) => {
                                    const id = + event.target.value
                                    setSimplifiedConfig({
                                        ...simplifiedConfig,                                        
                                        selectedAppliancePlanId: id,
                                    })
                                }}
                            >
                                {appliancePlans.data && appliancePlans.data.map((item, index) => (
                                    <MenuItem key={item.id} value={item.id}>plán #{item.id}</MenuItem>
                                ))}
                            </Select>
                        </FormControlWithLabelWrapper>
                        */}

                    </Box>
                :
                    <Box />
            }
            </Box>
        </>
    )
}

export default ProfileView
