import React, { MouseEventHandler } from 'react'
import Box from '@mui/material/Box'
import ButtonBase from '@mui/material/ButtonBase'
import Typography from '@mui/material/Typography'
import AddRoundedIcon from '@mui/icons-material/AddRounded'

function CreateButton({
    text,
    onClick,
}: {
    text: string,
    onClick: MouseEventHandler,
}) {

    return (
        <ButtonBase
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                backgroundColor: 'customBeige.main',
                paddingX: 2,
                paddingY: 2.5,
                paddingRight: 3,
                borderRadius: 5,
                "&.Mui-focusVisible": {
                    backgroundColor: 'customBeige.dark',
                },
            }}
            component="div"
            onClick={onClick}
        >
            <Box sx={{
                paddingRight: 1.5,
                fontSize: '1.5rem',
                lineHeight: 0,
            }}>
                <AddRoundedIcon fontSize='inherit'/>
            </Box>
            <Typography component="div" sx={{ fontSize: '1.25rem' }}>{text}</Typography>
        </ButtonBase>
    )
}

export default CreateButton
