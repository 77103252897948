import React, { useState } from 'react'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import CircularProgress from '@mui/material/CircularProgress'
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded'
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded'

import AppliancePlanButton from './appliance-plan-button'
import CreateButton from './create-button'
import CreateAppliancePlanForm from './forms/appliance-plan/create'
import { useAppliancePlans } from '../hooks/use-appliance-plan'

function AppliancePlanSelector({
    applianceId,
    resetDrawer,
}: {
    applianceId: string | undefined,
    resetDrawer: Function,
}) {

    const [newAppliancePlan, setNewAppliancePlan] = useState<boolean>(false)
    
    const { status, data, error } = useAppliancePlans(applianceId)

    if (status === 'pending') {
        return (
            <CircularProgress />
        )
    }

    if (error) {
        return (
            <Box>
                <ErrorOutlineRoundedIcon />
                <Box>{error.message}</Box>
            </Box>
        )
    }

    return (
        <Box
            // onClick={toggleDrawer(false)}
            // onKeyDown={toggleDrawer(false)}
        >
            {!newAppliancePlan &&
                <>
                    <Box sx={{
                        fontSize: '1.25rem',
                        flexGrow: 1,
                        color: 'primary.main',
                        paddingX: 2,
                        marginBottom: 1.5,
                    }}>
                        Výběr plánu
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1.5,
                    }}>
                        {data.map((appliancePlan, index) => (
                            <AppliancePlanButton
                                key={index}
                                appliancePlan={appliancePlan}
                                applianceId={applianceId}
                                resetDrawer={resetDrawer}

                            />
                        ))}
                        {/* Temporarily hiding creation of new plans when at least one exists */}
                        {data.length === 0 &&
                            <Box display="flex" my={2}>
                                <CreateButton text='Nový plán' onClick={() => setNewAppliancePlan(true)}/>
                            </Box>
                        }
                    </Box>
                </>
            }
            {newAppliancePlan &&
                <>
                    <Box display="flex" alignContent="center">
                        <Box marginLeft={-1}>
                            <IconButton
                                color="primary"
                                aria-label="back"
                                onClick={() => setNewAppliancePlan(false)}
                            >
                                <ArrowBackIosNewRoundedIcon />
                            </IconButton>
                        </Box>
                        <Box sx={{
                            fontSize: '1.25rem',
                            flexGrow: 1,
                            color: 'primary.main',
                            display: 'flex',
                            alignItems: 'center',
                            paddingLeft: 1,
                        }}>
                            Nový plán
                        </Box>
                    </Box>
                    <CreateAppliancePlanForm applianceId={applianceId} returnToList={() => setNewAppliancePlan(false)}/>
                </>
            }
        </Box>
    )
}

export default AppliancePlanSelector
