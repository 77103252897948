import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import type { Appliance, BasicConsumption } from "../types";
import { BasicConsumptionInput } from "../types";

export function useBasicConsumption(applianceId: number) {
    return useQuery({
        queryKey: ['basic_consumptions-' + applianceId],
        queryFn: async () => {
            const { data } : { data : BasicConsumption } = await axios.get(
                '/api/basic_consumption/single?appliance_id=' + applianceId,
            )
            return data
        },
    })
}

export const useCreateBasicConsumption = (applianceId: number, onSuccess?: (basicConsumptionId: number) => void) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (data: BasicConsumptionInput) => {
            return axios.get(
            `/api/basic_consumption/create?${
                [
                    'persons_count=' + encodeURIComponent(data.personsCount),
                    'for_showering=' + encodeURIComponent(data.forShowering),
                    'for_hand_wash=' + encodeURIComponent(data.forHandWash),
                    'for_hand_dish_washing=' + encodeURIComponent(data.forHandDishWashing),
                    'for_dishwasher=' + encodeURIComponent(data.forDishwasher),
                    'for_cleaning=' + encodeURIComponent(data.forCleaning),
                    'for_other_usage=' + encodeURIComponent(data.forOtherUsage),
                    'appliance_id=' + encodeURIComponent(applianceId),
                    
                ].join('&')
            }`)
        },
        onSuccess: response => {
            queryClient.invalidateQueries({ queryKey: ['basic_consumptions-' + applianceId] })
            // queryClient.invalidateQueries({ queryKey: ['basic_consumption-' + response.data.id] })

            if(onSuccess) {
                onSuccess(response.data.id)
            }
        },
    })
}

export const useUpdateBasicConsumption = (applianceId: number, basicConsumptionId: number, onSuccess?: (basicConsumptionId: number) => void) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (data: BasicConsumptionInput) => {
            return axios.get(
                `/api/basic_consumption/update?${
                    [
                        'id=' + encodeURIComponent(basicConsumptionId),
                        'persons_count=' + encodeURIComponent(data.personsCount),
                        'for_showering=' + encodeURIComponent(data.forShowering),
                        'for_hand_wash=' + encodeURIComponent(data.forHandWash),
                        'for_hand_dish_washing=' + encodeURIComponent(data.forHandDishWashing),
                        'for_dishwasher=' + encodeURIComponent(data.forDishwasher),
                        'for_cleaning=' + encodeURIComponent(data.forCleaning),
                        'for_other_usage=' + encodeURIComponent(data.forOtherUsage),
                        'appliance_id=' + encodeURIComponent(applianceId),
                    ].join('&')
                }`)
        },
        onSuccess: response => {
            queryClient.invalidateQueries({ queryKey: ['basic_consumptions-' + applianceId] })
            // queryClient.invalidateQueries({ queryKey: ['basic_consumption-' + basicConsumptionId] })

            onSuccess(response.data.id)
        },
    })
}
