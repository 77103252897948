import React from 'react'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import { Navigate } from 'react-router-dom'
import { useAtom } from 'jotai'
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded'
import { IconButton } from '@mui/material'

import packageJson from './../../../package.json'
import EstateSelector from '../components/estate-selector'
import { selectedEstateIdAtom } from '../state/application'
import { useRedirectToWizard } from './wizard/hooks'

function HomeFullVersionView() {

    //throw new Response("Not Found", { status: 404 })

    const [selectedEstateId, setSelectedEstateId] = useAtom(selectedEstateIdAtom)

    const {progress} = useRedirectToWizard()

    if (selectedEstateId) {
        return (
            <Navigate to={'/estate/'+selectedEstateId} />
        )
    }

    // first time user doesn't have estate selected, no need to show progress/error

    if(progress) {
        return progress
    }

    return (
        <>
            <Container maxWidth="sm">
                <Box display="flex" justifyContent="space-between">
                    <Box />
                    <IconButton href="/sign_out" color='primary'>
                        <AccountCircleRoundedIcon />
                    </IconButton>
                </Box>
                <EstateSelector />
                <Box sx={{
                    marginTop: 3,
                    fontSize: '0.75rem',
                    color: 'primary.main',
                    textAlign: 'center',
                }}>
                    <p>ChargeControl v{packageJson.version}</p>
                    <p>© Factorio Solutions</p>
                </Box>
            </Container>
        </>
    )
}

export default HomeFullVersionView
