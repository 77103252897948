import React, { useState, SyntheticEvent } from 'react'
import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { Estate } from '../types'
import UpdateEstateForm from './forms/estate/update'
import DeleteEstateForm from './forms/estate/delete'
import PowerElectricityList from './lists/power-electricity'
import SellElectricityList from './lists/sell-electricity'
import DistributionElectricityList from './lists/distribution-electricity'
import CreateDistributionElectricityForm from './forms/distribution-electricity/create'
import OptimiseNowButton from './optimise-now-button'
import ForceOptimisationButton from './force-optimisation-button'

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

interface TabPanelProps {
    children?: React.ReactNode
    dir?: string
    index: number
    value: number
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ pt: 3 }}>
            {children}
          </Box>
        )}
      </div>
    )
}

function EstateSettingsTabs({
    estate,
}: {
    estate: Estate,
}) {

    const [value, setValue] = useState(0)

    const handleChange = (event: SyntheticEvent, newValue: number) => {
        setValue(newValue)
    }

    return (
        <Box sx={{ width: '100%', minHeight: '680px' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="estate settings tabs" variant='fullWidth'>
                    <Tab label="Nemovitost" {...a11yProps(0)} sx={{ textTransform: 'none', fontSize: '1rem' }}/>
                    <Tab label="Nákup el." {...a11yProps(1)} sx={{ textTransform: 'none', fontSize: '1rem' }} />
                    <Tab label="Prodej el." {...a11yProps(2)} sx={{ textTransform: 'none', fontSize: '1rem' }} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <UpdateEstateForm estate={estate}/>
                <DeleteEstateForm estateId={estate.id}/>
                {/* Optimise now for debugging purposes */}
                <OptimiseNowButton estateId={estate.id}/>
                <ForceOptimisationButton estateId={estate.id}/>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Box sx={{
                    color: 'primary.main',
                    fontWeight: 400,
                    marginTop: '0.5rem',
                    marginBottom: '0.5rem',
                    fontSize: '1.1rem',
                }}>
                    Silová elektřina
                </Box>
                <PowerElectricityList estateId={estate.id} />
                <Box sx={{
                    color: 'primary.main',
                    fontWeight: 400,
                    marginTop: '1.5rem',
                    marginBottom: '0.5rem',
                    fontSize: '1.1rem',
                }}>
                    Distribuce
                </Box>
                {estate.distribution_electricity_id ?
                    <DistributionElectricityList
                        distributionElectricityId={estate.distribution_electricity_id}
                    />
                    :
                    <Box sx={{
                        flexGrow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        backgroundColor: 'customGrey.light',
                        padding: 2,
                        borderRadius: 5,
                    }}>
                        <CreateDistributionElectricityForm estateId={estate.id} />
                    </Box>
                }
            </TabPanel>
            <TabPanel value={value} index={2}>
                <SellElectricityList estateId={estate.id} />
            </TabPanel>
        </Box>
    )
}

export default EstateSettingsTabs
