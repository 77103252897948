import React, { MouseEventHandler } from 'react'
import Button from '@mui/material/Button'
import type { ButtonOwnProps } from '@mui/material/Button'

function CustomButton({
    text,
    onClick,
    disabled,
    type,
    color = 'primary',
    variant = 'contained',
    endIcon
}: {
    text: string,
    onClick?: MouseEventHandler,
    disabled?: boolean,
    type?: 'submit',
    color?: ButtonOwnProps["color"],
    variant?: ButtonOwnProps["variant"],
    endIcon?: React.ReactNode
}) {

    return (
        <Button
            sx={{
                paddingX: 4,
                paddingY: 1.5,
                borderRadius: 5,
                fontSize: '1rem',
            }}
            color={color}
            variant={variant}
            onClick={onClick ? onClick : () => {}}
            disabled={disabled}
            type={type}
            endIcon={endIcon}
        >
            {text}
        </Button>
    )
}

export default CustomButton
