import axios from 'axios'
import { AxiosError } from 'axios'
import {
    useQuery,
    useMutation,
    useQueryClient,
} from '@tanstack/react-query'
import { ApplianceControlType, type ApplianceControl, type ApplianceControlFormInput } from '../types'
import { useDebug } from "../views/debug";

export function useApplianceControls(applianceId) {
    return useQuery({
        queryKey: ['applianceControls-' + applianceId],
        queryFn: async () => {
            const { data } : { data : Array<ApplianceControl> } = await axios.get(
                '/api/appliance_control/list?appliance_id=' + applianceId,
            )
            return data
        },
    })
}

export function useApplianceControl(applianceControlId) {
    return useQuery({
        queryKey: ['applianceControl-' + applianceControlId],
        queryFn: async () => {
            const { data } : { data : ApplianceControl } = await axios.get(
                '/api/appliance_control/single?id=' + applianceControlId,
            )
            return data
        },
    })
}

export function useApplianceControlTest(
    applianceControlId,
    setResponse,
    onError?: (error: string) => void,
    onSuccess?: () => void,
) {
    const queryClient = useQueryClient()

    const debug = useDebug({name: '!!!! TEST !!! useApplianceControlTest !!!'})
    
    return useMutation({
        mutationFn: () => {
            return axios.get(
                '/api/appliance_control/test?id=' + applianceControlId,
            )
        },
        onSuccess: response => {
            queryClient.invalidateQueries({ queryKey: ['test-' + applianceControlId] })

            debug.logObject('succes -> response', response)

            if (response.data?.success) {
                debug.log('succes -> response -> success')
                if(onSuccess) {
                    debug.log('succes -> response -> success -> callback')
                    onSuccess()
                }
                setResponse('Test úspěšný')
            } else {
                debug.log('succes -> response -> error')
                if(onError) {
                    onError('')
                }
                setResponse('Test vrátil chybu')
            }
        },
        onError: response => {
            queryClient.invalidateQueries({ queryKey: ['test-' + applianceControlId] })

            debug.logObject('error -> response', response)

            if (response instanceof AxiosError && response.response?.data?.error) {
                if(onError) {
                    onError(response.response?.data?.error)
                }
                setResponse(response.response?.data?.error)
            } else {
                if(onError) {
                    onError('')
                }
                setResponse('Test neúspěšný')
            }
        },
    })
}

export const useCreateApplianceControl = (applianceId, returnToList) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (data: ApplianceControlFormInput) => {            
            const blob = {
                "client_id": data.type === ApplianceControlType.tuya && data.client_id ? data.client_id : undefined,
                "client_secret": data.type === ApplianceControlType.tuya && data.client_secret ? data.client_secret : undefined,
                "cloud_server": data.type === ApplianceControlType.shelly && data.cloud_server ? data.cloud_server : undefined,
                "cloud_key": data.type === ApplianceControlType.shelly && data.cloud_key ? data.cloud_key : undefined,
            }

            return axios.get(`/api/appliance_control/create?appliance_id=${applianceId
            }&device_id=${data.device_id
            }&type=${data.type
            }&blob=${JSON.stringify(blob)}`
            )
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['applianceControls-' + applianceId] })
            returnToList()
        },
    })
}

export const useUpdateApplianceControl = (applianceControlId, applianceId, handleClose) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (data: ApplianceControlFormInput) => {
            const blob = {
                "client_id": data.type === ApplianceControlType.tuya && data.client_id ? data.client_id : undefined,
                "client_secret": data.type === ApplianceControlType.tuya && data.client_secret ? data.client_secret : undefined,
                "cloud_server": data.type === ApplianceControlType.shelly && data.cloud_server ? data.cloud_server : undefined,
                "cloud_key": data.type === ApplianceControlType.shelly && data.cloud_key ? data.cloud_key : undefined,
            }

            return axios.get(`/api/appliance_control/update?id=${applianceControlId
            }&device_id=${data.device_id
            }&type=${data.type
            }&blob=${JSON.stringify(blob)}`
            )
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['applianceControls-' + applianceId] })
            queryClient.invalidateQueries({ queryKey: ['applianceControl-' + applianceControlId] })
            handleClose()
        },
    })
}
