import React from 'react'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded'

import { useApplianceControls } from '../../hooks/use-appliance-control'
import ApplianceControlTestButton from '../appliance-control-test-button'

function ApplianceControlWebsocketList({
    applianceId,
} : {
    applianceId: string | number | undefined,
}) {

    const { status, data, error } = useApplianceControls(applianceId)

    if (status === 'pending') {
        return (
            <CircularProgress />
        )
    }

    if (error) {
        return (
            <Box>
                <ErrorOutlineRoundedIcon />
                <Box>{error.message}</Box>
            </Box>
        )
    }

    if (data.length > 0) {
        return (
            <ApplianceControlTestButton applianceControlId={data[0].id} />
        )
    } else {
        return (
            <Box textAlign="center" mt={2}>
                Zatím nemáte přidanou zásuvku
            </Box>
        )
    }
    
}

export default ApplianceControlWebsocketList
