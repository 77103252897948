import React from 'react'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded'

import { useRenewableSources } from '../../hooks/use-renewable-source'
import RenewableSourceCard from '../renewable-source-card'

function RenewableSourceList({
    estateId,
} : {
    estateId: string | undefined,
}) {

    const { status, data, error } = useRenewableSources(estateId)

    if (status === 'pending') {
        return (
            <CircularProgress />
        )
    }

    if (error) {
        return (
            <Box>
                <ErrorOutlineRoundedIcon />
                <Box>{error.message}</Box>
            </Box>
        )
    }

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1.5,
        }}>
            {data.map((renewableSource, index) => (
                <RenewableSourceCard
                    key={index}    
                    renewableSource={renewableSource}
                />
            ))}
        </Box>
    )
}

export default RenewableSourceList
