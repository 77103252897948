import React from 'react'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded'

import { useDistributionElectricity } from '../../hooks/use-distribution-electricity'
import HdoList from './hdo'
import DistributionElectricityCard from '../distribution-electricity-card'

function DistributionElectricityList({
    distributionElectricityId,
} : {
    distributionElectricityId: string | number | undefined,
}) {

    const { status, data, error } = useDistributionElectricity(distributionElectricityId)

    if (status === 'pending') {
        return (
            <CircularProgress />
        )
    }

    if (error) {
        return (
            <Box>
                <ErrorOutlineRoundedIcon />
                <Box>{error.message}</Box>
            </Box>
        )
    }

    if (data) {
        return (
            <>
                <DistributionElectricityCard distributionElectricity={data} />
                <Box sx={{
                    color: 'primary.main',
                    fontWeight: 400,
                    marginTop: '1.5rem',
                    marginBottom: '0.5rem',
                    fontSize: '1.1rem',
                }}>
                    Rozvrh HDO
                </Box>
                <HdoList distributionElectricityId={data.id} />
            </>
        )
    } else {
        return (
            <ErrorOutlineRoundedIcon />
        )
    }
    
}

export default DistributionElectricityList
