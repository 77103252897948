import axios, { AxiosError } from 'axios'
import {
    useQuery,
    useMutation,
    useQueryClient,
} from '@tanstack/react-query'
import type { RenewableSource, RenewableSourceFormInput } from '../types'
import { useNavigate } from 'react-router'

export function useRenewableSources(estateId) {
    return useQuery<RenewableSource[], AxiosError>({
        queryKey: ['renewableSources-' + estateId],
        queryFn: async () => {
            const { data } : { data : Array<RenewableSource> } = await axios.get(
                '/api/renewable_source/list?estate_id=' + estateId,
            )
            return data? data.sort((a,b) => a.id - b.id): data // backend doesn't sort
        },
    })
}

export function useRenewableSource(renewableSourceId) {
    return useQuery({
        queryKey: ['renewableSource-' + renewableSourceId],
        queryFn: async () => {
            const { data } : { data : RenewableSource } = await axios.get(
                '/api/renewable_source/single?id=' + renewableSourceId,
            )
            return data
        },
    })
}

export const useCreateRenewableSource = (estateId, redirect = true, onSuccess?: (renewableSourceId: number) => void) => {
    const queryClient = useQueryClient()

    const navigate = useNavigate()

    return useMutation({
        mutationFn: (data: RenewableSourceFormInput) => {            
            return axios.get(`/api/renewable_source/create?estate_id=${estateId
                }&type=RenewableSource::Solar&name=${data.name
                }&power=${data.power
                }&declination=${data.declination
                }&azimuth=${data.azimuth}`
            )
        },
        onSuccess: response => {
            queryClient.invalidateQueries({ queryKey: ['renewableSources-' + estateId] })
            if (onSuccess) {
                onSuccess(response.data.id)
            }
            if (redirect && response.data.id) {
                navigate(`/estate/${estateId}/renewable-source/${response.data.id}`)
            }
        },
    })
}

export const useUpdateRenewableSource = (renewableSourceId, estateId, resetDrawer) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (data: RenewableSourceFormInput) => {
            return axios.get(`/api/renewable_source/update?id=${renewableSourceId
                }&estate_id=${estateId
                }&type=RenewableSource::Solar&name=${data.name
                }&power=${data.power
                }&declination=${data.declination
                }&azimuth=${data.azimuth}`
            )
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['renewableSources-' + estateId] })
            queryClient.invalidateQueries({ queryKey: ['renewableSource-' + renewableSourceId] })
            resetDrawer()
        },
    })
}

export const useDeleteRenewableSource = (renewableSourceId, estateId, redirect = true) => {
    const queryClient = useQueryClient()

    const navigate = useNavigate()

    return useMutation({
        mutationFn: () => {
            return axios.get(`/api/renewable_source/delete?id=${renewableSourceId}`)
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['renewableSources-' + estateId] })
            if(redirect) {
                navigate(`/estate/${estateId}`)
            }
        },
    })
}
