import React from 'react'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded'
import ButtonBase from '@mui/material/ButtonBase'
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded'

import { useApplianceControls } from '../hooks/use-appliance-control'

function PlugNotReadyCard({
    applianceId,
    toggleSettings,
}: {
    applianceId: string | number | undefined,
    toggleSettings: Function,
}) {

    const { status, data, error } = useApplianceControls(applianceId)

    if (status === 'pending') {
        return (
            <CircularProgress />
        )
    }

    if (error) {
        return (
            <Box>
                <ErrorOutlineRoundedIcon />
                <Box>{error.message}</Box>
            </Box>
        )
    }

    if (data.length > 0) {
        return null
    } else {
        return (
            <Box display="flex" flexDirection="column">
                <ButtonBase
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor: 'customBeige.main',
                        padding: 2,
                        borderRadius: 5,
                        textAlign: 'left',
                        "&.Mui-focusVisible": {
                            backgroundColor: 'customBeige.dark',
                        },
                    }}
                    onClick={() => toggleSettings()}
                >
                    <Box sx={{
                        paddingRight: 1,
                        fontSize: '1.8rem',
                        lineHeight: 0,
                    }}>
                        <ErrorOutlineRoundedIcon fontSize='inherit' color="warning"/>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                        gap: 1,
                        color: 'warning.main',
                    }}>
                        <Box sx={{ fontSize: '0.875rem' }}>Je potřeba doplnit chytrou zásuvku</Box>
                    </Box>
                    <Box sx={{
                        paddingLeft: 1,
                        fontSize: '1.5rem',
                        lineHeight: 0,
                        color: 'warning.main',
                    }}>
                        <ChevronRightRoundedIcon fontSize='inherit'/>
                    </Box>
                </ButtonBase>
            </Box>
        )
    }
}

export default PlugNotReadyCard
