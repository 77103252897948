import React from "react"
import dayjs from 'dayjs'
import 'dayjs/locale/cs'
import Box from "@mui/material/Box"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import { useForm, Controller, SubmitHandler } from "react-hook-form"
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import { PlanOverrideType, type PlanOverrideFormInput, PlanOverride } from "../../../types"
import CustomButton from "../../custom-button"
import { useUpdatePlanOverride } from "../../../hooks/use-plan-override"
import FormRow from "../helpers/form-row"

export default function UpdatePlanOverrideForm({
    appliancePlanId,
    planOverride,
    handleClose,
}: {
    appliancePlanId: number | undefined,
    planOverride: PlanOverride,
    handleClose: Function,
}) {
    const { control, handleSubmit } = useForm({
        defaultValues: {
            begins_at: dayjs(planOverride.begins_at),
            ends_at: dayjs(planOverride.ends_at),
            type: planOverride.type,
        },
    })

    const mutation = useUpdatePlanOverride(planOverride.id, appliancePlanId, handleClose)

    const onSubmit: SubmitHandler<PlanOverrideFormInput> = data => {
        mutation.mutate(data)
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1.5,
            }}>
                <FormControl variant="standard" sx={{ flexGrow: 1, marginBottom: 1 }}>
                    <InputLabel id="plan-override-type-label">Typ</InputLabel>
                    <Controller
                        name="type"
                        control={control}
                        render={({ field }) =>
                            <Select
                                labelId="plan-override-type-label"
                                id="plan-override-type"
                                {...field}
                            >
                                <MenuItem value={PlanOverrideType.party}>Párty</MenuItem>
                                <MenuItem value={PlanOverrideType.away}>Dovolená</MenuItem>
                            </Select>
                        }
                    />
                </FormControl>
                <FormRow>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="cs">
                        <Controller
                            name="begins_at"
                            control={control}
                            render={({ field }) => 
                                <DateTimePicker label="Začátek" ampm={false} {...field}/>
                            }
                        />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="cs">
                        <Controller
                            name="ends_at"
                            control={control}
                            render={({ field }) => 
                                <DateTimePicker label="Konec" ampm={false} {...field}/>
                            }
                        />
                    </LocalizationProvider>
                </FormRow>
                <Box display="flex" justifyContent="center" mt={2}>
                    <CustomButton
                        text="Uložit"
                        type="submit"
                        disabled={mutation.isPending}
                    />
                </Box>
                {mutation.isError ? (
                    <div>Došlo k chybě: {mutation.error.message}</div>
                ) : null}
            </Box>
        </form>
    )
}
