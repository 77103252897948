import { useAtom } from 'jotai/react'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'

import { simplifiedAppVersion, simplifiedAppVersionConfig } from '../state/application'
import { useAppliancePlan, useAppliancePlans } from './use-appliance-plan'
import { useDebug } from '../views/debug'
import { useEstate, useEstates } from './use-estate'
import { useAppliance, useAppliances } from './use-appliance'

export function useRedirectToSimpleHomepageIfEnabled() {
    const [simplifiedAppVersionValue, setSimplifiedAppVersion] = useAtom(simplifiedAppVersion)
    const navigate = useNavigate()
    if (simplifiedAppVersionValue) {
        navigate('/', {
            replace: true,
        })
    }
}

export const useAutoSelectFirstEstateFirstAppliancePlanAndAppliances = () => {
    const debug = useDebug({ name: 'simple homepage - auto select estate, appliance and appliance plan' })

    const [simplifiedConfig, setSimplifiedConfig] = useAtom(simplifiedAppVersionConfig)

    const estates = useEstates()
    const estate = useEstate(simplifiedConfig.selectedEstateId)
    const appliances = useAppliances(simplifiedConfig.selectedEstateId)
    const appliance = useAppliance(simplifiedConfig.selectedApplianceId)
    const appliancePlans = useAppliancePlans(simplifiedConfig.selectedApplianceId)
    const appliancePlan = useAppliancePlan(simplifiedConfig.selectedAppliancePlanId)

    function selectFirstEstateId() {
        if (estates.status === 'success' && estates.data.length) {
            debug.log(
                `estates changed data ... -> select first estates ${estates.data[0].id}`
            )

            setSimplifiedConfig({
                ...simplifiedConfig,
                selectedEstateId: estates.data[0].id, // this will trigger loading of appliance plans
                selectedApplianceId: 0,
                selectedAppliancePlanId: 0,
            })
        }
    }

    function selectFirstApplianceId() {
        if (appliances.status === 'success' && appliances.data.length) {
            debug.log(
                `appliances changed data ... -> select first appliance ${appliances.data[0].id}`
            )

            setSimplifiedConfig({
                ...simplifiedConfig,
                selectedApplianceId: appliances.data[0].id, // this will trigger loading of appliance plans
                selectedAppliancePlanId: 0,
            })
        }
    }

    function selectFirstAppliancePlanId() {
        // there is only one appliance plane so select it
        if (appliancePlans.status === 'success' && appliancePlans.data.length) {
            debug.log(
                `appliance plan changed data ... -> selected appliance plan ${appliancePlans.data[0].id}`
            )

            setSimplifiedConfig({
                ...simplifiedConfig,
                selectedAppliancePlanId: appliancePlans.data[0].id,
            })
        }
    }

    useEffect(() => {
        if (estate.status === 'error') {
            debug.log("selected estate doesn't exist -> reset selection")

            setSimplifiedConfig({
                ...simplifiedConfig,
                selectedEstateId: 0,
                selectedApplianceId: 0,
                selectedAppliancePlanId: 0
            })

            // estate id doesn't exist, but other estates could be loaded correctly
            selectFirstEstateId()
        }
    }, [estate.status])

    useEffect(() => {
        if (appliance.status === 'error') {
            debug.log("selected appliance doesn't exist -> reset selection")

            setSimplifiedConfig({
                ...simplifiedConfig,
                selectedApplianceId: 0,
                selectedAppliancePlanId: 0
            })

            selectFirstApplianceId()
        }
    }, [appliance.status])

    useEffect(() => {
        if (appliance.status === 'error') {
            debug.log("selected appliance plan doesn't exist -> reset selection")

            setSimplifiedConfig({
                ...simplifiedConfig,
                selectedAppliancePlanId: 0
            })

            selectFirstAppliancePlanId()
        }
    }, [appliancePlan.status])

    useEffect(() => {
        debug.log('estates status ...', estates.status)

        // do not override current selection
        if (simplifiedConfig.selectedEstateId) return

        selectFirstEstateId()
    }, [estates.status, estates.data])

    useEffect(() => {
        debug.log('appliances status ...', appliances.status)

        // do not override current selection
        if (simplifiedConfig.selectedApplianceId) return

        selectFirstApplianceId()
    }, [appliances.status, appliances.data])

    useEffect(() => {
        debug.log('appliance plans status ...', appliancePlans.status)

        // do not override current selection
        if (simplifiedConfig.selectedAppliancePlanId) return

        selectFirstAppliancePlanId()
    }, [appliancePlans.status, appliancePlans.data])
}
