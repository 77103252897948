import React, { useState } from 'react'
import Box from '@mui/material/Box'
import ButtonBase from '@mui/material/ButtonBase'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded'

import { SellElectricity, SellElectricityType } from '../types'
import UpdateSellElectricityForm from './forms/sell-electricity/update'
import { convertFromKiloWattHoursPrice } from '../helpers'

function SellElectricityCard({
    sellElectricity,
}: {
    sellElectricity: SellElectricity,
}) {

    const [edit, setEdit] = useState<boolean>(false)

    const TYPE_MAP = {
        'SellElectricity::Spot': 'Spotový',
        'SellElectricity::Flat': 'Fixní',
        'SellElectricity::Null': 'Nenastaven',
    }
    
    return (
        <Box display="flex" flexDirection="column">
            <ButtonBase
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: 'customGrey.main',
                    padding: 2,
                    borderRadius: 5,
                    textAlign: 'left',
                    boxShadow: '0 2px 6px rgba(0, 0, 0, .03)',
                    "&.Mui-focusVisible": {
                        backgroundColor: 'customGrey.dark',
                    },
                }}
                onClick={() => setEdit(!edit)}
            >
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    gap: 1,
                }}>
                    <Box mb={0.5} fontSize={'1rem'}>{TYPE_MAP[sellElectricity.type]}</Box>
                    <Box display="flex" flexDirection="column" gap={0.5}>
                        <Box sx={{ fontSize: '0.875rem', flexGrow: 1, }}>
                            Poplatky: {convertFromKiloWattHoursPrice(sellElectricity.sell_fees[sellElectricity.sell_fees.length -1]?.amount_cents)} Kč bez DPH/MWh
                        </Box>
                        {sellElectricity.type === SellElectricityType.flat &&
                            <Box sx={{ fontSize: '0.875rem', flexGrow: 1, }}>
                                Elektřina: {convertFromKiloWattHoursPrice(sellElectricity.sell_electricity[sellElectricity.sell_electricity.length -1]?.amount_cents)} Kč bez DPH/MWh
                            </Box>
                        }
                    </Box>
                </Box>
                <Box sx={{
                    paddingLeft: 1,
                    fontSize: '1.5rem',
                    lineHeight: 0,
                }}>
                    {edit ?
                        <ExpandLessRoundedIcon fontSize='inherit'/>
                    :
                        <ExpandMoreRoundedIcon fontSize='inherit'/>
                    }
                </Box>
                
            </ButtonBase>
            {edit &&
                <Box sx={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: 'customGrey.light',
                    padding: 2,
                    borderRadius: 5,
                    paddingTop: 8,
                    marginTop: -4,
                }}>
                    <UpdateSellElectricityForm
                        estateId={sellElectricity.estate_id}
                        sellElectricity={sellElectricity}
                        handleClose={() => setEdit(!edit)}
                    />
                </Box>
            }
        </Box>
    )
}

export default SellElectricityCard
