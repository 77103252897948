import React from 'react'
import Box from '@mui/material/Box'
import ButtonBase from '@mui/material/ButtonBase'
import BoltRoundedIcon from '@mui/icons-material/BoltRounded'
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded'
import { Link } from 'react-router-dom'
import { RenewableSource } from '../types'

function RenewableSourceCard({
    renewableSource,
}: {
    renewableSource: RenewableSource,
}) {

    return (
        <ButtonBase
            sx={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: 'customGreen.main',
                padding: 2,
                borderRadius: 5,
                "&.Mui-focusVisible": {
                    backgroundColor: 'customGreen.dark',
                },
            }} 
            component={Link}
            to={`renewable-source/${renewableSource.id}`}
        >
            <Box sx={{
                paddingRight: 1,
                fontSize: '1.8rem',
                lineHeight: 0,
            }}>
                <BoltRoundedIcon fontSize='inherit'/>
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                gap: 0.2,
            }}>
                <Box>{renewableSource.name}</Box>
                <Box sx={{ fontSize: '0.875rem' }}>Orientace: {renewableSource.azimuth+'°'} • Sklon: {renewableSource.declination+'°'} • Výkon: {renewableSource.power+' W'}</Box>
            </Box>
            <Box sx={{
                paddingLeft: 1,
                fontSize: '1.5rem',
                lineHeight: 0,
            }}>
                <ChevronRightRoundedIcon fontSize='inherit'/>
            </Box>
        </ButtonBase>
    )
}

export default RenewableSourceCard
