import { createTheme } from '@mui/material/styles'
import { csCZ as pickersCsCZ } from '@mui/x-date-pickers/locales'

declare module '@mui/material/styles' {
    interface Palette {
        customGrey: Palette['primary'];
        customGreen: Palette['primary'];
        customBeige: Palette['primary'];
        customBlue: Palette['primary'];
        customYellow: Palette['primary'];
    }
  
    interface PaletteOptions {
        customGrey?: PaletteOptions['primary'];
        customGreen?: PaletteOptions['primary'];
        customBeige?: PaletteOptions['primary'];
        customBlue?: PaletteOptions['primary'];
        customYellow?: PaletteOptions['primary'];
    }
}

const theme = createTheme({
    palette: {
        primary: {
            main: '#001F3F',
          },
        secondary: {
            main: '#ff9100',
        },
        // terciary: {
        //     main: '#ffbf29',
        // },
        customGrey: {
            main: '#F0F0F0',
            light: '#F5F5F5',
            dark: '#D0D0D0',
        },
        customGreen: {
            main: '#DFF2E1',
            light: '#F5FDF6',
            dark: '#7FC387',
        },
        customBeige: {
            main: '#FFECDB',
            light: '#FFF6EF',
            dark: '#F7D1B0',
        },
        customBlue: {
            main: '#E2F7FF',
            light: '#F2FBFF',
            dark: '#BBE0EF',
        },
        customYellow: {
            main: '#FFEC88',
            light: '#FFF7CC',
            dark: '#FFD700',
        }
    },
    components: {
        // Name of the component
        MuiButton: {
            styleOverrides: {
                // Name of the slot
                root: {
                    // Some CSS
                    textTransform: 'none',
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    flexGrow: 1,
                },
            },
        }
    },
}, pickersCsCZ)

export default theme
