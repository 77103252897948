import React from "react"
import TextField from "@mui/material/TextField"
import Box from "@mui/material/Box"
import InputAdornment from "@mui/material/InputAdornment"
// import Switch from "@mui/material/Switch"
// import FormControlLabel from "@mui/material/FormControlLabel"
import { useForm, Controller, SubmitHandler } from "react-hook-form"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import InputLabel from "@mui/material/InputLabel"
import { FormControl } from "@mui/material"

import {
    type DistributionElectricityFormInput,
    DistributionElectricity,
    DISTRIBUTION_FORM_SUPPLIERS,
    DISTRIBUTION_RATE_TABLE,
    supplierHasHDO,
    supplierHasEAN,
} from "../../../types"
import CustomButton from "../../custom-button"
import { useUpdateDistributionElectricity } from "../../../hooks/use-distribution-electricity"
import FormRow from "../helpers/form-row"
import { convertFromKiloWattHoursPrice } from "../../../helpers"

export default function UpdateDistributionElectricityForm({
    distributionElectricity,
    handleClose,
}: {
    distributionElectricity: DistributionElectricity,
    handleClose: () => void,
}) {
    const { control, watch, handleSubmit } = useForm<DistributionElectricityFormInput>({
        defaultValues: {
            supplier: distributionElectricity.supplier ?? '',
            distribution_rate: distributionElectricity.distribution_rate ?? '',
            // has_manual_hdo: distributionElectricity.has_manual_hdo ? true : false, // Is commented out in forms ...
            low_price_cents: distributionElectricity.low_price_cents ? convertFromKiloWattHoursPrice(distributionElectricity.low_price_cents) : '',
            high_price_cents: distributionElectricity.high_price_cents ? convertFromKiloWattHoursPrice(distributionElectricity.high_price_cents) : '',
            signal_code: distributionElectricity.signal_code ?? '',
            ean: distributionElectricity.ean ?? '',
        },
    })

    const mutation = useUpdateDistributionElectricity(distributionElectricity.id, handleClose)

    const onSubmit: SubmitHandler<DistributionElectricityFormInput> = data => {
        mutation.mutate(data)
    }

    const supplier: DistributionElectricityFormInput['supplier'] | undefined = watch('supplier')

    const distribution_rate = watch('distribution_rate')

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1.5,
            }}>
                <FormRow>
                    <Controller
                        name="supplier"
                        control={control}
                        render={({ field }) =>
                            <FormControl sx={{ flex: 1 }}>
                                <InputLabel id="distribution-electricity-supplier-label">Distributor</InputLabel>
                                <Select
                                    labelId="distribution-electricity-supplier-label"
                                    label="Distributor"
                                    variant="standard"
                                    id="distribution-electricity-supplier"
                                    {...field}
                                >
                                    {DISTRIBUTION_FORM_SUPPLIERS.map(({ name, id }) => (
                                        <MenuItem key={id} value={id}>{name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            // <TextField id="distribution-electricity-supplier" label="Distributor" variant="standard" {...field} />
                        }
                    />
                    <Controller
                        name="distribution_rate"
                        control={control}
                        render={({ field }) => (
                            <FormControl sx={{ flex: 1 }}>
                                <InputLabel id="distribution-electricity-distribution_rate">Distribuční sazba</InputLabel>
                                <Select
                                    labelId="distribution-electricity-distribution_rate"
                                    variant="standard"
                                    {...field}
                                >
                                    {DISTRIBUTION_RATE_TABLE.map(({ name, id }) => (
                                        <MenuItem key={id} value={id}>{name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            //  <TextField sx={{ flex: 1 }} id="distribution-electricity-distribution_rate" label="Distribuční sazba" variant="standard" {...field} />
                        )}
                    />
                </FormRow>
                {/* Temporarily hidden - sending always true */}
                {/* <Box mt={1}>s
                    <FormControlLabel control={
                        <Controller
                        name="has_manual_hdo"
                        control={control}
                        render={({ field }) =>
                            <Switch
                                onChange={(e) => field.onChange(e.target.checked)}
                                checked={field.value}
                            />
                        }
                    />
                    } label="Manuální HDO" />
                </Box> */}
                <Controller
                    name="low_price_cents"
                    control={control}
                    render={({ field }) =>
                        <TextField
                            id="distribution-electricity-low-price"
                            label="Cena nízkého tarifu"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">Kč bez DPH/MWh</InputAdornment>,
                            }}
                            variant="standard"
                            type="number"
                            disabled
                            aria-readonly
                            // required
                            {...field}
                        />}
                />
                <Controller
                    name="high_price_cents"
                    control={control}
                    render={({ field }) =>
                        <TextField
                            id="distribution-electricity-high-price"
                            label="Cena vysokého tarifu"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">Kč bez DPH/MWh</InputAdornment>,
                            }}
                            variant="standard"
                            type="number"
                            disabled
                            aria-readonly
                            // required
                            {...field}
                        />}
                />
                <Box sx={{ display: supplierHasHDO(supplier)? 'flex' : 'none' }}>
                    {
                        // These tarifs do not have HDO (https://chytrybojler.cz/tutorials/)
                        !(distribution_rate === 'D01d' || distribution_rate === 'D02d') &&
                        <Controller
                            name="signal_code"
                            control={control}
                            render={({ field }) => 
                                <TextField 
                                    id="distribution-electricity-signal-code" 
                                    label="Kód HDO" 
                                    variant="standard" 
                                    required={false} //supplierHasHDO(supplier)}
                                    {...field} 
                                />
                            }
                        />
                    }
                </Box>
                <Box sx={{ display: supplierHasEAN(supplier)? 'flex' : 'none' }}>
                    {
                        // These tarifs do not have HDO (https://chytrybojler.cz/tutorials/)
                        !(distribution_rate === 'D01d' || distribution_rate === 'D02d') &&
                        <Controller
                            name="ean"
                            control={control}
                            render={({ field }) => 
                                <TextField 
                                    id="distribution-electricity-ean" 
                                    label="EAN" 
                                    variant="standard" 
                                    required={false} // supplierHasEAN(supplier)}
                                    {...field} 
                                />
                            }
                        />
                    }
                </Box>
                <Box display="flex" justifyContent="center" mt={2} mb={2}>
                    <CustomButton
                        text="Uložit"
                        type="submit"
                        disabled={mutation.isPending}
                    />
                </Box>
                {mutation.isError ? (
                    <div>Došlo k chybě: {mutation.error.message}</div>
                ) : null}
            </Box>
        </form>
    )
}
