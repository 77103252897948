import React from "react"
import TextField from "@mui/material/TextField"
import Box from "@mui/material/Box"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import {
    Appliance,
    type ApplianceFormInput,
    BasicConsumption,
    BasicConsumptionInput,
    EnergyClass
} from "../../../types"
import { useUpdateAppliance } from "../../../hooks/use-appliance"
import CustomButton from "../../custom-button"
import FormRow from "../helpers/form-row"
import { ApplianceUsages } from "./components/appliance-usages"
import {
    useUpdateBasicConsumption
} from "../../../hooks/use-basic-consumption";

type FormInputs = ApplianceFormInput & Pick<BasicConsumptionInput, (
    'personsCount' |
    'forCleaning' |
    'forHandDishWashing' |
    'forOtherUsage' |
    'forShowering' |
    'forDishwasher' |
    'forHandWash'
)>

export default function UpdateApplianceForm({
    appliance,
    basicConsumption,
    resetDrawer,
}: {
    appliance: Appliance,
    basicConsumption: BasicConsumption,
    resetDrawer: Function,
}) {
    const { control, handleSubmit } = useForm<FormInputs>({
        defaultValues: {
            // name: '',
            energyCapacity: appliance.energy_capacity ?? '',
            inputPower: appliance.input_power ?? '',
            isDependentOnHdo: appliance.is_dependent_on_hdo ?? false,
            lossPower: appliance.loss_power ?? '',
            // blob
            dissipation: appliance.blob.dissipation ?? '',
            energyClass: appliance.blob.energetic_class ?? EnergyClass.C,
            hotWaterMaxTemperature: appliance.blob.hot_water_max_temperature ?? '',
            hotWaterMinTemperature: appliance.blob.hot_water_min_temperature ?? '',
            priceWeight: appliance.blob.price_weight ?? '',
            roomTemperature: appliance.blob.room_temperature ?? '',
            volume: appliance.blob.volume ?? '',
            // waterSourceTemperature: appliance.blob.water_source_temperature ?? '',   // value is set by backend (#158)
            temperatureWeight: appliance.blob.temperature_weight ?? '',
            personsCount: basicConsumption.persons_count ?? 2,
            forCleaning: basicConsumption.for_cleaning ?? false,
            forDishwasher: basicConsumption.for_dishwasher ?? false,
            forHandDishWashing: basicConsumption.for_hand_dish_washing ?? false,
            forHandWash: basicConsumption.for_hand_wash ?? false,
            forShowering: basicConsumption.for_showering ?? false,
            forOtherUsage: basicConsumption.for_other_usage ?? '',
        },
    })

    const mutationBasicConsumption = useUpdateBasicConsumption(appliance.id, basicConsumption.id)

    const mutation = useUpdateAppliance(appliance.id, appliance.estate_id, resetDrawer)

    const onSubmit: SubmitHandler<FormInputs> = data => {
        mutation.mutate(data)
        mutationBasicConsumption.mutate({
            personsCount: data.personsCount,
            forCleaning: data.forCleaning,
            forDishwasher: data.forDishwasher,
            forHandDishWashing: data.forHandDishWashing,
            forHandWash: data.forHandWash,
            forShowering: data.forShowering,
            forOtherUsage: data.forOtherUsage,
            applianceId: appliance.id,
        })
    }

    const ENERGY_CLASS_ARRAY = Object.keys(EnergyClass) as Array<keyof typeof EnergyClass>

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1.5,
            }}>
                <FormRow>
                    <Controller
                        name="volume"
                        control={control}
                        render={({ field }) => <TextField id="appliance-volume" label="Objem [l]" variant="standard" type="number" required {...field} />}
                    />
                </FormRow>
                {/* <FormRow>
                    <Controller
                        name="energyCapacity"
                        control={control}
                        render={({ field }) => <TextField id="appliance-energy-capacity" label="Kapacita energie" variant="standard" required {...field} />}
                    />
                    <Controller
                        name="lossPower"
                        control={control}
                        render={({ field }) => <TextField id="appliance-loss-power" label="Výkonová ztráta" variant="standard" type="number" required {...field} />}
                    />
                </FormRow> */}
                {/* Temporarily hidden - sending always true */}
                {/* <Box mt={1}>
                    <FormControlLabel control={
                        <Controller
                        name="isDependentOnHdo"
                        control={control}
                        render={({ field }) =>
                            <Switch
                                onChange={(e) => field.onChange(e.target.checked)}
                                checked={field.value}
                            />
                        }
                    />
                    } label="Závislý na HDO" />
                </Box> */}
                <FormRow>
                    {/* <Controller
                        name="dissipation"
                        control={control}
                        render={({ field }) => <TextField id="appliance-dissipation" label="Disipace" variant="standard" required {...field} />}
                    /> */}
                    <Controller
                        name="inputPower"
                        control={control}
                        render={({ field }) => <TextField id="appliance-input-power" label="Příkon [W]" variant="standard" type="number" required {...field} />}
                    />
                    <FormControl variant="standard" sx={{ minWidth: 130 }}>
                        <InputLabel id="appliance-energy-class-label">Energetická třída</InputLabel>
                        <Controller
                            name="energyClass"
                            control={control}
                            render={({ field }) =>
                                <Select
                                    labelId="appliance-energy-class-label"
                                    id="appliance-energy-class"
                                    {...field}
                                >
                                    {ENERGY_CLASS_ARRAY.map(energyClass => (
                                        <MenuItem key={energyClass} value={energyClass}>{energyClass}</MenuItem>
                                    ))}
                                </Select>
                            }
                        />
                    </FormControl>
                </FormRow>
                <FormRow>
                    {/* <Controller
                        name="hotWaterMinTemperature"
                        control={control}
                        render={({ field }) => <TextField id="appliance-hot-water-min-temperature" label="Min. teplota horké vody [°C]" variant="standard" type="number" required {...field} />}
                    /> */}
                    <Controller
                        name="hotWaterMaxTemperature"
                        control={control}
                        render={({ field }) => <TextField id="appliance-hot-water-max-temperature" label="Nastavená teplota na bojleru [°C]" variant="standard" type="number" required {...field} />}
                    />
                </FormRow>
                {/* 
                 
                 value is set by backend (#158)
                 
                <FormRow>
                    <Controller
                        name="waterSourceTemperature"
                        control={control}
                        render={({ field }) => <TextField id="appliance-water-source-temperature" label="Teplota zdroje vody [°C]" variant="standard" type="number" required {...field} />}
                    />
                    <Controller
                        name="roomTemperature"
                        control={control}
                        render={({ field }) => <TextField id="appliance-room-temperature" label="Teplota místnosti bojleru [°C]" variant="standard" type="number" required {...field} />}
                    />
                </FormRow> 
                */}
                {/* <FormRow>
                    <Controller
                        name="priceWeight"
                        control={control}
                        render={({ field }) => <TextField id="appliance-price-weight" label="Cenový koeficient" variant="standard" type="number" required {...field} />}
                    />
                    <Controller
                        name="temperatureWeight"
                        control={control}
                        render={({ field }) => <TextField id="appliance-temperature-weight" label="Koeficient teploty" variant="standard" type="number" required {...field} />}
                    />
                </FormRow> */}


                <FormRow>
                    <Controller
                        name="personsCount"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                label="Počet osob co používá tento bojler"
                                variant="standard"
                                type="number"
                                // id="persons-count"
                                required
                                {...field}
                            />
                        )}
                    />
                </FormRow>

                <ApplianceUsages control={control} />

                <Box display="flex" justifyContent="center" mt={2} mb={2}>
                    <CustomButton
                        text="Uložit"
                        type="submit"
                        disabled={mutation.isPending}
                    />
                </Box>
                {mutation.isError ? (
                    <div>Došlo k chybě: {mutation.error.message}</div>
                ) : null}
            </Box>
        </form>
    )
}
