import React from 'react'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded'

import { useSellElectricities } from '../../hooks/use-sell-electricity'
import CreateSellElectricityForm from '../forms/sell-electricity/create'
import SellElectricityCard from '../sell-electricity-card'

function SellElectricityList({
    estateId,
} : {
    estateId: string | number | undefined,
}) {

    const { status, data, error } = useSellElectricities(estateId)

    if (status === 'pending') {
        return (
            <CircularProgress />
        )
    }

    if (error) {
        return (
            <Box>
                <ErrorOutlineRoundedIcon />
                <Box>{error.message}</Box>
            </Box>
        )
    }

    if (data.length > 0) {
        return (
            <SellElectricityCard sellElectricity={data[0]}/>
        )
    } else {
        return (
            <Box sx={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: 'customGrey.light',
                padding: 2,
                borderRadius: 5,
            }}>
                <CreateSellElectricityForm estateId={estateId} />
            </Box>
        )
    }
    
}

export default SellElectricityList
