import React from "react"
import TextField from "@mui/material/TextField"
import Box from "@mui/material/Box"
import Switch from "@mui/material/Switch"
import FormControlLabel from "@mui/material/FormControlLabel"
import { useForm, Controller, SubmitHandler } from "react-hook-form"
import type { AppliancePlan, AppliancePlanFormInput } from "../../../types"
import CustomButton from "../../custom-button"
import { useUpdateAppliancePlan } from "../../../hooks/use-appliance-plan"

export default function UpdateAppliancePlanForm({
    appliancePlan,
    applianceId,
    resetDrawer,
}: {
    appliancePlan: AppliancePlan,
    applianceId: string | undefined,
    resetDrawer: Function,
}) {
    const { control, handleSubmit } = useForm({
        defaultValues: {
            name: appliancePlan.name,
            active: appliancePlan.active,
        },
    })

    const mutation = useUpdateAppliancePlan(appliancePlan.id, applianceId, resetDrawer)

    const onSubmit: SubmitHandler<AppliancePlanFormInput> = data => {
        mutation.mutate(data)
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1.5,
            }}>
                <Controller
                    name="name"
                    control={control}
                    render={({ field }) => <TextField id="estate-name" label="Název plánu" variant="standard" required {...field} />}
                />
                <Box mt={1}>
                    <FormControlLabel control={
                        <Controller
                        name="active"
                        control={control}
                        render={({ field }) =>
                            <Switch
                                onChange={(e) => field.onChange(e.target.checked)}
                                checked={field.value}
                            />
                        }
                    />
                    } label="Aktivovat plán" />
                </Box>
                <Box display="flex" justifyContent="center" mt={2}>
                    <CustomButton
                        text="Uložit"
                        type="submit"
                        disabled={mutation.isPending}
                    />
                </Box>
                {mutation.isError ? (
                    <div>Došlo k chybě: {mutation.error.message}</div>
                ) : null}
            </Box>
        </form>
    )
}
