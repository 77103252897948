import React from "react"
import TextField from "@mui/material/TextField"
import Box from "@mui/material/Box"
import { useForm, Controller, SubmitHandler } from "react-hook-form"

import type { RenewableSourceFormInput } from "../../../types"
import { useCreateRenewableSource } from "../../../hooks/use-renewable-source"
import CustomButton from "../../custom-button"
import { FormControlWithLabelWrapper } from "../../../views/wizard/steps/wizard-common"
import { DeclinationInput } from "./components/declination-input"
import { AzimuthInput } from "./components/azimuth-input"
import { numberOrUndefinedFromString } from "./_utils"

export default function CreateRenewableSourceForm({
    estateId,
}: {
    estateId: string | undefined,
}) {
    const { control, watch, setValue, handleSubmit } = useForm({
        defaultValues: {
            name: '',
            power: '',
            declination: '',
            azimuth: '',
        },
    })

    const mutation = useCreateRenewableSource(estateId)

    const onSubmit: SubmitHandler<RenewableSourceFormInput> = data => {
        mutation.mutate(data)
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '3rem',
            }}>
                <FormControlWithLabelWrapper
                    label={'Název'}
                    labelId="renewable-source-name"
                >
                    <Controller
                        name="name"
                        control={control}
                        render={({ field }) => <TextField id="renewable-source-name" variant="standard" type="text" required {...field} />}
                    />
                </FormControlWithLabelWrapper>

                <FormControlWithLabelWrapper
                    label={'Výkon (W)'}
                    labelId="renewable-source-power"
                >
                    <Controller
                        name="power"
                        control={control}
                        render={({ field }) => <TextField id="renewable-source-power" variant="standard" type="number" required {...field} />}
                    />
                </FormControlWithLabelWrapper>

                <DeclinationInput
                    control={control}
                    watch={watch}
                    setValue={setValue}
                />

                <AzimuthInput
                    control={control}
                    watch={watch}
                    setValue={setValue}
                    estateId={numberOrUndefinedFromString(estateId)}
                />

                {/* <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-around',
                    }}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}>
                        <img
                            src="/panel_declination.svg"
                            loading="lazy"
                            alt="Panel declination hint"
                            style={{
                                maxWidth: '100%',
                                width: '200px',
                                
                            }}
                        />
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}>
                        <img
                            src="/panel_orientation.svg"
                            loading="lazy"
                            alt="Panel orientation hint"
                            style={{
                                maxWidth: '100%',
                                width: '200px',
                            }}
                        />
                    </Box>
                </Box> */}
                <Box display="flex" justifyContent="center" mt={2} mb={2}>
                    <CustomButton
                        text="Vytvořit"
                        type="submit"
                        disabled={mutation.isPending}
                    />
                </Box>
                {mutation.isError ? (
                    <div>Došlo k chybě: {mutation.error.message}</div>
                ) : null}
            </Box>
        </form>
    )
}
