import React from 'react'
import Box from '@mui/material/Box'
import ButtonBase from '@mui/material/ButtonBase'
import Typography from '@mui/material/Typography'
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded'

function PlanSelectButton({
    onClick,
}: {
    onClick: Function,
}) {

    return (
        <ButtonBase sx={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: 'customGrey.main',
            padding: 2,
            borderRadius: 5,
            flexBasis: '50%',
            "&.Mui-focusVisible": {
                backgroundColor: 'customGrey.dark',
            },
         }} component="div" onClick={() => onClick()}>
            <Box sx={{
                paddingRight: 1.5,
                fontSize: '1.5rem',
                lineHeight: 0,
            }}>
                <CalendarMonthRoundedIcon fontSize='inherit'/>
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                gap: 0.2,
            }}>
                <Typography component="div" sx={{ fontSize: '1rem' }}>Vyberte plán</Typography>
                <Typography component="div" sx={{ fontSize: '0.875rem' }}>Neaktivní</Typography>
            </Box>
        </ButtonBase>
    )
}

export default PlanSelectButton
