import React, { useState } from "react"
import Box from "@mui/material/Box"
import CustomButton from "../../custom-button"
import { useDeleteRenewableSource } from "../../../hooks/use-renewable-source"


export default function DeleteRenewableSourceForm({
    renewableSourceId,
    estateId,
}: {
    renewableSourceId: number,
    estateId: number,
}) {

    const [confirmation, setConfirmation] = useState<boolean>(false)

    const mutation = useDeleteRenewableSource(renewableSourceId, estateId)

    return (
        <Box>
            <Box display="flex" justifyContent="center" gap={1} mt={2}>
                {!confirmation &&
                    <CustomButton
                        variant="outlined"
                        color="error"    
                        text="Odstranit"
                        onClick={() => setConfirmation(true)}
                    />
                }
                {confirmation &&
                    <>
                        <CustomButton
                            color="error" 
                            text="Potvrdit"
                            disabled={mutation.isPending}
                            onClick={() => mutation.mutate()}
                        />
                        <CustomButton
                            variant="outlined"
                            text="Zrušit"
                            onClick={() => setConfirmation(false)}
                        />
                    </>
                }
            </Box>
            <Box>
                {mutation.isError ? (
                    <div>Došlo k chybě: {mutation.error.message}</div>
                ) : null}
            </Box>
        </Box>
    )
}
