import React, { ReactNode } from 'react'
import Button from '@mui/material/Button'
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded'

export function PreviousStepButton(props: { onClick: () => void; children: ReactNode }) {
    return (
        <Button
            onClick={props.onClick}
            sx={{
                color: '#071F3F',
                ontSize: '18px',
            }}
            startIcon={<ChevronLeftRoundedIcon />}
        >
            {props.children}
        </Button>
    )
}

export const FONT_WEIGHT = {
    medium: 500,
}
