import React from "react"
import TextField from "@mui/material/TextField"
import Box from "@mui/material/Box"
import { useForm, Controller, SubmitHandler } from "react-hook-form"
import type {
    EstateFormInput,
    Estate,
} from "../../../types"
import { useUpdateEstate } from "../../../hooks/use-estate"
import CustomButton from "../../custom-button"
import InputLabel from "@mui/material/InputLabel";

function ReadOnlyTextInput({label,id,value}) {

    // <Controller
    //     name={label}
    //     control={control}
    //     render={({ field }) => (

    return (
        <Box sx={{width:'100%', display:'flex', flexDirection:'column'}}>
            <InputLabel
                id={id}
                disabled
                sx={{
                    transform: 'scale(0.75)'
                }}
            >
                {label}
            </InputLabel>
            <TextField
                aria-labelledby={id}
                variant="standard"
                type="number"
                aria-readonly
                disabled
                value={value}
            />
        </Box>
    )
}

export default function UpdateEstateForm({
    estate,
}: {
    estate: Estate,
}) {
    const { control, handleSubmit } = useForm<EstateFormInput>({
        defaultValues: {
            name: estate.name ?? '',
            // latitude: estate.latitude ?? '',
            // longitude: estate.longitude ?? '',
            adress: estate.adress ?? '',
        },
    })

    const mutation = useUpdateEstate(estate.id, () => {})

    const onSubmit: SubmitHandler<EstateFormInput> = data => {
        mutation.mutate(data)
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1.5,
            }}>
                <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                        <TextField 
                            id="estate-name" 
                            label="Název nemovitosti" 
                            variant="standard" 
                            required 
                            {...field} 
                        />
                    )}
                />
                <Controller
                    name="adress"
                    control={control}
                    render={({ field }) => (
                        <TextField 
                            id="estate-adress" 
                            label="Adresa nemovitosti" 
                            variant="standard" 
                            required 
                            {...field} 
                        />
                    )}
                />
                <ReadOnlyTextInput
                    id={'estate-latitude'}
                    label={'Zeměpisná šířka'}
                    value={estate.latitude}
                />
                <ReadOnlyTextInput
                    id={'estate-longitude'}
                    label={'Zeměpisná délka'}
                    value={estate.longitude}
                />
                <Box display="flex" justifyContent="center" mt={2}>
                    <CustomButton
                        text="Uložit"
                        type="submit"
                        disabled={mutation.isPending}
                    />
                </Box>
                {mutation.isError ? (
                    <div>Došlo k chybě: {mutation.error.message}</div>
                ) : null}
            </Box>
        </form>
    )
}
