import React, { useEffect } from 'react'
import { Controller, useForm, type SubmitHandler } from 'react-hook-form'
import DeviceThermostatRoundedIcon from '@mui/icons-material/DeviceThermostatRounded'
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import BoltRoundedIcon from '@mui/icons-material/BoltRounded'
import PlaceIcon from '@mui/icons-material/Place';

import CustomButton from '../../../components/custom-button'
import Map from '../../../components/forms/renewable-source/components/map'
import { AzimuthInput } from '../../../components/forms/renewable-source/components/azimuth-input'
import { DeclinationInput } from '../../../components/forms/renewable-source/components/declination-input'
import { useCreateRenewableSource, useRenewableSource, useRenewableSources, useUpdateRenewableSource } from '../../../hooks/use-renewable-source'
import type { Estate, EstateFormInput, RenewableSource, RenewableSourceFormInput } from '../../../types'
import { useDebug } from '../../debug'
import { PreviousStepButton } from '../components'
import { BottomButtons, FormControlWithLabelWrapper, StepIcon } from './wizard-common'
import { useEstate, useUpdateEstate } from '../../../hooks/use-estate'
import InputLabel from '@mui/material/InputLabel'

export function WizardStep4({
    estateId,
    nextStep,
    goBack,
}: {
    estateId: number
    nextStep: () => void
    goBack: () => void
}) {
    const debug = useDebug({ name: 'WizardStep4' })

    const { status, data, error } = useEstate(estateId)

    if (status === 'pending') {
        return <CircularProgress />
    }

    if (error || data === undefined) {
        return (
            <Box>
                <ErrorOutlineRoundedIcon />
                <Box>{error.message}</Box>
            </Box>
        )
    }

    return (
        <Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4rem' }}>

                <StepIcon icon={<PlaceIcon />} color={'#DFF2E1'} />

                {/* {data.length == 0 &&
                    <FormControlWithLabelWrapper
                        label={'Máte fotovoltaické panely?'}
                        id='WizardStep4-supplier'
                    >
                        <Select
                            aria-labelledby="WizardStep4-supplier"
                            value={enableEditation ? '1' : '0'}
                            onChange={(event) => {
                                const bool: boolean = event.target.value === '1' ? true : false
                                setEnableEditation(bool)
                            }}
                        >
                            {[false, true].map((bool, index) => (
                                <MenuItem
                                    value={bool ? '1' : '0'}
                                    key={index}
                                >
                                    {bool ? 'Ano' : 'Ne'}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControlWithLabelWrapper>
                } */}

                <Box>
                    <Form estate={data} />
                </Box>

                <BottomButtons>
                    <PreviousStepButton
                        onClick={() => {
                            goBack()
                        }}
                    >
                        Zpět
                    </PreviousStepButton>

                    <CustomButton
                        text="Pokračovat"
                        onClick={() => {
                            nextStep()
                        }}
                    />
                </BottomButtons>

            </Box>
        </Box>
    )
}

function ReadOnlyTextInput({ label, id, value }) {
    return (
        <FormControlWithLabelWrapper
            id={id}
            label={label}
        >
            <TextField
                aria-labelledby={id}
                variant="standard"
                type="number"
                aria-readonly
                disabled
                value={value}
            />
        </FormControlWithLabelWrapper>
    )
}

function Form({
    estate,
    // goBack,
}: {
    estate: Estate,
    // goBack?: () => void
}) {
    const debug = useDebug({ name: 'Form' })


    debug.logObject('estate.adress', estate.adress)

    const { control, handleSubmit } = useForm<EstateFormInput>({
        defaultValues: {
            adress: estate.adress ?? '',
        },
    })

    const mutation = useUpdateEstate(estate.id, () => { })

    const onSubmit: SubmitHandler<EstateFormInput> = data => {
        mutation.mutate(data)
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '3rem',
                }}
            >
                <FormControlWithLabelWrapper
                    label='Adresa nemovitosti'
                    hint="Přesná adresa je důležitá pro předpověď počasí a optimalizaci."
                    id="estate-adress"
                >
                    <Controller
                        name="adress"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                aria-labelledby="estate-adress"
                                variant="standard"
                                required
                                {...field}
                            />
                        )}
                    />
                </FormControlWithLabelWrapper>

                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '2rem' }}>
                        <TextField
                            variant="standard"
                            type="number"
                            aria-readonly
                            disabled
                            label={'Zeměpisná šířka'}
                            value={estate.latitude}
                        />
                        <TextField
                            variant="standard"
                            type="number"
                            aria-readonly
                            disabled
                            label={'Zeměpisná délka'}
                            value={estate.longitude}
                        />
                    </Box>
                    {estate.latitude && estate.longitude &&
                        <Map
                            startingLatitude={parseFloat(estate.latitude)}
                            startingLongitude={parseFloat(estate.longitude)}
                        />
                    }
                </Box>

                {/* <ReadOnlyTextInput
                    id={'estate-latitude'}
                    label={'Zeměpisná šířka'}
                    value={estate.latitude}
                />
                <ReadOnlyTextInput
                    id={'estate-longitude'}
                    label={'Zeměpisná délka'}
                    value={estate.longitude}
                /> */}

                <BottomButtons bottomPadding={false}>

                    <Box />
                    {/* {goBack ? (
                        <PreviousStepButton
                            onClick={() => {
                                goBack()
                            }}
                        >
                            Zpět
                        </PreviousStepButton>
                    ) : (
                        <Box />
                    )} */}

                    <CustomButton
                        text="Aktualizovat"
                        type="submit"
                        disabled={mutation.isPending}
                    />
                </BottomButtons>
            </Box>
        </form>
    )
}
