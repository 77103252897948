import React from "react"
import Box from "@mui/material/Box"
import FormControlLabel from "@mui/material/FormControlLabel"
import { Controller } from "react-hook-form"
import Checkbox from "@mui/material/Checkbox"

import { CONSUMPTION_TEXTS } from "../../../../types"
import { FormControlWithLabelWrapper, Hint, Textarea } from "../../../../views/wizard/steps/wizard-common"

function AlignToTextOfCheckbox({ children }) {
    return <Box sx={{
        margin: '0 0 0 2rem'
    }}>
        {children}
    </Box>
}

export function ApplianceUsages({control}) {
    return (
        <>
            <Box sx={{ marginTop: '1.5rem' }}>
                <FormControlWithLabelWrapper
                    label="K čemu používáte tento bojler?"
                    id='applianceUsages'
                    component="h2"
                    // hint={'Teplota musí být minimálně 60°C kvůli legionelle.'}
                />
            </Box>

            <Box
                sx={{display: 'flex', flexDirection: 'column', gap: '0'}}
                role='group'
                aria-labelledby="applianceUsages"
            >
                <Controller
                    name="forShowering"
                    control={control}
                    render={({ field }) =>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={field.value}
                                    {...field}
                                    onChange={(e) => field.onChange(e.target.checked)}
                                />
                            }
                            label={CONSUMPTION_TEXTS.forShowering.label}
                        />
                    }
                />

                <Controller
                    name="forHandWash"
                    control={control}
                    render={({ field }) =>
                        <Box sx={{display: 'flex', flexDirection: 'column'}}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        // onChange={(e) => field.onChange(e.target.checked)}
                                        checked={field.value}
                                        {...field}
                                    />
                                }
                                label={CONSUMPTION_TEXTS.forHandWash.label}
                            />
                            <AlignToTextOfCheckbox>
                                <Hint>
                                    {CONSUMPTION_TEXTS.forHandWash.hint}
                                </Hint>
                            </AlignToTextOfCheckbox>
                        </Box>
                    }
                />

                <Box>
                    <Controller
                        name="forHandDishWashing"
                        control={control}
                        render={({ field }) =>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        // onChange={(e) => field.onChange(e.target.checked)}
                                        checked={field.value}
                                        {...field}
                                    />
                                }
                                label={CONSUMPTION_TEXTS.forHandDishWashing.label}
                            />
                        }
                    />

                    <AlignToTextOfCheckbox>
                        <Hint>
                            {CONSUMPTION_TEXTS.forHandDishWashing.hint}
                        </Hint>
                    </AlignToTextOfCheckbox>
                </Box>


                <Box>
                    <Controller
                        name="forDishwasher"
                        control={control}
                        render={({ field }) =>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        // onChange={(e) => field.onChange(e.target.checked)}
                                        checked={field.value}
                                        {...field}
                                    />
                                }
                                label={CONSUMPTION_TEXTS.forDishwasher.label}
                            />
                        }
                    />
                    <AlignToTextOfCheckbox>
                        <Hint>{CONSUMPTION_TEXTS.forDishwasher.hint}</Hint>
                    </AlignToTextOfCheckbox>
                </Box>

                <Box>
                    <Box sx={{
                        color: '#071F3F',
                        fontWeight: '400',
                        fontSize: '1rem',
                        lineHeight: '1.5',
                        margin: '1rem 0 .5rem 0',
                    }}>
                        {CONSUMPTION_TEXTS.forOtherUsage.label}
                    </Box>
                    <Box sx={{
                        margin: '0 0 .5rem 0'
                    }}>
                        <Hint>
                            {CONSUMPTION_TEXTS.forOtherUsage.hint}
                        </Hint>
                    </Box>
                    <Controller
                        name="forOtherUsage"
                        control={control}
                        render={({ field }) =>
                            <Textarea
                                {...field}
                                sx={{
                                    resize: 'vertical',

                                }}
                            />
                        }
                    />
                </Box>
            </Box>        
        </>
    )
}
