import React from "react"
import Box from "@mui/material/Box"
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';

export function Azimuth({
    children,
    azimuthAngle
}: {
    children: React.ReactNode,
    azimuthAngle: number
}) {
    // Azimuth = CSS angle
    //    -180 = 0 or 360
    //       0 = 180
    //    - 90 = 90
    const cssAngle = -azimuthAngle + 180 // flip direction

    const arrowSizePX = 120

    return (
        <Box sx={{ position: 'relative' }}>
            <TrendingFlatIcon
                sx={{
                    color: '#001dff',
                    position: 'absolute',
                    // width: '20px',
                    // height: '80px',
                    fontSize: `${arrowSizePX}px`,
                    zIndex: 10000,
                    transform: `rotate(${cssAngle - 90}deg)`,
                    //transform: rotate(calc(223deg - 90deg)),
                    top: `calc(50% - ${arrowSizePX / 2}px)`,
                    left: `calc(50% - ${arrowSizePX / 2}px)`,
                    pointerEvents: 'none',
                    opacity: '.5',
                }}
            />
            {/*
                <Box sx={{
                    position: 'absolute',
                    width: '20px',
                    height: '80px',
                    zIndex: '10000',
                    background: '#001dff',
                    top: 'calc( 50% - 40px )',
                    left: 'calc( 50% - 10px )',
                    pointerEvents: 'none',
                    opacity: '.5',
                    transform: `rotate(${cssAngle}deg)`,
                }}>
                    //right arrow
                    <Box sx={{
                        position: 'absolute',
                        top: '-11px',
                        left: '10px',
                        width: '40px',
                        height: '20px',
                        background: '#001dff',
                        transform: 'rotate(30deg)',
                        transformOrigin: 'top left',
                    }}></Box>
                    // left arrow 
                    <Box sx={{
                        position: 'absolute',
                        top: '-11px',
                        left: 'calc( 10px - 40px)',
                        width: '40px',
                        height: '20px',
                        background: '#001dff',
                        transform: 'rotate(330deg)',
                        transformOrigin: 'top right',
                    }}></Box>
                </Box>
            */}
            {children}
        </Box>
    )
}
