import React from 'react'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded'

import { usePowerElectricities } from '../../hooks/use-power-electricity'
import CreatePowerElectricityForm from '../forms/power-electricity/create'
import PowerElectricityCard from '../power-electricity-card'

function PowerElectricityList({
    estateId,
} : {
    estateId: string | number | undefined,
}) {

    const { status, data, error } = usePowerElectricities(estateId)

    if (status === 'pending') {
        return (
            <CircularProgress />
        )
    }

    if (error) {
        return (
            <Box>
                <ErrorOutlineRoundedIcon />
                <Box>{error.message}</Box>
            </Box>
        )
    }

    if (data.length > 0) {
        return (
            <PowerElectricityCard powerElectricity={data[0]} />
        )
    } else {
        return (
            <Box sx={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: 'customGrey.light',
                padding: 2,
                borderRadius: 5,
            }}>
                <CreatePowerElectricityForm estateId={estateId} />
            </Box>
        )
    }
    
}

export default PowerElectricityList
