import React, { useState } from "react"
import TextField from "@mui/material/TextField"
import Box from "@mui/material/Box"
import { useForm, Controller, SubmitHandler } from "react-hook-form"
import { useNavigate } from "react-router-dom"

import type {
    Estate,
    EstateFormInput
} from "../../../types"
import {
    useCreateEstate,
} from "../../../hooks/use-estate"
import CustomButton from "../../custom-button"

export default function CreateEstateForm() {
    const { control, handleSubmit } = useForm<EstateFormInput>({
        defaultValues: {
            name: '',
            // latitude: '',
            // longitude: '',
            adress: '',
        },
    })

    const mutation = useCreateEstate()

    const onSubmit: SubmitHandler<EstateFormInput> = data => {
        mutation.mutate(data)
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1.5,
            }}>
                <Controller
                    name="name"
                    control={control}
                    render={({ field }) => <TextField id="estate-name" label="Název nemovitosti" variant="standard" required {...field} />}
                />
                <Controller
                    name="adress"
                    control={control}
                    render={({ field }) => (
                        <TextField 
                            id="estate-adress" 
                            label="Adresa nemovitosti" 
                            variant="standard" 
                            required 
                            {...field} 
                        />
                    )}
                />                
                {/* <Controller
                    name="latitude"
                    control={control}
                    render={({ field }) => (
                        <TextField 
                            id="estate-latitude"
                            label="Zeměpisná šířka" 
                            variant="standard" 
                            type="number" 
                            aria-readonly
                            disabled
                            {...field} 
                        />
                    )}
                />
                <Controller
                    name="longitude"
                    control={control}

                    render={({ field }) => (
                        <TextField 
                            id="estate-longitude" 
                            label="Zeměpisná délka" 
                            variant="standard"
                            type="number" 
                            aria-readonly
                            disabled
                            {...field} 
                        />
                    )}
                /> */}
                <Box display="flex" justifyContent="center" mt={2}>
                    <CustomButton
                        text="Vytvořit"
                        type="submit"
                        disabled={mutation.isPending}
                    />
                </Box>
                {mutation.isError ? (
                    <div>Došlo k chybě: {mutation.error.message}</div>
                ) : null}
            </Box>
        </form>
    )
}
