import React from 'react'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded'

import { useDoses } from '../hooks/use-dose'
import { DAYS_OF_WEEK } from '../constants'
import { getHours, getLengthInHours } from '../helpers'

function SelectedDoseOverview({
    appliancePlanId,
}: {
    appliancePlanId: string | number | undefined,
}) {
    
    const { status, data, error } = useDoses(appliancePlanId)

    if (status === 'pending') {
        return (
            <CircularProgress />
        )
    }

    if (error) {
        return (
            <Box>
                <ErrorOutlineRoundedIcon />
                <Box>{error.message}</Box>
            </Box>
        )
    }

    // TODO: Connect actual dose to width and left

    return (
        <Box display="flex" flexDirection="column" gap={2} pt={2}>
            <Box display="flex">
                <Box flexBasis={30}></Box>
                <Box flexGrow={1} display="flex" justifyContent="space-between">
                    <Box fontSize={14}>00:00</Box>
                    <Box fontSize={14}>12:00</Box>
                    <Box fontSize={14}>24:00</Box>
                </Box>
            </Box>
            {DAYS_OF_WEEK.map(day => {
                const filteredDoses = data.filter(dose => dose.day_of_week == day.id)
                return (
                    <Box display="flex" key={day.id}>
                        <Box flexBasis={30} color="primary.main">{day.name}</Box>
                        <Box sx={{
                            flexGrow: 1,
                            backgroundColor: 'customGreen.main',
                            borderRadius: 10,
                            position: 'relative',
                            overflow: 'hidden',
                            minHeight: 24,
                        }}>
                            {filteredDoses.map(dose => {
                                const startHour = getHours(dose.begins_at)
                                const lengthHours = getLengthInHours(dose.begins_at, dose.ends_at)

                                return(
                                    <Box key={dose.id} sx={{
                                        position: 'absolute',
                                        left: (startHour/0.24)+'%',
                                        width: (lengthHours/0.24)+'%',
                                        height: '100%',
                                        backgroundColor: 'customBlue.dark',
                                    }}>
                                    </Box>
                                )
                            })}
                        </Box>
                    </Box>
                )
            })}
        </Box>
    )
}

export default SelectedDoseOverview
