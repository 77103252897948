import React from 'react'
import Box from '@mui/material/Box'
import ButtonBase from '@mui/material/ButtonBase'
import Typography from '@mui/material/Typography'
import { useAtom } from 'jotai'
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded'
import { AppliancePlan } from '../types'
import { selectedAppliancePlansAtom } from '../state/application'

function AppliancePlanButton({
    applianceId,
    appliancePlan,
    resetDrawer,
}: {
    applianceId: string | undefined,
    appliancePlan: AppliancePlan,
    resetDrawer: Function,
}) {

    const [selectedAppliancePlans, setSelectedAppliancePlans] = useAtom(selectedAppliancePlansAtom)

    const onClick = (appliancePlanId: number) => {
        setSelectedAppliancePlans({
            ...selectedAppliancePlans,
            ['applianceId-'+applianceId]: appliancePlanId,
        })
        resetDrawer()
    }

    return (
        <ButtonBase
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                backgroundColor: appliancePlan.active ? 'customGreen.main' : 'customGrey.main',
                paddingX: 2,
                paddingY: 2.5,
                borderRadius: 5,
                flexGrow: 1,
                "&.Mui-focusVisible": {
                    backgroundColor: appliancePlan.active ? 'customGreen.dark' : 'customGrey.dark',
                },
            }}
            onClick={() => onClick(appliancePlan.id)}
        >
            <Box sx={{
                paddingRight: 1.5,
                fontSize: '1.5rem',
                lineHeight: 0,
            }}>
                <CalendarMonthRoundedIcon fontSize='inherit'/>
            </Box>
            <Typography component="div" sx={{ fontSize: '1.25rem' }}>{appliancePlan.name}</Typography>
        </ButtonBase>
    )
}

export default AppliancePlanButton
