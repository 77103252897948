import axios from 'axios'
import {
    useQuery,
    useMutation,
    useQueryClient,
} from '@tanstack/react-query'
import type { PlanOverride, PlanOverrideFormInput } from '../types'

export function useAppliancePlanOverrides(appliancePlanId) {
    return useQuery({
        queryKey: ['appliancePlanOverrides-' + appliancePlanId],
        queryFn: async () => {
            const { data } : { data : Array<PlanOverride> } = await axios.get(
                '/api/appliance_plan_override/list?appliance_plan_id=' + appliancePlanId,
            )
            return data
        },
    })
}

export function useAppliancePlanOverride(appliancePlanOverrideId) {
    return useQuery({
        queryKey: ['appliancePlanOverride-' + appliancePlanOverrideId],
        queryFn: async () => {
            const { data } : { data : PlanOverride } = await axios.get(
                '/api/appliance_plan_override/single?id=' + appliancePlanOverrideId,
            )
            return data
        },
    })
}

export const useCreatePlanOverride = (appliancePlanId, returnToList) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (data: PlanOverrideFormInput) => {            
            const beginsAt = data.begins_at.toISOString()
            const endsAt = data.ends_at.toISOString()
            return axios.get(`/api/appliance_plan_override/create?appliance_plan_id=${appliancePlanId
            }&begins_at=${beginsAt
            }&ends_at=${endsAt
            }&type=${data.type}`
            )
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['appliancePlanOverrides-' + appliancePlanId] })
            returnToList()
        },
    })
}

export const useUpdatePlanOverride = (appliancePlanOverrideId, appliancePlanId, handleClose) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (data: PlanOverrideFormInput) => {
            const beginsAt = data.begins_at.toISOString()
            const endsAt = data.ends_at.toISOString()
            return axios.get(`/api/appliance_plan_override/update?id=${appliancePlanOverrideId
            }&appliance_plan_id=${appliancePlanId
            }&begins_at=${beginsAt
            }&ends_at=${endsAt
            }&type=${data.type}`
            )
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['appliancePlanOverrides-' + appliancePlanId] })
            queryClient.invalidateQueries({ queryKey: ['appliancePlanOverride-' + appliancePlanOverrideId] })
            handleClose()
        },
    })
}

export const useDeletePlanOverride = (appliancePlanOverrideId, appliancePlanId) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: () => {
            return axios.get(`/api/appliance_plan_override/delete?id=${appliancePlanOverrideId}`)
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['appliancePlanOverrides-' + appliancePlanId] })
        },
    })
}
