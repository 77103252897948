import React from 'react'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded'

import { useIsOptimised } from '../hooks/use-estate'
import SavedAmountCard from './saved-amount-card'
import EstateIsReady from './estate-is-ready'
import EstateOptimisationToggle from './estate-optimisation-toggle'
import type { Estate } from '../types'

function EstateOptimised({
    estate,
} : {
    estate: Estate,
}) {

    const { status, data, error } = useIsOptimised(estate.id)

    if (status === 'pending') {
        return (
            <CircularProgress />
        )
    }

    if (error) {
        return (
            <Box>
                <ErrorOutlineRoundedIcon />
                <Box>{error.message}</Box>
            </Box>
        )
    }

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1.5,
        }}>
            {data.optimalization_is_active &&
                <>
                    <SavedAmountCard estateId={estate.id} />
                    <Box display="flex" justifyContent="center">
                        <EstateOptimisationToggle estate={estate}/>
                    </Box>
                </>
            }
            {!data.optimalization_is_active &&
                <EstateIsReady estate={estate} />
            }
        </Box>
    )
}

export default EstateOptimised
