import React, { useEffect } from 'react'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import { Link, useParams } from 'react-router-dom'
import Button, { buttonClasses } from '@mui/material/Button'
import GiteRoundedIcon from '@mui/icons-material/GiteRounded'
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded'
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded'
import { useAtom } from 'jotai'
import CircularProgress from '@mui/material/CircularProgress'
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded'
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded'

import packageJson from './../../../package.json'
// import BatteryCard from '../components/battery-card'
import SwipeableTemporaryDrawer from '../components/swipeable-temporary-drawer'
import CreateButton from '../components/create-button'
import EstateSelector from '../components/estate-selector'
import useDrawer from '../hooks/use-drawer'
import { IconButton } from '@mui/material'
import DeviceTypeSelector from '../components/device-type-selector'
import { selectedEstateIdAtom } from '../state/application'
import { useEstate } from '../hooks/use-estate'
import ApplianceList from '../components/lists/appliance'
import RenewableSourceList from '../components/lists/renewable-source'
import EstateOptimised from '../components/estate-optimised'
import { WizardButton } from "./wizard/wizard-button";
import { useRedirectToSimpleHomepageIfEnabled } from '../hooks/use-simple-homepage'

function EstateView() {

    useRedirectToSimpleHomepageIfEnabled()

    //throw new Response("Not Found", { status: 404 })

    let { estateId } = useParams()

    const [drawerOpenEstate, toggleDrawerEstate, resetDrawerEstate] = useDrawer()
    const [drawerOpenNewDevice, toggleDrawerNewDevice] = useDrawer()

    const [selectedEstateId, setSelectedEstateId] = useAtom(selectedEstateIdAtom)

    const { status, data, error } = useEstate(estateId)

    // Save selected estate to localStorage
    useEffect(() => {
        if (data) {
            setSelectedEstateId(data.id)
        }
    }, [data])

    useEffect(() => {
        resetDrawerEstate()
    }, [estateId])

    if (status === 'pending') {
        return (
            <Box display="flex" justifyContent="center">
                <CircularProgress />
            </Box>
        )
    }

    if (error) {
        setSelectedEstateId(null)
        return (
            <Container maxWidth="sm">
                <Box display="flex" flexDirection="column" alignItems="center">
                    <ErrorOutlineRoundedIcon />
                    <Box>{error.message}</Box>
                    <Box mt={4}>
                        <Box>Došlo k chybě při načítání nemovitosti s ID: {estateId}</Box>
                    </Box>
                    <Box display="flex" justifyContent="center" mt={4}>
                        <Button
                            startIcon={<ArrowBackRoundedIcon fontSize='large' />}
                            size='large'
                            sx={{
                                fontSize: '1rem',
                                fontWeight: 400,
                            }}
                            component={Link}
                            to="/"
                        >
                            Zpět na výběr nemovitostí
                        </Button>
                    </Box>
                </Box>
            </Container>
        )
    }

    return (
        <>
            <Container maxWidth="sm">
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '.5rem'
                }}>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Button
                            startIcon={<GiteRoundedIcon fontSize='large' />}
                            endIcon={<ArrowDropDownRoundedIcon fontSize='inherit' />}
                            size='large'
                            sx={{
                                fontSize: '1.2rem',
                                fontWeight: 400,
                                [`& .${buttonClasses.startIcon} > *:nth-of-type(1)`]: {
                                    fontSize: '1.8rem',
                                }
                            }}
                            onClick={toggleDrawerEstate(true)}
                        >
                            {data.name}
                        </Button>
                        <Box sx={{
                            display: 'flex',
                            gap: '1rem',
                        }}>
                            <Box>
                                <IconButton
                                    color='primary'
                                    component={Link}
                                    to="settings"
                                >
                                    <SettingsRoundedIcon/>
                                </IconButton>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{display : 'flex', justifyContent: 'right', marginBottom: '1rem'}}>
                        <WizardButton estateId={estateId} />
                    </Box>
                </Box>
                <Box mt={0.5}>
                    <EstateOptimised estate={data} />
                </Box>
                <Box sx={{
                    color: 'primary.main',
                    fontWeight: 400,
                    marginTop: '1rem',
                    marginBottom: '0.5rem',
                    paddingLeft: '0.4rem',
                }}>
                    Bojlery
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1.5,
                }}>
                    <ApplianceList estateId={estateId} />
                </Box>
                <Box sx={{
                    color: 'primary.main',
                    fontWeight: 400,
                    marginTop: '1.5rem',
                    marginBottom: '0.5rem',
                    paddingLeft: '0.4rem',
                }}>
                    Fotovoltaika
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1.5,
                }}>
                    <RenewableSourceList estateId={estateId} />
                    {/* <BatteryCard
                        name="Bateriové úložiště"
                        capacity="5.6 kWh"
                    /> */}
                </Box>
                <Box display="flex" mt={5}>
                    <CreateButton text='Přidat' onClick={toggleDrawerNewDevice(true)} />
                </Box>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: 4,
                }}>
                    <Button
                        startIcon={
                            <AccountCircleRoundedIcon />
                        }
                        size='large'
                        sx={{
                            fontSize: '1rem',
                            fontWeight: 400,
                        }}
                        href="/sign_out"
                    >
                        Nastavení účtu
                    </Button>
                </Box>
                <Box sx={{
                    marginTop: 3,
                    fontSize: '0.75rem',
                    color: 'primary.main',
                    textAlign: 'center',
                }}>
                    <p>ChargeControl v{packageJson.version}</p>
                    <p>© Factorio Solutions</p>
                </Box>
            </Container>
            <SwipeableTemporaryDrawer
                open={drawerOpenEstate}
                toggleDrawer={toggleDrawerEstate}
            >
                <EstateSelector />
            </SwipeableTemporaryDrawer>
            <SwipeableTemporaryDrawer
                open={drawerOpenNewDevice}
                toggleDrawer={toggleDrawerNewDevice}
            >
                <DeviceTypeSelector estateId={estateId}/>
            </SwipeableTemporaryDrawer>
        </>
    )
}

export default EstateView
