import React from 'react'
import Box from '@mui/material/Box'
import ButtonBase from '@mui/material/ButtonBase'
import Typography from '@mui/material/Typography'
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded'
import { useToggleAppliancePlan } from '../hooks/use-appliance-plan'
import { AppliancePlan, CustomDataWithError } from '../types'
import { AxiosError } from 'axios'

function PlanToggleButton({
    appliancePlan,
    applianceId,
}: {
    appliancePlan: AppliancePlan,
    applianceId: string | undefined,
}) {

    const mutation = useToggleAppliancePlan(appliancePlan.id, applianceId, () => {})

    const onClick = (bool: boolean) => {
        mutation.mutate(bool)
    }

    return (
        <Box sx={{
            flexBasis: '50%',
        }}>
            <ButtonBase sx={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: appliancePlan?.active ? 'customGreen.main' : 'customGrey.main',
                padding: 2,
                borderRadius: 5,
                "&.Mui-focusVisible": {
                    backgroundColor: 'customGreen.dark',
                },
            }} component="div" onClick={() => onClick(!appliancePlan.active)}>
                <Box sx={{
                    paddingRight: 1.5,
                    fontSize: '1.5rem',
                    lineHeight: 0,
                }}>
                    <CalendarMonthRoundedIcon fontSize='inherit'/>
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    gap: 0.2,
                }}>
                    <Typography component="div" sx={{ fontSize: '1rem' }}>{appliancePlan.name}</Typography>
                    <Typography component="div" sx={{ fontSize: '0.875rem' }}>{appliancePlan?.active ? 'Zapnuto' : 'Vypnuto'}</Typography>
                </Box>
            </ButtonBase>
            {((mutation.error as AxiosError)?.response?.data as CustomDataWithError)?.error &&
                <Box sx={{
                    color: 'error.main',
                    marginTop: 1,
                }}>{((mutation.error as AxiosError)?.response?.data as CustomDataWithError)?.error}</Box>
            }
        </Box>
    )
}

export default PlanToggleButton
